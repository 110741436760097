import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Timestamp: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export enum Application {
  Dolt = 'Dolt',
  Doltgres = 'Doltgres',
  MySql = 'MySQL'
}

export type AuthorInfo = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AvailableInstanceTypes = {
  __typename?: 'AvailableInstanceTypes';
  cloud: CloudProvider;
  instanceTypes: Array<InstanceType>;
  zone: Scalars['String']['output'];
};

export type Backup = {
  __typename?: 'Backup';
  _id: Scalars['ID']['output'];
  backupName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Timestamp']['output']>;
  databases: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  instanceIndex: Scalars['Int']['output'];
  ownerName: Scalars['String']['output'];
  size: Scalars['String']['output'];
};

export type BackupName = {
  backupName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};

export type BoolOverrideValidationType = {
  __typename?: 'BoolOverrideValidationType';
  isBool?: Maybe<Scalars['Boolean']['output']>;
};

export type Branch = {
  __typename?: 'Branch';
  _id: Scalars['ID']['output'];
  branchName: Scalars['String']['output'];
  databaseName: Scalars['String']['output'];
  defaultDoc?: Maybe<Doc>;
  deploymentName: Scalars['String']['output'];
  head?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated: Scalars['Timestamp']['output'];
  ownerName: Scalars['String']['output'];
  pull?: Maybe<PullNameState>;
  table?: Maybe<Table>;
  tableNames: Array<Scalars['String']['output']>;
};


export type BranchTableArgs = {
  tableName: Scalars['String']['input'];
};


export type BranchTableNamesArgs = {
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BranchList = {
  __typename?: 'BranchList';
  list: Array<Branch>;
  nextOffset?: Maybe<Scalars['Int']['output']>;
};

export type CalcCostRes = {
  __typename?: 'CalcCostRes';
  costs: Array<CostType>;
};

export enum CloudProvider {
  Aws = 'Aws',
  Gcp = 'Gcp',
  Unspecified = 'Unspecified'
}

export enum ClusterType {
  Dolt = 'Dolt',
  Doltgres = 'Doltgres',
  MySqlWithDoltReplicas = 'MySQLWithDoltReplicas'
}

export type ColConstraint = {
  __typename?: 'ColConstraint';
  notNull: Scalars['Boolean']['output'];
};

export type CollabList = {
  __typename?: 'CollabList';
  list: Array<DeploymentCollaborator>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export enum CollabPermLevel {
  Admin = 'Admin',
  Read = 'Read',
  ReadAndPulls = 'ReadAndPulls',
  Unspecified = 'Unspecified',
  Write = 'Write'
}

export type Column = {
  __typename?: 'Column';
  constraints?: Maybe<Array<ColConstraint>>;
  isPrimaryKey: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sourceTable?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ColumnValue = {
  __typename?: 'ColumnValue';
  displayValue: Scalars['String']['output'];
};

export type Commit = {
  __typename?: 'Commit';
  _id: Scalars['ID']['output'];
  commitId: Scalars['String']['output'];
  committedAt: Scalars['Timestamp']['output'];
  committer: DoltWriter;
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  parents: Array<Scalars['String']['output']>;
};

export enum CommitDiffType {
  ThreeDot = 'ThreeDot',
  TwoDot = 'TwoDot',
  Unspecified = 'Unspecified'
}

export type CommitList = {
  __typename?: 'CommitList';
  list: Array<Commit>;
  nextOffset?: Maybe<Scalars['Int']['output']>;
};

export type CostType = {
  __typename?: 'CostType';
  amount: MoneyType;
  period: TimePeriod;
};

export type CredsRes = {
  __typename?: 'CredsRes';
  jwt: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  _id: Scalars['ID']['output'];
  dashboardName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
};

export type DepTeamPerm = {
  __typename?: 'DepTeamPerm';
  _id: Scalars['ID']['output'];
  depName: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  permLevel: TeamPermLevel;
  team?: Maybe<Team>;
  teamName: Scalars['String']['output'];
};

export type DepTeamPermList = {
  __typename?: 'DepTeamPermList';
  list: Array<DepTeamPerm>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type DeployInput = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};

export enum DeployState {
  Started = 'Started',
  Starting = 'Starting',
  Stopped = 'Stopped',
  Stopping = 'Stopping',
  Unspecified = 'Unspecified'
}

export type Deployment = {
  __typename?: 'Deployment';
  _id: Scalars['ID']['output'];
  cloud: CloudProvider;
  clusterType: ClusterType;
  createdAt: Scalars['Timestamp']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  currentConfigItems?: Maybe<Array<OverrideItem>>;
  deploymentName: Scalars['String']['output'];
  destroyAt: Scalars['Timestamp']['output'];
  destroyedBy?: Maybe<Scalars['String']['output']>;
  disableAutomaticDoltUpdates: Scalars['Boolean']['output'];
  doltVersion: Scalars['String']['output'];
  exposeRemotesapiEndpoint: Scalars['Boolean']['output'];
  host: Scalars['String']['output'];
  hourlyCostUsd: Scalars['Float']['output'];
  instanceTypeName: Scalars['String']['output'];
  numReplicas: Scalars['Int']['output'];
  ownerName: Scalars['String']['output'];
  password: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  privateLinkConfig?: Maybe<PrivateLinkConfig>;
  pscConfig?: Maybe<PrivateServiceConnectConfig>;
  role: DeploymentRole;
  serverCertCa: Scalars['String']['output'];
  state: DeployState;
  username: Scalars['String']['output'];
  volumeSizeGb: Scalars['Int']['output'];
  volumeTypeName: Scalars['String']['output'];
  webPKICert: Scalars['Boolean']['output'];
  workbenchHost: Scalars['String']['output'];
  zone: Scalars['String']['output'];
};

export type DeploymentCollaborator = {
  __typename?: 'DeploymentCollaborator';
  _id: Scalars['ID']['output'];
  collaboratorName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  ownerName: Scalars['String']['output'];
  permissionLevel: CollabPermLevel;
  username: Scalars['String']['output'];
};

export type DeploymentCreds = {
  __typename?: 'DeploymentCreds';
  deploymentName: Scalars['String']['output'];
  keyID: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
};

export enum DeploymentFlag {
  None = 'None',
  UiTest = 'UITest'
}

export type DeploymentInstance = {
  __typename?: 'DeploymentInstance';
  _id: Scalars['ID']['output'];
  application: Application;
  deploymentName: Scalars['String']['output'];
  host: Scalars['String']['output'];
  hourlyCost: MoneyType;
  index: Scalars['Int']['output'];
  instanceID: Scalars['String']['output'];
  instanceTypeName: Scalars['String']['output'];
  isPrimary: Scalars['Boolean']['output'];
  ownerName: Scalars['String']['output'];
  prettyName: Scalars['String']['output'];
  volumeSizeGb: Scalars['Int']['output'];
  volumeTypeName: Scalars['String']['output'];
};

export type DeploymentInstanceList = {
  __typename?: 'DeploymentInstanceList';
  list: Array<DeploymentInstance>;
};

export type DeploymentList = {
  __typename?: 'DeploymentList';
  list: Array<DeploymentListItem>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type DeploymentListItem = {
  __typename?: 'DeploymentListItem';
  _id: Scalars['ID']['output'];
  cloud: CloudProvider;
  clusterType: ClusterType;
  deploymentName: Scalars['String']['output'];
  doltVersion: Scalars['String']['output'];
  hourlyCostUsd: Scalars['Float']['output'];
  instanceTypeName: Scalars['String']['output'];
  lastBackupSize?: Maybe<Scalars['String']['output']>;
  lastBackupTime?: Maybe<Scalars['Timestamp']['output']>;
  numReplicas: Scalars['Int']['output'];
  ownerName: Scalars['String']['output'];
  privateLinkConfig?: Maybe<PrivateLinkConfig>;
  pscConfig?: Maybe<PrivateServiceConnectConfig>;
  state: DeployState;
  volumeSizeGb: Scalars['Int']['output'];
  volumeTypeName: Scalars['String']['output'];
  zone: Scalars['String']['output'];
};

export type DeploymentNameParams = {
  __typename?: 'DeploymentNameParams';
  _id: Scalars['ID']['output'];
  deploymentName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
};

export type DeploymentOwner = {
  __typename?: 'DeploymentOwner';
  displayName?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum DeploymentRole {
  Admin = 'Admin',
  Reader = 'Reader',
  Unspecified = 'Unspecified',
  Writer = 'Writer'
}

export enum DiffRowType {
  Added = 'Added',
  All = 'All',
  Modified = 'Modified',
  Removed = 'Removed'
}

export type DiffStat = {
  __typename?: 'DiffStat';
  cellCount: Scalars['Float']['output'];
  cellsModified: Scalars['Float']['output'];
  rowCount: Scalars['Float']['output'];
  rowsAdded: Scalars['Float']['output'];
  rowsDeleted: Scalars['Float']['output'];
  rowsModified: Scalars['Float']['output'];
  rowsUnmodified: Scalars['Float']['output'];
};

export type DiffSummary = {
  __typename?: 'DiffSummary';
  _id: Scalars['ID']['output'];
  fromTableName: Scalars['String']['output'];
  hasDataChanges: Scalars['Boolean']['output'];
  hasSchemaChanges: Scalars['Boolean']['output'];
  tableName: Scalars['String']['output'];
  tableType: TableDiffType;
  toTableName: Scalars['String']['output'];
};

export type Doc = {
  __typename?: 'Doc';
  branchName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  docRow?: Maybe<Row>;
  docType: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
};

export type DocList = {
  __typename?: 'DocList';
  list: Array<Doc>;
};

export enum DocType {
  License = 'License',
  Readme = 'Readme',
  Unspecified = 'Unspecified'
}

export type DoltWriter = {
  __typename?: 'DoltWriter';
  _id: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  _id: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  isPrimary: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type EnumOverrideValidationType = {
  __typename?: 'EnumOverrideValidationType';
  validValuesList?: Maybe<Array<Scalars['String']['output']>>;
};

export type ExistingDepSettings = {
  deploymentName: Scalars['String']['input'];
  includeCollabs: Scalars['Boolean']['input'];
  includeConfig: Scalars['Boolean']['input'];
  ownerName: Scalars['String']['input'];
};

export enum FileType {
  Csv = 'Csv',
  Psv = 'Psv'
}

export type ForeignKey = {
  __typename?: 'ForeignKey';
  columnName: Scalars['String']['output'];
  foreignKeyColumn: Array<ForeignKeyColumn>;
  referencedTableName: Scalars['String']['output'];
  tableName: Scalars['String']['output'];
};

export type ForeignKeyColumn = {
  __typename?: 'ForeignKeyColumn';
  referencedColumnName: Scalars['String']['output'];
  referrerColumnIndex: Scalars['Float']['output'];
};

export type GcpVpcInput = {
  networkName: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type GcpVpcs = {
  __typename?: 'GcpVpcs';
  networkName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
};

export type GetOrgIdentityProviderRes = {
  __typename?: 'GetOrgIdentityProviderRes';
  acsUrl: Scalars['String']['output'];
  audienceUri: Scalars['String']['output'];
  descriptor?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<OrgIdentityProvider>;
  providerCertCa: Scalars['String']['output'];
};

export type Graph = {
  __typename?: 'Graph';
  _id: Scalars['ID']['output'];
  deploymentName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  graphName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
};

export type GraphData = {
  __typename?: 'GraphData';
  metricName: Scalars['String']['output'];
  useY0: Scalars['Boolean']['output'];
  values: Array<Scalars['Float']['output']>;
};

export type GraphDataRes = {
  __typename?: 'GraphDataRes';
  endTime: Scalars['Timestamp']['output'];
  graphs: Array<GraphData>;
  startTime: Scalars['Timestamp']['output'];
  valueTimestamps: Array<Scalars['Timestamp']['output']>;
  xAxis: Scalars['String']['output'];
  y0Axis: Scalars['String']['output'];
  y1Axis: Scalars['String']['output'];
};

export enum IdentityProvider {
  Google = 'Google',
  Password = 'Password',
  Saml = 'SAML',
  Unspecified = 'Unspecified'
}

export enum ImportOperation {
  Update = 'Update'
}

export type Incident = {
  __typename?: 'Incident';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  impact: IncidentImpact;
  incidentName: Scalars['String']['output'];
  lastModifiedAt?: Maybe<Scalars['Timestamp']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  relatedDeployments: Array<DeploymentNameParams>;
  resolvedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: IncidentStatus;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum IncidentImpact {
  Critical = 'Critical',
  Low = 'Low',
  Unspecified = 'Unspecified'
}

export enum IncidentStatus {
  CustomerCanceled = 'CustomerCanceled',
  Open = 'Open',
  Resolved = 'Resolved',
  Unspecified = 'Unspecified'
}

export type IncidentSummary = {
  __typename?: 'IncidentSummary';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Timestamp']['output'];
  impact: IncidentImpact;
  incidentName: Scalars['String']['output'];
  resolvedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: IncidentStatus;
  title: Scalars['String']['output'];
};

export type Index = {
  __typename?: 'Index';
  columns: Array<IndexColumn>;
  comment: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type IndexColumn = {
  __typename?: 'IndexColumn';
  name: Scalars['String']['output'];
  sqlType?: Maybe<Scalars['String']['output']>;
};

export type InstanceType = {
  __typename?: 'InstanceType';
  costs: Array<CostType>;
  cpus: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mem: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  network: Scalars['String']['output'];
};

export type IntOverrideValidationType = {
  __typename?: 'IntOverrideValidationType';
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Int']['output']>;
};

export type ListIncidentsRes = {
  __typename?: 'ListIncidentsRes';
  list: Array<IncidentSummary>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export enum LoadDataModifier {
  Ignore = 'Ignore',
  Replace = 'Replace'
}

export type LogLine = {
  __typename?: 'LogLine';
  text: Scalars['String']['output'];
  time: Scalars['Timestamp']['output'];
};

export type LogsList = {
  __typename?: 'LogsList';
  list: Array<LogLine>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  prevPageToken?: Maybe<Scalars['String']['output']>;
};

export type MergeState = {
  __typename?: 'MergeState';
  mergeBaseCommit: Scalars['String']['output'];
  premergeFromCommit: Scalars['String']['output'];
  premergeToCommit: Scalars['String']['output'];
};

export type MergeStateArg = {
  mergeBaseCommit: Scalars['String']['input'];
  premergeFromCommit: Scalars['String']['input'];
  premergeToCommit: Scalars['String']['input'];
};

export type MoneyType = {
  __typename?: 'MoneyType';
  currencyCode: Scalars['String']['output'];
  nanos: Scalars['Float']['output'];
  units: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addInstance: Scalars['Boolean']['output'];
  changePrimary: Scalars['Boolean']['output'];
  createBackup: Scalars['Boolean']['output'];
  createBillingPortalSession: Scalars['String']['output'];
  createBranch: Branch;
  createCollab: DeploymentCollaborator;
  createDatabase: Scalars['String']['output'];
  createDatabaseCreds: CredsRes;
  createDepTeamPerm: DepTeamPerm;
  createDeployment: DeploymentNameParams;
  createDeploymentCreds: Scalars['Boolean']['output'];
  createEmail: EmailAddress;
  createIncident: Incident;
  createOrg: Organization;
  createOrgIdentityProvider: OrgIdentityProvider;
  createOrgMember: OrgMember;
  createPull: Pull;
  createPullComment: PullSummary;
  createSession: UserSession;
  createSubscription: Subscription;
  createTag: Tag;
  createTeam: Team;
  createTeamMember: TeamMember;
  createUser: User;
  deleteBranch: Scalars['Boolean']['output'];
  deleteCollab: Scalars['Boolean']['output'];
  deleteCurrentSession: Scalars['Boolean']['output'];
  deleteDepTeamPerm: Scalars['Boolean']['output'];
  deleteDeploymentCreds: Scalars['Boolean']['output'];
  deleteEmail: Scalars['Boolean']['output'];
  deleteInstance: Scalars['Boolean']['output'];
  deleteOrg: Scalars['Boolean']['output'];
  deleteOrgIdentityProvider: Scalars['Boolean']['output'];
  deleteOrgMember: Scalars['Boolean']['output'];
  deletePullComment: PullSummary;
  deleteSubscription: Scalars['Boolean']['output'];
  deleteTag: Scalars['Boolean']['output'];
  deleteTeam: Scalars['Boolean']['output'];
  deleteTeamMember: Scalars['Boolean']['output'];
  destroyDeployment: Scalars['Boolean']['output'];
  loadDataFile: Scalars['Boolean']['output'];
  makeEmailPrimary: EmailAddress;
  mergePull: Pull;
  rebootInstance: Scalars['Boolean']['output'];
  rerollDeploymentCreds: Scalars['Boolean']['output'];
  resendVerifyEmail: Scalars['Boolean']['output'];
  restartDolt: Scalars['Boolean']['output'];
  restoreAllTables: Scalars['Boolean']['output'];
  sendPasswordRecoveryEmail: Scalars['Boolean']['output'];
  signin: User;
  signout: Scalars['Boolean']['output'];
  updateCollab: DeploymentCollaborator;
  updateCurrentUser: User;
  updateDepTeamPerm: DepTeamPerm;
  updateDeployment: Deployment;
  updateDolt: Scalars['Boolean']['output'];
  updateIncident: Incident;
  updateOrg: Organization;
  updateOrgIdentityProvider: OrgIdentityProvider;
  updateOrgMember: OrgMember;
  updateOverrides: Scalars['Boolean']['output'];
  updatePassword: Scalars['Boolean']['output'];
  updatePasswordFromToken: Scalars['Boolean']['output'];
  updatePull: Pull;
  updatePullComment: PullSummary;
  updateRemotesapiEndpoint: Scalars['Boolean']['output'];
  updateServiceWindow: ServiceWindow;
  updateTeam: Team;
  updateTeamMember: TeamMember;
  updateUser: User;
  updateWorkbenchSettings: WorkbenchSettings;
  verifyEmail: EmailAddress;
};


export type MutationAddInstanceArgs = {
  backupName?: InputMaybe<BackupName>;
  deploymentName: Scalars['String']['input'];
  instanceTypeId: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  volumeSize: Scalars['Int']['input'];
  volumeTypeId: Scalars['String']['input'];
};


export type MutationChangePrimaryArgs = {
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateBackupArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateBillingPortalSessionArgs = {
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
};


export type MutationCreateBranchArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
  newBranchName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateCollabArgs = {
  collaboratorName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
  permLevel: CollabPermLevel;
};


export type MutationCreateDatabaseArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateDatabaseCredsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateDepTeamPermArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  permLevel: TeamPermLevel;
  teamName: Scalars['String']['input'];
};


export type MutationCreateDeploymentArgs = {
  allowedPeeringAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedProjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedVpcs?: InputMaybe<Array<GcpVpcInput>>;
  backupName?: InputMaybe<BackupName>;
  canCreateWorkbenchUsers: Scalars['Boolean']['input'];
  cloud: CloudProvider;
  clusterType?: InputMaybe<ClusterType>;
  deploymentName: Scalars['String']['input'];
  existingDepSettings?: InputMaybe<ExistingDepSettings>;
  exposeRemotesapiEndpoint?: InputMaybe<Scalars['Boolean']['input']>;
  flag?: InputMaybe<DeploymentFlag>;
  instanceTypeId: Scalars['String']['input'];
  numReplicas?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  usePrivateLink?: InputMaybe<Scalars['Boolean']['input']>;
  volumeSize: Scalars['Int']['input'];
  volumeTypeId: Scalars['String']['input'];
  webPKICert?: InputMaybe<Scalars['Boolean']['input']>;
  zone: Scalars['String']['input'];
};


export type MutationCreateDeploymentCredsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationCreateEmailArgs = {
  emailAddress: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateIncidentArgs = {
  deployments: Array<DeployInput>;
  impact: IncidentImpact;
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateOrgArgs = {
  billingEmail: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type MutationCreateOrgIdentityProviderArgs = {
  metadataDescriptor: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type MutationCreateOrgMemberArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  newMember?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  role: OrgRole;
};


export type MutationCreatePullArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fromBranchName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  title: Scalars['String']['input'];
  toBranchName: Scalars['String']['input'];
};


export type MutationCreatePullCommentArgs = {
  comment: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
};


export type MutationCreateSessionArgs = {
  provider: IdentityProvider;
  samlOrgName?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSubscriptionArgs = {
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
  provider: PaymentProviderType;
  token: Scalars['String']['input'];
};


export type MutationCreateTagArgs = {
  author?: InputMaybe<AuthorInfo>;
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};


export type MutationCreateTeamArgs = {
  callerRole: TeamRole;
  description: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationCreateTeamMemberArgs = {
  memberName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  role: TeamRole;
  teamName: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  provider: IdentityProvider;
  samlOrgName?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationDeleteBranchArgs = {
  branchName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationDeleteCollabArgs = {
  collaboratorName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
};


export type MutationDeleteDepTeamPermArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationDeleteDeploymentCredsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationDeleteEmailArgs = {
  emailAddress: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationDeleteInstanceArgs = {
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationDeleteOrgArgs = {
  orgName: Scalars['String']['input'];
};


export type MutationDeleteOrgIdentityProviderArgs = {
  orgName: Scalars['String']['input'];
};


export type MutationDeleteOrgMemberArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeletePullCommentArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
};


export type MutationDeleteTagArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};


export type MutationDeleteTeamArgs = {
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationDeleteTeamMemberArgs = {
  memberName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationDestroyDeploymentArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationLoadDataFileArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  fileType: FileType;
  importOp: ImportOperation;
  modifier?: InputMaybe<LoadDataModifier>;
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
};


export type MutationMakeEmailPrimaryArgs = {
  emailAddress: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationMergePullArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
};


export type MutationRebootInstanceArgs = {
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationRerollDeploymentCredsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationResendVerifyEmailArgs = {
  emailAddress: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRestartDoltArgs = {
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationRestoreAllTablesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type MutationSendPasswordRecoveryEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSigninArgs = {
  provider: IdentityProvider;
  samlOrgName?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCollabArgs = {
  collaboratorName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
  permLevel: CollabPermLevel;
};


export type MutationUpdateCurrentUserArgs = {
  company: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdateDepTeamPermArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  permLevel: TeamPermLevel;
  teamName: Scalars['String']['input'];
};


export type MutationUpdateDeploymentArgs = {
  allowedPeeringAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  deploymentName: Scalars['String']['input'];
  disableAutomaticDoltUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  ownerName: Scalars['String']['input'];
};


export type MutationUpdateDoltArgs = {
  deploymentName: Scalars['String']['input'];
  desiredVersion: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationUpdateIncidentArgs = {
  deployments: Array<DeployInput>;
  impact: IncidentImpact;
  incidentName: Scalars['String']['input'];
  status: IncidentStatus;
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateOrgArgs = {
  billingEmail: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type MutationUpdateOrgIdentityProviderArgs = {
  metadataDescriptor: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type MutationUpdateOrgMemberArgs = {
  orgName: Scalars['String']['input'];
  role: OrgRole;
  username: Scalars['String']['input'];
};


export type MutationUpdateOverridesArgs = {
  deploymentName: Scalars['String']['input'];
  overrides: Array<OverrideInput>;
  ownerName: Scalars['String']['input'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdatePasswordFromTokenArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdatePullArgs = {
  afterMergeCommit?: InputMaybe<Scalars['String']['input']>;
  associatedUser?: InputMaybe<Scalars['String']['input']>;
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  mergeState?: InputMaybe<MergeStateArg>;
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
  state: PullState;
  title: Scalars['String']['input'];
  updatedPullActivity?: InputMaybe<PullActivity>;
};


export type MutationUpdatePullCommentArgs = {
  _id: Scalars['ID']['input'];
  authorName: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};


export type MutationUpdateRemotesapiEndpointArgs = {
  deploymentName: Scalars['String']['input'];
  exposeRemotesapiEndpoint: Scalars['Boolean']['input'];
  ownerName: Scalars['String']['input'];
};


export type MutationUpdateServiceWindowArgs = {
  dayOfWeek: Scalars['Int']['input'];
  deploymentName: Scalars['String']['input'];
  endHourOfDay: Scalars['Int']['input'];
  ownerName: Scalars['String']['input'];
  serviceWindowID: Scalars['String']['input'];
  startHourOfDay: Scalars['Int']['input'];
};


export type MutationUpdateTeamArgs = {
  callerRole: TeamRole;
  description: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationUpdateTeamMemberArgs = {
  memberName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  role: TeamRole;
  teamName: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  company: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdateWorkbenchSettingsArgs = {
  deleteSourceBranchOnAcceptedMerge?: InputMaybe<Scalars['Boolean']['input']>;
  deploymentName: Scalars['String']['input'];
  enableWrites?: InputMaybe<Scalars['Boolean']['input']>;
  ownerName: Scalars['String']['input'];
};


export type MutationVerifyEmailArgs = {
  emailAddress: Scalars['String']['input'];
  username: Scalars['String']['input'];
  verifyToken: Scalars['String']['input'];
};

export type OrgIdentityProvider = {
  __typename?: 'OrgIdentityProvider';
  _id: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
};

export type OrgMember = {
  __typename?: 'OrgMember';
  _id: Scalars['ID']['output'];
  availableCallerActions: Array<OrgMemberAction>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  role: OrgRole;
  teams: TeamList;
  username: Scalars['String']['output'];
};

export enum OrgMemberAction {
  Delete = 'Delete',
  Unspecified = 'Unspecified'
}

export enum OrgRole {
  Member = 'Member',
  Owner = 'Owner',
  Unspecified = 'Unspecified'
}

export type Organization = {
  __typename?: 'Organization';
  _id: Scalars['ID']['output'];
  billingEmail: Scalars['String']['output'];
  callerRole: OrgRole;
  displayName: Scalars['String']['output'];
  hasSamlProvider?: Maybe<Scalars['Boolean']['output']>;
  hasSubscription?: Maybe<Scalars['Boolean']['output']>;
  orgName: Scalars['String']['output'];
};

export type OverrideInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OverrideItem = {
  __typename?: 'OverrideItem';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OverridesRes = {
  __typename?: 'OverridesRes';
  list: Array<OverrideItem>;
};

export type OwnerSubInfo = {
  __typename?: 'OwnerSubInfo';
  _id: Scalars['ID']['output'];
  canAdminister: Scalars['Boolean']['output'];
  hasSubscription: Scalars['Boolean']['output'];
  ownerName: Scalars['String']['output'];
  ownerType: OwnerType;
};

export enum OwnerType {
  Org = 'Org',
  User = 'User'
}

export enum PaymentProviderType {
  Stripe = 'Stripe'
}

export type PrivateLinkConfig = {
  __typename?: 'PrivateLinkConfig';
  allowedPeeringAccountIds: Array<Scalars['String']['output']>;
  endpoints: Array<PrivateLinkEndpoint>;
  usePrivateLink: Scalars['Boolean']['output'];
};

export type PrivateLinkEndpoint = {
  __typename?: 'PrivateLinkEndpoint';
  hostname: Scalars['String']['output'];
  serviceName: Scalars['String']['output'];
  state: PrivateLinkEndpointState;
};

export enum PrivateLinkEndpointState {
  Available = 'Available',
  Creating = 'Creating',
  Deleted = 'Deleted',
  Unspecified = 'Unspecified'
}

export type PrivateServiceConnectConfig = {
  __typename?: 'PrivateServiceConnectConfig';
  allowedProjectIds: Array<Scalars['String']['output']>;
  allowedVpcs: Array<GcpVpcs>;
  baseUrl: Scalars['String']['output'];
  consumerReadServiceName: Scalars['String']['output'];
  consumerServiceName: Scalars['String']['output'];
  targetReadService: Scalars['String']['output'];
  targetService: Scalars['String']['output'];
  usePrivateServiceConnect: Scalars['Boolean']['output'];
};

export type Pull = {
  __typename?: 'Pull';
  _id: Scalars['ID']['output'];
  afterMergeCommit?: Maybe<Scalars['String']['output']>;
  availableActions: Array<PullAction>;
  commentCount: Scalars['Float']['output'];
  createdAt: Scalars['Timestamp']['output'];
  creatorName: Scalars['String']['output'];
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fromBranchName: Scalars['String']['output'];
  mergeState?: Maybe<MergeState>;
  ownerName: Scalars['String']['output'];
  pullId: Scalars['String']['output'];
  state: PullState;
  title: Scalars['String']['output'];
  toBranchName: Scalars['String']['output'];
};

export enum PullAction {
  Close = 'Close',
  Merge = 'Merge',
  None = 'None'
}

export enum PullActivity {
  BranchDeleted = 'BranchDeleted',
  Closed = 'Closed',
  DatabaseDropped = 'DatabaseDropped',
  Merged = 'Merged',
  Opened = 'Opened',
  Unspecified = 'Unspecified'
}

export type PullActivityLog = {
  __typename?: 'PullActivityLog';
  _id: Scalars['ID']['output'];
  activity: PullActivity;
  createdAt: Scalars['Timestamp']['output'];
  involvedUser?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type PullComment = {
  __typename?: 'PullComment';
  _id: Scalars['ID']['output'];
  authorName: Scalars['String']['output'];
  availableActions: Array<PullCommentAction>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  updatedAt: Scalars['Timestamp']['output'];
};

export enum PullCommentAction {
  Delete = 'Delete',
  Unspecified = 'Unspecified',
  Update = 'Update'
}

export type PullDetailComment = {
  __typename?: 'PullDetailComment';
  _id: Scalars['ID']['output'];
  authorName: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  updatedAt: Scalars['Timestamp']['output'];
};

export type PullDetailCommit = {
  __typename?: 'PullDetailCommit';
  _id: Scalars['ID']['output'];
  commitId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  message: Scalars['String']['output'];
  parentCommitId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type PullDetailLog = {
  __typename?: 'PullDetailLog';
  _id: Scalars['ID']['output'];
  activity: PullActivity;
  createdAt: Scalars['Timestamp']['output'];
  involvedUser?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type PullDetailSummary = {
  __typename?: 'PullDetailSummary';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Timestamp']['output'];
  numCommits: Scalars['Float']['output'];
  username: Scalars['String']['output'];
};

export type PullDetails = PullDetailComment | PullDetailCommit | PullDetailLog | PullDetailSummary;

export type PullList = {
  __typename?: 'PullList';
  list: Array<Pull>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type PullNameState = {
  __typename?: 'PullNameState';
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  pullId: Scalars['String']['output'];
  state: PullState;
};

export type PullPreview = {
  __typename?: 'PullPreview';
  existingPullId?: Maybe<Scalars['String']['output']>;
  pullSummary?: Maybe<PullSummary>;
};

export enum PullState {
  Closed = 'Closed',
  Merged = 'Merged',
  Open = 'Open',
  Unspecified = 'Unspecified'
}

export type PullSummary = {
  __typename?: 'PullSummary';
  _id: Scalars['ID']['output'];
  activityLogs: Array<PullActivityLog>;
  comments: Array<PullComment>;
  commits: CommitList;
};

export type PullWithDetails = {
  __typename?: 'PullWithDetails';
  _id: Scalars['ID']['output'];
  details?: Maybe<Array<PullDetails>>;
  summary?: Maybe<PullSummary>;
};

export type Query = {
  __typename?: 'Query';
  allBranches: Array<Branch>;
  availableInstanceTypes: AvailableInstanceTypes;
  backups: Array<Backup>;
  branch?: Maybe<Branch>;
  branchOrDefault?: Maybe<Branch>;
  branches: BranchList;
  branchesPullState: BranchList;
  calcCost: CalcCostRes;
  collaborators: CollabList;
  commits: CommitList;
  currentUser: User;
  currentUserDeployments: DeploymentList;
  currentUserIncidents: ListIncidentsRes;
  currentUserOrgMemberships: Array<UserOrgMembership>;
  currentUserSubscriptions: Array<Subscription>;
  dashboards: Array<Dashboard>;
  databases: Array<Scalars['String']['output']>;
  defaultBranch?: Maybe<Branch>;
  depTeamPerms: DepTeamPermList;
  deployment: Deployment;
  deploymentCreds?: Maybe<DeploymentCreds>;
  deploymentInstances: DeploymentInstanceList;
  deploymentOwner: DeploymentOwner;
  deployments: DeploymentList;
  diffStat: DiffStat;
  diffSummaries: Array<DiffSummary>;
  docOrDefaultDoc?: Maybe<Doc>;
  docs: DocList;
  doltProcedures?: Maybe<RowList>;
  doltSchemas: RowList;
  doltVersions: Array<Scalars['String']['output']>;
  graphData: GraphDataRes;
  graphs: Array<Graph>;
  incident: Incident;
  incidents: ListIncidentsRes;
  logs: LogsList;
  org: Organization;
  orgIdentityProvider: GetOrgIdentityProviderRes;
  orgMembers: Array<OrgMember>;
  overrides: OverridesRes;
  ownerSubscription: OwnerSubInfo;
  pull: Pull;
  pullPreview: PullPreview;
  pullWithDetails: PullWithDetails;
  pulls: PullList;
  rowDiffs: RowDiffList;
  rows: RowList;
  rowsForDiff: RowListWithCols;
  samlAuthnRequest: SamlAuthnRequest;
  schemaDiff?: Maybe<SchemaDiff>;
  searchUsers: Array<User>;
  serviceWindows: Array<ServiceWindow>;
  sqlSelect: SqlSelect;
  sqlSelectForCsvDownload: Scalars['String']['output'];
  status: Array<Status>;
  storageOptions: StorageOptions;
  subscription: Subscription;
  supportedOverrides: SupportedOverridesRes;
  supportedZones: SupportedZones;
  table: Table;
  tableNames: TableNames;
  tables: Array<Table>;
  tag?: Maybe<Tag>;
  tags: TagList;
  team: Team;
  teamMembers: TeamMemberList;
  teams: TeamList;
  teamsForMember: TeamList;
  user: User;
  userOrgMemberships: Array<UserOrgMembership>;
  views: RowList;
  workbenchSettings: WorkbenchSettings;
};


export type QueryAllBranchesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  sortBy?: InputMaybe<SortBranchesBy>;
};


export type QueryAvailableInstanceTypesArgs = {
  cloud: CloudProvider;
  zone: Scalars['String']['input'];
};


export type QueryBackupsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryBranchArgs = {
  branchName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryBranchOrDefaultArgs = {
  branchName?: InputMaybe<Scalars['String']['input']>;
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryBranchesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  sortBy?: InputMaybe<SortBranchesBy>;
};


export type QueryBranchesPullStateArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  sortBy?: InputMaybe<SortBranchesBy>;
};


export type QueryCalcCostArgs = {
  cloud: CloudProvider;
  instanceTypeId: Scalars['String']['input'];
  replicas?: InputMaybe<Scalars['Int']['input']>;
  volumeSize: Scalars['Int']['input'];
  volumeTypeId: Scalars['String']['input'];
  zone: Scalars['String']['input'];
};


export type QueryCollaboratorsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCommitsArgs = {
  afterCommitId?: InputMaybe<Scalars['String']['input']>;
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  excludingCommitsFromRefName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  twoDot?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrentUserDeploymentsArgs = {
  ownerFilter?: InputMaybe<Scalars['String']['input']>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  stateFilter?: InputMaybe<DeployState>;
};


export type QueryCurrentUserIncidentsArgs = {
  filterByImpact?: InputMaybe<IncidentImpact>;
  filterByStatus?: InputMaybe<IncidentStatus>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDashboardsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDatabasesArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDefaultBranchArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDepTeamPermsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDeploymentArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDeploymentCredsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDeploymentInstancesArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryDeploymentOwnerArgs = {
  ownerName: Scalars['String']['input'];
};


export type QueryDeploymentsArgs = {
  ownerName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
  stateFilter?: InputMaybe<DeployState>;
};


export type QueryDiffStatArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  tableName?: InputMaybe<Scalars['String']['input']>;
  toRefName: Scalars['String']['input'];
  type?: InputMaybe<CommitDiffType>;
};


export type QueryDiffSummariesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  tableName?: InputMaybe<Scalars['String']['input']>;
  toRefName: Scalars['String']['input'];
  type?: InputMaybe<CommitDiffType>;
};


export type QueryDocOrDefaultDocArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  docType?: InputMaybe<DocType>;
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryDocsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryDoltProceduresArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryDoltSchemasArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryDoltVersionsArgs = {
  clusterType?: InputMaybe<ClusterType>;
};


export type QueryGraphDataArgs = {
  deploymentName: Scalars['String']['input'];
  endTime: Scalars['Timestamp']['input'];
  graphName: Scalars['String']['input'];
  instanceID?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
  startTime: Scalars['Timestamp']['input'];
};


export type QueryGraphsArgs = {
  dashboardName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  endTime: Scalars['Timestamp']['input'];
  ownerName: Scalars['String']['input'];
  startTime: Scalars['Timestamp']['input'];
};


export type QueryIncidentArgs = {
  incidentName: Scalars['String']['input'];
};


export type QueryIncidentsArgs = {
  filterByImpact?: InputMaybe<IncidentImpact>;
  filterByStatus?: InputMaybe<IncidentStatus>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};


export type QueryLogsArgs = {
  deploymentName: Scalars['String']['input'];
  endTime: Scalars['Timestamp']['input'];
  instanceID?: InputMaybe<Scalars['String']['input']>;
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
  numLines: Scalars['Int']['input'];
  ownerName: Scalars['String']['input'];
  prevPageToken?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Timestamp']['input'];
};


export type QueryOrgArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryOrgIdentityProviderArgs = {
  includeDescriptor?: InputMaybe<Scalars['Boolean']['input']>;
  orgName: Scalars['String']['input'];
};


export type QueryOrgMembersArgs = {
  orgName: Scalars['String']['input'];
};


export type QueryOverridesArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryOwnerSubscriptionArgs = {
  ownerName: Scalars['String']['input'];
};


export type QueryPullArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
};


export type QueryPullPreviewArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromBranchName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  toBranchName: Scalars['String']['input'];
};


export type QueryPullWithDetailsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
};


export type QueryPullsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  filterByState?: InputMaybe<PullState>;
  ownerName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRowDiffsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  filterByRowType?: InputMaybe<DiffRowType>;
  fromCommitId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  tableName: Scalars['String']['input'];
  toCommitId: Scalars['String']['input'];
};


export type QueryRowsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  revisionName: Scalars['String']['input'];
  revisionType: RevisionType;
  tableName: Scalars['String']['input'];
};


export type QueryRowsForDiffArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  revisionName: Scalars['String']['input'];
  revisionType: RevisionType;
  tableName: Scalars['String']['input'];
};


export type QuerySamlAuthnRequestArgs = {
  orgName: Scalars['String']['input'];
};


export type QuerySchemaDiffArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  fromCommitId: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  tableName: Scalars['String']['input'];
  toCommitId: Scalars['String']['input'];
};


export type QuerySearchUsersArgs = {
  caseInsensitive?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryServiceWindowsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QuerySqlSelectArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  queryString: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QuerySqlSelectForCsvDownloadArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  queryString: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryStatusArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryStorageOptionsArgs = {
  cloud: CloudProvider;
  instanceTypeId: Scalars['String']['input'];
  zone: Scalars['String']['input'];
};


export type QuerySubscriptionArgs = {
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
};


export type QuerySupportedZonesArgs = {
  cloud: CloudProvider;
};


export type QueryTableArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
};


export type QueryTableNamesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryTablesArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryTagArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};


export type QueryTeamArgs = {
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type QueryTeamMembersArgs = {
  orgName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
  teamName: Scalars['String']['input'];
};


export type QueryTeamsArgs = {
  orgName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTeamsForMemberArgs = {
  memberName: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};


export type QueryUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryUserOrgMembershipsArgs = {
  username: Scalars['String']['input'];
};


export type QueryViewsArgs = {
  databaseName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
};


export type QueryWorkbenchSettingsArgs = {
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
};

export enum QueryExecutionStatus {
  Error = 'Error',
  RowLimit = 'RowLimit',
  Success = 'Success',
  Timeout = 'Timeout'
}

export enum RevisionType {
  BranchName = 'BranchName',
  CommitId = 'CommitId',
  RefName = 'RefName',
  Unspecified = 'Unspecified',
  WorkspaceName = 'WorkspaceName'
}

export type Row = {
  __typename?: 'Row';
  columnValues: Array<ColumnValue>;
};

export type RowDiff = {
  __typename?: 'RowDiff';
  added?: Maybe<Row>;
  deleted?: Maybe<Row>;
};

export type RowDiffList = {
  __typename?: 'RowDiffList';
  columns: Array<Column>;
  list: Array<RowDiff>;
  nextOffset?: Maybe<Scalars['Int']['output']>;
};

export type RowList = {
  __typename?: 'RowList';
  list: Array<Row>;
  nextOffset?: Maybe<Scalars['Int']['output']>;
};

export type RowListWithCols = {
  __typename?: 'RowListWithCols';
  columns: Array<Column>;
  list: Array<Row>;
  nextOffset?: Maybe<Scalars['Int']['output']>;
};

export type SamlAuthnRequest = {
  __typename?: 'SAMLAuthnRequest';
  authnRequest: Scalars['String']['output'];
  httpPostUrl: Scalars['String']['output'];
};

export type SchemaDiff = {
  __typename?: 'SchemaDiff';
  numChangedSchemas?: Maybe<Scalars['Int']['output']>;
  schemaDiff?: Maybe<TextDiff>;
  schemaPatch?: Maybe<Array<Scalars['String']['output']>>;
};

export type ServiceWindow = {
  __typename?: 'ServiceWindow';
  _id: Scalars['ID']['output'];
  dayOfWeek: Scalars['Int']['output'];
  deploymentName: Scalars['String']['output'];
  endHourOfDay: Scalars['Int']['output'];
  ownerName: Scalars['String']['output'];
  serviceWindowID: Scalars['String']['output'];
  startHourOfDay: Scalars['Int']['output'];
};

export type Session = {
  __typename?: 'Session';
  _id: Scalars['ID']['output'];
  cookieValue: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  ipAddress: Scalars['String']['output'];
  lastActivity: Scalars['Timestamp']['output'];
  lastAuthenticated: Scalars['Timestamp']['output'];
  sessionId: Scalars['String']['output'];
  userAgent: Scalars['String']['output'];
};

export enum SortBranchesBy {
  LastUpdated = 'LastUpdated',
  Unspecified = 'Unspecified'
}

export type SqlSelect = {
  __typename?: 'SqlSelect';
  _id: Scalars['ID']['output'];
  columns: Array<Column>;
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  queryExecutionMessage: Scalars['String']['output'];
  queryExecutionStatus: QueryExecutionStatus;
  queryString: Scalars['String']['output'];
  revisionName: Scalars['String']['output'];
  revisionType: RevisionType;
  rows: Array<Row>;
};

export type Status = {
  __typename?: 'Status';
  _id: Scalars['ID']['output'];
  refName: Scalars['String']['output'];
  staged: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  tableName: Scalars['String']['output'];
};

export type StorageOption = {
  __typename?: 'StorageOption';
  cost: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxSizeGb: Scalars['Int']['output'];
  minSizeGb: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type StorageOptions = {
  __typename?: 'StorageOptions';
  cloud: CloudProvider;
  instanceTypeId: Scalars['String']['output'];
  storageOptions: Array<StorageOption>;
  zone: Scalars['String']['output'];
};

export type StringOverrideValidationType = {
  __typename?: 'StringOverrideValidationType';
  validationRegex?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _id: Scalars['ID']['output'];
  ownerName: Scalars['String']['output'];
  ownerType: OwnerType;
  type: SubscriptionType;
};

export enum SubscriptionType {
  Active = 'Active',
  Member = 'Member',
  Unspecified = 'Unspecified'
}

export type SupportedOverrideItem = {
  __typename?: 'SupportedOverrideItem';
  key: Scalars['String']['output'];
  validation: Validation;
  value: Scalars['String']['output'];
};

export type SupportedOverridesRes = {
  __typename?: 'SupportedOverridesRes';
  list: Array<SupportedOverrideItem>;
};

export type SupportedZones = {
  __typename?: 'SupportedZones';
  cloud: CloudProvider;
  supportedZones: Array<Scalars['String']['output']>;
};

export type Table = {
  __typename?: 'Table';
  _id: Scalars['ID']['output'];
  columns: Array<Column>;
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  foreignKeys: Array<ForeignKey>;
  indexes: Array<Index>;
  ownerName: Scalars['String']['output'];
  revisionName: Scalars['String']['output'];
  revisionType: RevisionType;
  tableName: Scalars['String']['output'];
};

export enum TableDiffType {
  Added = 'Added',
  Dropped = 'Dropped',
  Modified = 'Modified',
  Renamed = 'Renamed'
}

export type TableNames = {
  __typename?: 'TableNames';
  list: Array<Scalars['String']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ID']['output'];
  commitId: Scalars['String']['output'];
  databaseName: Scalars['String']['output'];
  deploymentName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  taggedAt: Scalars['Timestamp']['output'];
  tagger: DoltWriter;
};

export type TagList = {
  __typename?: 'TagList';
  list: Array<Tag>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type Team = {
  __typename?: 'Team';
  _id: Scalars['ID']['output'];
  callerRole: TeamRole;
  description: Scalars['String']['output'];
  members: TeamMemberList;
  orgName: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
};

export type TeamList = {
  __typename?: 'TeamList';
  list: Array<Team>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  _id: Scalars['ID']['output'];
  memberName: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  role: TeamRole;
  teamName: Scalars['String']['output'];
};

export type TeamMemberList = {
  __typename?: 'TeamMemberList';
  list: Array<TeamMember>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export enum TeamPermLevel {
  Admin = 'Admin',
  Read = 'Read',
  Unspecified = 'Unspecified',
  Write = 'Write'
}

export enum TeamRole {
  Maintainer = 'Maintainer',
  Member = 'Member',
  Unspecified = 'Unspecified'
}

export type TextDiff = {
  __typename?: 'TextDiff';
  leftLines: Scalars['String']['output'];
  rightLines: Scalars['String']['output'];
};

export enum TimePeriod {
  Hour = 'Hour',
  Month = 'Month',
  Unspecified = 'Unspecified'
}

export type UnspecifiedValidationType = {
  __typename?: 'UnspecifiedValidationType';
  unspecified?: Maybe<Scalars['Boolean']['output']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  company: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  emailAddressesList: Array<EmailAddress>;
  provider?: Maybe<IdentityProvider>;
  username: Scalars['String']['output'];
};

export type UserOrgMembership = {
  __typename?: 'UserOrgMembership';
  _id: Scalars['ID']['output'];
  member: OrgMember;
  orgName: Scalars['String']['output'];
  organization: Organization;
  username: Scalars['String']['output'];
};

export type UserSession = {
  __typename?: 'UserSession';
  session: Session;
  user: User;
};

export type Validation = BoolOverrideValidationType | EnumOverrideValidationType | IntOverrideValidationType | StringOverrideValidationType | UnspecifiedValidationType;

export type WorkbenchSettings = {
  __typename?: 'WorkbenchSettings';
  _id: Scalars['String']['output'];
  deleteSourceBranchOnAcceptedMerge: Scalars['Boolean']['output'];
  deploymentName: Scalars['String']['output'];
  enableWrites: Scalars['Boolean']['output'];
  ownerName: Scalars['String']['output'];
};

export type CreateSubscriptionMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  token: Scalars['String']['input'];
  provider: PaymentProviderType;
  ownerType: OwnerType;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'Subscription', _id: string, type: SubscriptionType } };

export type SubscriptionForBillingFragment = { __typename?: 'Subscription', _id: string, type: SubscriptionType };

export type SubscriptionForBillingSettingsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
}>;


export type SubscriptionForBillingSettingsQuery = { __typename?: 'Query', subscription: { __typename?: 'Subscription', _id: string, type: SubscriptionType } };

export type OrganizationForBillingFragment = { __typename?: 'Organization', _id: string, callerRole: OrgRole };

export type OrgForBillingQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type OrgForBillingQuery = { __typename?: 'Query', org: { __typename?: 'Organization', _id: string, callerRole: OrgRole } };

export type DeleteSubscriptionMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: boolean };

export type CreateBillingPortalSessionMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  ownerType: OwnerType;
}>;


export type CreateBillingPortalSessionMutation = { __typename?: 'Mutation', createBillingPortalSession: string };

export type UsersFor404QueryVariables = Exact<{ [key: string]: never; }>;


export type UsersFor404Query = { __typename?: 'Query', currentUser: { __typename?: 'User', _id: string } };

export type GetTagQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
}>;


export type GetTagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', _id: string, tagName: string, deploymentName: string, ownerName: string, message: string, taggedAt: any, commitId: string, tagger: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } } | null };

export type SqlSelectForCsvDownloadQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  queryString: Scalars['String']['input'];
}>;


export type SqlSelectForCsvDownloadQuery = { __typename?: 'Query', sqlSelectForCsvDownload: string };

export type DeployListItemFragment = { __typename?: 'DeploymentListItem', _id: string, ownerName: string, deploymentName: string, state: DeployState, cloud: CloudProvider, zone: string, hourlyCostUsd: number, numReplicas: number, doltVersion: string, lastBackupTime?: any | null, lastBackupSize?: string | null, clusterType: ClusterType };

export type ListDeploymentsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
  stateFilter?: InputMaybe<DeployState>;
}>;


export type ListDeploymentsQuery = { __typename?: 'Query', deployments: { __typename?: 'DeploymentList', nextPageToken?: string | null, list: Array<{ __typename?: 'DeploymentListItem', _id: string, ownerName: string, deploymentName: string, state: DeployState, cloud: CloudProvider, zone: string, hourlyCostUsd: number, numReplicas: number, doltVersion: string, lastBackupTime?: any | null, lastBackupSize?: string | null, clusterType: ClusterType }> } };

export type ListUserDeploymentsQueryVariables = Exact<{
  ownerFilter?: InputMaybe<Scalars['String']['input']>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
  stateFilter?: InputMaybe<DeployState>;
}>;


export type ListUserDeploymentsQuery = { __typename?: 'Query', currentUserDeployments: { __typename?: 'DeploymentList', nextPageToken?: string | null, list: Array<{ __typename?: 'DeploymentListItem', _id: string, ownerName: string, deploymentName: string, state: DeployState, cloud: CloudProvider, zone: string, hourlyCostUsd: number, numReplicas: number, doltVersion: string, lastBackupTime?: any | null, lastBackupSize?: string | null, clusterType: ClusterType }> } };

export type CommitForDiffSelectorFragment = { __typename?: 'Commit', _id: string, commitId: string, message: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } };

export type CommitListForDiffSelectorFragment = { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string, commitId: string, message: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } }> };

export type CommitsForDiffSelectorQueryVariables = Exact<{
  refName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
}>;


export type CommitsForDiffSelectorQuery = { __typename?: 'Query', commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string, commitId: string, message: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } }> } };

export type PullSummaryForDiffSelectorFragment = { __typename?: 'PullSummary', _id: string, commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string, commitId: string, message: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } }> } };

export type PullCommitsForDiffSelectorQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type PullCommitsForDiffSelectorQuery = { __typename?: 'Query', pullWithDetails: { __typename?: 'PullWithDetails', _id: string, summary?: { __typename?: 'PullSummary', _id: string, commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string, commitId: string, message: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } }> } } | null } };

export type DiffStatForDiffsFragment = { __typename?: 'DiffStat', rowsUnmodified: number, rowsAdded: number, rowsDeleted: number, rowsModified: number, cellsModified: number, rowCount: number, cellCount: number };

export type DiffStatQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
  toRefName: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CommitDiffType>;
  tableName?: InputMaybe<Scalars['String']['input']>;
}>;


export type DiffStatQuery = { __typename?: 'Query', diffStat: { __typename?: 'DiffStat', rowsUnmodified: number, rowsAdded: number, rowsDeleted: number, rowsModified: number, cellsModified: number, rowCount: number, cellCount: number } };

export type ColumnForDiffTableListFragment = { __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null };

export type ColumnValueForTableListFragment = { __typename?: 'ColumnValue', displayValue: string };

export type RowForTableListFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type RowDiffForTableListFragment = { __typename?: 'RowDiff', added?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null, deleted?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null };

export type RowDiffListWithColsFragment = { __typename?: 'RowDiffList', nextOffset?: number | null, list: Array<{ __typename?: 'RowDiff', added?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null, deleted?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null }>, columns: Array<{ __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }> };

export type RowDiffsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
  fromCommitId: Scalars['String']['input'];
  toCommitId: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filterByRowType?: InputMaybe<DiffRowType>;
}>;


export type RowDiffsQuery = { __typename?: 'Query', rowDiffs: { __typename?: 'RowDiffList', nextOffset?: number | null, list: Array<{ __typename?: 'RowDiff', added?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null, deleted?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null }>, columns: Array<{ __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }> } };

export type SchemaDiffForTableListFragment = { __typename?: 'TextDiff', leftLines: string, rightLines: string };

export type SchemaDiffFragment = { __typename?: 'SchemaDiff', schemaPatch?: Array<string> | null, schemaDiff?: { __typename?: 'TextDiff', leftLines: string, rightLines: string } | null };

export type SchemaDiffQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
  fromCommitId: Scalars['String']['input'];
  toCommitId: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
}>;


export type SchemaDiffQuery = { __typename?: 'Query', schemaDiff?: { __typename?: 'SchemaDiff', schemaPatch?: Array<string> | null, schemaDiff?: { __typename?: 'TextDiff', leftLines: string, rightLines: string } | null } | null };

export type BranchForBranchSelectorFragment = { __typename?: 'Branch', branchName: string, deploymentName: string, ownerName: string, databaseName: string };

export type BranchesForSelectorQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type BranchesForSelectorQuery = { __typename?: 'Query', allBranches: Array<{ __typename?: 'Branch', branchName: string, deploymentName: string, ownerName: string, databaseName: string }> };

export type TableNamesForBranchQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type TableNamesForBranchQuery = { __typename?: 'Query', tableNames: { __typename?: 'TableNames', list: Array<string> } };

export type ColumnsListForTableListFragment = { __typename?: 'IndexColumn', name: string, sqlType?: string | null };

export type IndexForTableListFragment = { __typename?: 'Index', name: string, type: string, comment: string, columns: Array<{ __typename?: 'IndexColumn', name: string, sqlType?: string | null }> };

export type TableForSchemaListFragment = { __typename?: 'Table', tableName: string, foreignKeys: Array<{ __typename?: 'ForeignKey', tableName: string, columnName: string, referencedTableName: string, foreignKeyColumn: Array<{ __typename?: 'ForeignKeyColumn', referencedColumnName: string, referrerColumnIndex: number }> }>, columns: Array<{ __typename?: 'Column', name: string, type: string, isPrimaryKey: boolean, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }>, indexes: Array<{ __typename?: 'Index', name: string, type: string, comment: string, columns: Array<{ __typename?: 'IndexColumn', name: string, sqlType?: string | null }> }> };

export type TableListForSchemasQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TableListForSchemasQuery = { __typename?: 'Query', tables: Array<{ __typename?: 'Table', tableName: string, foreignKeys: Array<{ __typename?: 'ForeignKey', tableName: string, columnName: string, referencedTableName: string, foreignKeyColumn: Array<{ __typename?: 'ForeignKeyColumn', referencedColumnName: string, referrerColumnIndex: number }> }>, columns: Array<{ __typename?: 'Column', name: string, type: string, isPrimaryKey: boolean, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }>, indexes: Array<{ __typename?: 'Index', name: string, type: string, comment: string, columns: Array<{ __typename?: 'IndexColumn', name: string, sqlType?: string | null }> }> }> };

export type RowsForDoltSchemasQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type RowsForDoltSchemasQuery = { __typename?: 'Query', doltSchemas: { __typename?: 'RowList', list: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> } };

export type RowsForDoltProceduresQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type RowsForDoltProceduresQuery = { __typename?: 'Query', doltProcedures?: { __typename?: 'RowList', list: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> } | null };

export type SignoutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignoutMutation = { __typename?: 'Mutation', signout: boolean };

export type RowForSqlDataTableFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type ColumnForSqlDataTableFragment = { __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, sourceTable?: string | null };

export type SqlSelectForSqlDataTableQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  queryString: Scalars['String']['input'];
}>;


export type SqlSelectForSqlDataTableQuery = { __typename?: 'Query', sqlSelect: { __typename?: 'SqlSelect', queryExecutionStatus: QueryExecutionStatus, queryExecutionMessage: string, columns: Array<{ __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, sourceTable?: string | null }>, rows: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> } };

export type StatusFragment = { __typename?: 'Status', _id: string, refName: string, tableName: string, staged: boolean, status: string };

export type GetStatusQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type GetStatusQuery = { __typename?: 'Query', status: Array<{ __typename?: 'Status', _id: string, refName: string, tableName: string, staged: boolean, status: string }> };

export type RestoreAllMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type RestoreAllMutation = { __typename?: 'Mutation', restoreAllTables: boolean };

export type DeploymentNameParamsFragment = { __typename?: 'DeploymentNameParams', _id: string, ownerName: string, deploymentName: string };

export type IncidentFragment = { __typename?: 'Incident', _id: string, incidentName: string, impact: IncidentImpact, summary: string, title: string, status: IncidentStatus, createdBy: string, lastModifiedBy?: string | null, createdAt: any, lastModifiedAt?: any | null, resolvedAt?: any | null, relatedDeployments: Array<{ __typename?: 'DeploymentNameParams', _id: string, ownerName: string, deploymentName: string }> };

export type CreateIncidentMutationVariables = Exact<{
  username: Scalars['String']['input'];
  deployments: Array<DeployInput> | DeployInput;
  title: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  impact: IncidentImpact;
}>;


export type CreateIncidentMutation = { __typename?: 'Mutation', createIncident: { __typename?: 'Incident', _id: string, incidentName: string, impact: IncidentImpact, summary: string, title: string, status: IncidentStatus, createdBy: string, lastModifiedBy?: string | null, createdAt: any, lastModifiedAt?: any | null, resolvedAt?: any | null, relatedDeployments: Array<{ __typename?: 'DeploymentNameParams', _id: string, ownerName: string, deploymentName: string }> } };

export type UpdateIncidentMutationVariables = Exact<{
  incidentName: Scalars['String']['input'];
  deployments: Array<DeployInput> | DeployInput;
  title: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  impact: IncidentImpact;
  status: IncidentStatus;
}>;


export type UpdateIncidentMutation = { __typename?: 'Mutation', updateIncident: { __typename?: 'Incident', _id: string, incidentName: string, impact: IncidentImpact, summary: string, title: string, status: IncidentStatus, createdBy: string, lastModifiedBy?: string | null, createdAt: any, lastModifiedAt?: any | null, resolvedAt?: any | null, relatedDeployments: Array<{ __typename?: 'DeploymentNameParams', _id: string, ownerName: string, deploymentName: string }> } };

export type DeployListItemForSupportFragment = { __typename?: 'DeploymentListItem', _id: string, ownerName: string, deploymentName: string, state: DeployState };

export type ListUserDeploymentsForSupportQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListUserDeploymentsForSupportQuery = { __typename?: 'Query', currentUserDeployments: { __typename?: 'DeploymentList', nextPageToken?: string | null, list: Array<{ __typename?: 'DeploymentListItem', _id: string, ownerName: string, deploymentName: string, state: DeployState }> } };

export type IncidentSummaryFragment = { __typename?: 'IncidentSummary', _id: string, incidentName: string, impact: IncidentImpact, title: string, status: IncidentStatus, createdAt: any, resolvedAt?: any | null };

export type ListIncidentsForCurrentUserQueryVariables = Exact<{
  filterByImpact?: InputMaybe<IncidentImpact>;
  filterByStatus?: InputMaybe<IncidentStatus>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListIncidentsForCurrentUserQuery = { __typename?: 'Query', currentUserIncidents: { __typename?: 'ListIncidentsRes', nextPageToken?: string | null, list: Array<{ __typename?: 'IncidentSummary', _id: string, incidentName: string, impact: IncidentImpact, title: string, status: IncidentStatus, createdAt: any, resolvedAt?: any | null }> } };

export type ColumnForTableListFragment = { __typename?: 'Column', name: string, type: string, isPrimaryKey: boolean, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null };

export type TableWithColumnsFragment = { __typename?: 'Table', tableName: string, columns: Array<{ __typename?: 'Column', name: string, type: string, isPrimaryKey: boolean, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }> };

export type TableForBranchQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
}>;


export type TableForBranchQuery = { __typename?: 'Query', table: { __typename?: 'Table', tableName: string, columns: Array<{ __typename?: 'Column', name: string, type: string, isPrimaryKey: boolean, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }> } };

export type OrgMemberListQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type OrgMemberListQuery = { __typename?: 'Query', orgMembers: Array<{ __typename?: 'OrgMember', _id: string }> };

export type UserOrgMembershipFragment = { __typename?: 'UserOrgMembership', _id: string, orgName: string, member: { __typename?: 'OrgMember', role: OrgRole }, organization: { __typename?: 'Organization', _id: string, displayName: string, hasSubscription?: boolean | null } };

export type CurrentUserOrgMembershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserOrgMembershipsQuery = { __typename?: 'Query', currentUserOrgMemberships: Array<{ __typename?: 'UserOrgMembership', _id: string, orgName: string, member: { __typename?: 'OrgMember', role: OrgRole }, organization: { __typename?: 'Organization', _id: string, displayName: string, hasSubscription?: boolean | null } }> };

export type CreateOrgMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  billingEmail: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
}>;


export type CreateOrgMutation = { __typename?: 'Mutation', createOrg: { __typename?: 'Organization', _id: string, orgName: string, displayName: string, callerRole: OrgRole, billingEmail: string, hasSamlProvider?: boolean | null } };

export type RowForSchemasFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type RowsForViewsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type RowsForViewsQuery = { __typename?: 'Query', views: { __typename?: 'RowList', list: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> } };

export type SupportedZonesFragment = { __typename?: 'SupportedZones', cloud: CloudProvider, supportedZones: Array<string> };

export type SupportedZonesQueryVariables = Exact<{
  cloud: CloudProvider;
}>;


export type SupportedZonesQuery = { __typename?: 'Query', supportedZones: { __typename?: 'SupportedZones', cloud: CloudProvider, supportedZones: Array<string> } };

export type MoneyTypeFragment = { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number };

export type CostTypeFragment = { __typename?: 'CostType', period: TimePeriod, amount: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } };

export type InstanceTypeFragment = { __typename?: 'InstanceType', id: string, name: string, cpus: number, mem: number, network: string, description: string, costs: Array<{ __typename?: 'CostType', period: TimePeriod, amount: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } }> };

export type InstanceTypesFragment = { __typename?: 'AvailableInstanceTypes', cloud: CloudProvider, zone: string, instanceTypes: Array<{ __typename?: 'InstanceType', id: string, name: string, cpus: number, mem: number, network: string, description: string, costs: Array<{ __typename?: 'CostType', period: TimePeriod, amount: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } }> }> };

export type AvailableInstanceTypesQueryVariables = Exact<{
  cloud: CloudProvider;
  zone: Scalars['String']['input'];
}>;


export type AvailableInstanceTypesQuery = { __typename?: 'Query', availableInstanceTypes: { __typename?: 'AvailableInstanceTypes', cloud: CloudProvider, zone: string, instanceTypes: Array<{ __typename?: 'InstanceType', id: string, name: string, cpus: number, mem: number, network: string, description: string, costs: Array<{ __typename?: 'CostType', period: TimePeriod, amount: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } }> }> } };

export type StorageOptionFragment = { __typename?: 'StorageOption', id: string, name: string, description: string, minSizeGb: number, maxSizeGb: number, cost: number };

export type StorageOptionsFragment = { __typename?: 'StorageOptions', cloud: CloudProvider, zone: string, instanceTypeId: string, storageOptions: Array<{ __typename?: 'StorageOption', id: string, name: string, description: string, minSizeGb: number, maxSizeGb: number, cost: number }> };

export type StorageOptionsQueryVariables = Exact<{
  cloud: CloudProvider;
  zone: Scalars['String']['input'];
  instanceTypeId: Scalars['String']['input'];
}>;


export type StorageOptionsQuery = { __typename?: 'Query', storageOptions: { __typename?: 'StorageOptions', cloud: CloudProvider, zone: string, instanceTypeId: string, storageOptions: Array<{ __typename?: 'StorageOption', id: string, name: string, description: string, minSizeGb: number, maxSizeGb: number, cost: number }> } };

export type CalcCostQueryVariables = Exact<{
  cloud: CloudProvider;
  zone: Scalars['String']['input'];
  instanceTypeId: Scalars['String']['input'];
  volumeTypeId: Scalars['String']['input'];
  volumeSize: Scalars['Int']['input'];
  replicas?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CalcCostQuery = { __typename?: 'Query', calcCost: { __typename?: 'CalcCostRes', costs: Array<{ __typename?: 'CostType', period: TimePeriod, amount: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } }> } };

export type CreateDeploymentMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  cloud: CloudProvider;
  zone: Scalars['String']['input'];
  instanceTypeId: Scalars['String']['input'];
  volumeTypeId: Scalars['String']['input'];
  volumeSize: Scalars['Int']['input'];
  flag?: InputMaybe<DeploymentFlag>;
  webPKICert?: InputMaybe<Scalars['Boolean']['input']>;
  canCreateWorkbenchUsers: Scalars['Boolean']['input'];
  backupName?: InputMaybe<BackupName>;
  exposeRemotesapiEndpoint?: InputMaybe<Scalars['Boolean']['input']>;
  existingDepSettings?: InputMaybe<ExistingDepSettings>;
  numReplicas?: InputMaybe<Scalars['Int']['input']>;
  clusterType?: InputMaybe<ClusterType>;
  usePrivateLink?: InputMaybe<Scalars['Boolean']['input']>;
  allowedPeeringAccountIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  allowedProjectIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  allowedVpcs?: InputMaybe<Array<GcpVpcInput> | GcpVpcInput>;
}>;


export type CreateDeploymentMutation = { __typename?: 'Mutation', createDeployment: { __typename?: 'DeploymentNameParams', _id: string, deploymentName: string, ownerName: string } };

export type PullNameStateForBranchesListFragment = { __typename?: 'PullNameState', deploymentName: string, ownerName: string, databaseName: string, pullId: string, state: PullState };

export type BranchPullStateForBranchesListFragment = { __typename?: 'Branch', _id: string, branchName: string, deploymentName: string, ownerName: string, databaseName: string, lastUpdated: any, isDefault?: boolean | null, pull?: { __typename?: 'PullNameState', deploymentName: string, ownerName: string, databaseName: string, pullId: string, state: PullState } | null };

export type BranchPullStateQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortBranchesBy>;
}>;


export type BranchPullStateQuery = { __typename?: 'Query', branchesPullState: { __typename?: 'BranchList', nextOffset?: number | null, list: Array<{ __typename?: 'Branch', _id: string, branchName: string, deploymentName: string, ownerName: string, databaseName: string, lastUpdated: any, isDefault?: boolean | null, pull?: { __typename?: 'PullNameState', deploymentName: string, ownerName: string, databaseName: string, pullId: string, state: PullState } | null }> } };

export type BranchForCreateBranchFragment = { __typename?: 'Branch', ownerName: string, deploymentName: string, databaseName: string, branchName: string };

export type CreateBranchMutationVariables = Exact<{
  newBranchName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  fromRefName: Scalars['String']['input'];
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'Branch', ownerName: string, deploymentName: string, databaseName: string, branchName: string } };

export type DoltWriterForHistoryFragment = { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string };

export type CommitForHistoryFragment = { __typename?: 'Commit', _id: string, ownerName: string, deploymentName: string, message: string, commitId: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } };

export type CommitListForHistoryFragment = { __typename?: 'CommitList', nextOffset?: number | null, list: Array<{ __typename?: 'Commit', _id: string, ownerName: string, deploymentName: string, message: string, commitId: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } }> };

export type HistoryForBranchQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HistoryForBranchQuery = { __typename?: 'Query', commits: { __typename?: 'CommitList', nextOffset?: number | null, list: Array<{ __typename?: 'Commit', _id: string, ownerName: string, deploymentName: string, message: string, commitId: string, committedAt: any, parents: Array<string>, committer: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } }> } };

export type CommitForAfterCommitHistoryFragment = { __typename?: 'Commit', _id: string, commitId: string, parents: Array<string>, message: string, committedAt: any, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } };

export type HistoryForCommitQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  afterCommitId: Scalars['String']['input'];
}>;


export type HistoryForCommitQuery = { __typename?: 'Query', commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string, commitId: string, parents: Array<string>, message: string, committedAt: any, committer: { __typename?: 'DoltWriter', _id: string, displayName: string, username?: string | null } }> } };

export type DefaultBranchPageQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DefaultBranchPageQuery = { __typename?: 'Query', defaultBranch?: { __typename?: 'Branch', _id: string, branchName: string, tableNames: Array<string> } | null };

export type DocRowForDocPageFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type DocForDocPageFragment = { __typename?: 'Doc', docRow?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null };

export type DocListForDocPageFragment = { __typename?: 'DocList', list: Array<{ __typename?: 'Doc', docRow?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null }> };

export type DocsRowsForDocPageQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
}>;


export type DocsRowsForDocPageQuery = { __typename?: 'Query', docs: { __typename?: 'DocList', list: Array<{ __typename?: 'Doc', docRow?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null }> } };

export type DocColumnValuesForDocPageFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type DocDataForDocPageQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  docType?: InputMaybe<DocType>;
}>;


export type DocDataForDocPageQuery = { __typename?: 'Query', docOrDefaultDoc?: { __typename?: 'Doc', docRow?: { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> } | null } | null };

export type DocPageQueryNoBranchQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type DocPageQueryNoBranchQuery = { __typename?: 'Query', branchOrDefault?: { __typename?: 'Branch', _id: string, branchName: string } | null };

export type CreatePullRequestMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fromBranchName: Scalars['String']['input'];
  toBranchName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type CreatePullRequestMutation = { __typename?: 'Mutation', createPull: { __typename?: 'Pull', _id: string, pullId: string } };

export type BranchForNewPullFragment = { __typename?: 'Branch', _id: string, branchName: string, isDefault?: boolean | null };

export type BranchesForNewPullQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type BranchesForNewPullQuery = { __typename?: 'Query', allBranches: Array<{ __typename?: 'Branch', _id: string, branchName: string, isDefault?: boolean | null }> };

export type PullPreviewFragment = { __typename?: 'PullPreview', existingPullId?: string | null, pullSummary?: { __typename?: 'PullSummary', _id: string, commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string }> } } | null };

export type PullPreviewForNewPullQueryVariables = Exact<{
  fromBranchName: Scalars['String']['input'];
  toBranchName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type PullPreviewForNewPullQuery = { __typename?: 'Query', pullPreview: { __typename?: 'PullPreview', existingPullId?: string | null, pullSummary?: { __typename?: 'PullSummary', _id: string, commits: { __typename?: 'CommitList', list: Array<{ __typename?: 'Commit', _id: string }> } } | null } };

export type BranchesForPull404PageQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type BranchesForPull404PageQuery = { __typename?: 'Query', pull: { __typename?: 'Pull', _id: string, fromBranchName: string, toBranchName: string } };

export type DeleteBranchMutationVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  branchName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type DeleteBranchMutation = { __typename?: 'Mutation', deleteBranch: boolean };

export type GetBranchForPullQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  branchName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type GetBranchForPullQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', _id: string, isDefault?: boolean | null } | null };

export type MergePullMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type MergePullMutation = { __typename?: 'Mutation', mergePull: { __typename?: 'Pull', _id: string, ownerName: string, deploymentName: string, databaseName: string, pullId: string, state: PullState, title: string, description: string, fromBranchName: string, toBranchName: string, creatorName: string, createdAt: any, afterMergeCommit?: string | null } };

export type DeletePullCommentMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;


export type DeletePullCommentMutation = { __typename?: 'Mutation', deletePullComment: { __typename?: 'PullSummary', _id: string } };

export type UpdatePullCommentMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  authorName: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type UpdatePullCommentMutation = { __typename?: 'Mutation', updatePullComment: { __typename?: 'PullSummary', _id: string } };

export type PullForPullDetailsFragment = { __typename?: 'Pull', _id: string, ownerName: string, deploymentName: string, databaseName: string, pullId: string, state: PullState, title: string, description: string, fromBranchName: string, toBranchName: string, creatorName: string, createdAt: any, afterMergeCommit?: string | null };

export type PullForPullDetailsQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type PullForPullDetailsQuery = { __typename?: 'Query', pull: { __typename?: 'Pull', _id: string, ownerName: string, deploymentName: string, databaseName: string, pullId: string, state: PullState, title: string, description: string, fromBranchName: string, toBranchName: string, creatorName: string, createdAt: any, afterMergeCommit?: string | null } };

export type PullDetailCommentFragment = { __typename?: 'PullDetailComment', _id: string, authorName: string, comment: string, createdAt: any, updatedAt: any };

export type PullDetailCommitFragment = { __typename?: 'PullDetailCommit', _id: string, username: string, message: string, createdAt: any, commitId: string, parentCommitId?: string | null };

export type PullDetailSummaryFragment = { __typename?: 'PullDetailSummary', _id: string, username: string, createdAt: any, numCommits: number };

export type PullDetailLogFragment = { __typename?: 'PullDetailLog', _id: string, username: string, createdAt: any, activity: PullActivity, involvedUser?: string | null };

type PullDetailsForPullDetails_PullDetailComment_Fragment = { __typename?: 'PullDetailComment', _id: string, authorName: string, comment: string, createdAt: any, updatedAt: any };

type PullDetailsForPullDetails_PullDetailCommit_Fragment = { __typename?: 'PullDetailCommit', _id: string, username: string, message: string, createdAt: any, commitId: string, parentCommitId?: string | null };

type PullDetailsForPullDetails_PullDetailLog_Fragment = { __typename?: 'PullDetailLog', _id: string, username: string, createdAt: any, activity: PullActivity, involvedUser?: string | null };

type PullDetailsForPullDetails_PullDetailSummary_Fragment = { __typename?: 'PullDetailSummary', _id: string, username: string, createdAt: any, numCommits: number };

export type PullDetailsForPullDetailsFragment = PullDetailsForPullDetails_PullDetailComment_Fragment | PullDetailsForPullDetails_PullDetailCommit_Fragment | PullDetailsForPullDetails_PullDetailLog_Fragment | PullDetailsForPullDetails_PullDetailSummary_Fragment;

export type PullDetailsFragment = { __typename?: 'PullWithDetails', _id: string, details?: Array<{ __typename?: 'PullDetailComment', _id: string, authorName: string, comment: string, createdAt: any, updatedAt: any } | { __typename?: 'PullDetailCommit', _id: string, username: string, message: string, createdAt: any, commitId: string, parentCommitId?: string | null } | { __typename?: 'PullDetailLog', _id: string, username: string, createdAt: any, activity: PullActivity, involvedUser?: string | null } | { __typename?: 'PullDetailSummary', _id: string, username: string, createdAt: any, numCommits: number }> | null };

export type PullDetailsForPullDetailsQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type PullDetailsForPullDetailsQuery = { __typename?: 'Query', pullWithDetails: { __typename?: 'PullWithDetails', _id: string, details?: Array<{ __typename?: 'PullDetailComment', _id: string, authorName: string, comment: string, createdAt: any, updatedAt: any } | { __typename?: 'PullDetailCommit', _id: string, username: string, message: string, createdAt: any, commitId: string, parentCommitId?: string | null } | { __typename?: 'PullDetailLog', _id: string, username: string, createdAt: any, activity: PullActivity, involvedUser?: string | null } | { __typename?: 'PullDetailSummary', _id: string, username: string, createdAt: any, numCommits: number }> | null } };

export type PullSummaryForPullDetailsFragment = { __typename?: 'PullSummary', _id: string };

export type CreatePullCommentMutationVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type CreatePullCommentMutation = { __typename?: 'Mutation', createPullComment: { __typename?: 'PullSummary', _id: string } };

export type UpdatePullInfoMutationVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  state: PullState;
}>;


export type UpdatePullInfoMutation = { __typename?: 'Mutation', updatePull: { __typename?: 'Pull', _id: string } };

export type PullForPullListFragment = { __typename?: 'Pull', _id: string, createdAt: any, ownerName: string, deploymentName: string, databaseName: string, pullId: string, creatorName: string, description: string, state: PullState, title: string, commentCount: number };

export type PullListForPullListFragment = { __typename?: 'PullList', nextPageToken?: string | null, list: Array<{ __typename?: 'Pull', _id: string, createdAt: any, ownerName: string, deploymentName: string, databaseName: string, pullId: string, creatorName: string, description: string, state: PullState, title: string, commentCount: number }> };

export type PullsForDatabaseQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
  filterByState?: InputMaybe<PullState>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type PullsForDatabaseQuery = { __typename?: 'Query', pulls: { __typename?: 'PullList', nextPageToken?: string | null, list: Array<{ __typename?: 'Pull', _id: string, createdAt: any, ownerName: string, deploymentName: string, databaseName: string, pullId: string, creatorName: string, description: string, state: PullState, title: string, commentCount: number }> } };

export type PullForPullPageQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type PullForPullPageQuery = { __typename?: 'Query', pull: { __typename?: 'Pull', _id: string, ownerName: string, deploymentName: string, databaseName: string } };

export type MergeStateFragment = { __typename?: 'MergeState', premergeFromCommit: string, premergeToCommit: string, mergeBaseCommit: string };

export type PullForDiffFragment = { __typename?: 'Pull', _id: string, fromBranchName: string, toBranchName: string, state: PullState, mergeState?: { __typename?: 'MergeState', premergeFromCommit: string, premergeToCommit: string, mergeBaseCommit: string } | null };

export type PullForDiffQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  pullId: Scalars['String']['input'];
}>;


export type PullForDiffQuery = { __typename?: 'Query', pull: { __typename?: 'Pull', _id: string, fromBranchName: string, toBranchName: string, state: PullState, mergeState?: { __typename?: 'MergeState', premergeFromCommit: string, premergeToCommit: string, mergeBaseCommit: string } | null } };

export type RefPageQueryVariables = Exact<{
  refName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RefPageQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', _id: string } | null, tableNames: { __typename?: 'TableNames', list: Array<string> } };

export type CreateTagMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  fromRefName: Scalars['String']['input'];
  author?: InputMaybe<AuthorInfo>;
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'Tag', _id: string, tagName: string, deploymentName: string, ownerName: string, message: string, taggedAt: any, commitId: string, tagger: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } } };

export type TagForListFragment = { __typename?: 'Tag', _id: string, tagName: string, deploymentName: string, ownerName: string, message: string, taggedAt: any, commitId: string, tagger: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } };

export type TagListForTagListFragment = { __typename?: 'TagList', list: Array<{ __typename?: 'Tag', _id: string, tagName: string, deploymentName: string, ownerName: string, message: string, taggedAt: any, commitId: string, tagger: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } }> };

export type TagListQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type TagListQuery = { __typename?: 'Query', tags: { __typename?: 'TagList', list: Array<{ __typename?: 'Tag', _id: string, tagName: string, deploymentName: string, ownerName: string, message: string, taggedAt: any, commitId: string, tagger: { __typename?: 'DoltWriter', _id: string, username?: string | null, displayName: string, emailAddress: string } }> } };

export type DeleteTagMutationVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', deleteTag: boolean };

export type BackupFragment = { __typename?: 'Backup', _id: string, ownerName: string, deploymentName: string, backupName: string, size: string, databases: string, createdAt?: any | null, instanceIndex: number };

export type BackupsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type BackupsQuery = { __typename?: 'Query', backups: Array<{ __typename?: 'Backup', _id: string, ownerName: string, deploymentName: string, backupName: string, size: string, databases: string, createdAt?: any | null, instanceIndex: number }> };

export type CreateBackupMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type CreateBackupMutation = { __typename?: 'Mutation', createBackup: boolean };

export type UpdateDeploymentRemotesapiMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  exposeRemotesapiEndpoint: Scalars['Boolean']['input'];
}>;


export type UpdateDeploymentRemotesapiMutation = { __typename?: 'Mutation', updateRemotesapiEndpoint: boolean };

export type BoolOverrideValidationFragment = { __typename?: 'BoolOverrideValidationType', isBool?: boolean | null };

export type IntOverrideValidationFragment = { __typename?: 'IntOverrideValidationType', maxValue?: number | null, minValue?: number | null };

export type StringOverrideValidationFragment = { __typename?: 'StringOverrideValidationType', validationRegex?: string | null };

export type EnumOverrideValidationFragment = { __typename?: 'EnumOverrideValidationType', validValuesList?: Array<string> | null };

export type UnspecifiedValidationFragment = { __typename?: 'UnspecifiedValidationType', unspecified?: boolean | null };

export type SupportedOverrideItemFragment = { __typename?: 'SupportedOverrideItem', key: string, value: string, validation: { __typename?: 'BoolOverrideValidationType', isBool?: boolean | null } | { __typename?: 'EnumOverrideValidationType', validValuesList?: Array<string> | null } | { __typename?: 'IntOverrideValidationType', maxValue?: number | null, minValue?: number | null } | { __typename?: 'StringOverrideValidationType', validationRegex?: string | null } | { __typename?: 'UnspecifiedValidationType', unspecified?: boolean | null } };

export type SupportedOverridesQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportedOverridesQuery = { __typename?: 'Query', supportedOverrides: { __typename?: 'SupportedOverridesRes', list: Array<{ __typename?: 'SupportedOverrideItem', key: string, value: string, validation: { __typename?: 'BoolOverrideValidationType', isBool?: boolean | null } | { __typename?: 'EnumOverrideValidationType', validValuesList?: Array<string> | null } | { __typename?: 'IntOverrideValidationType', maxValue?: number | null, minValue?: number | null } | { __typename?: 'StringOverrideValidationType', validationRegex?: string | null } | { __typename?: 'UnspecifiedValidationType', unspecified?: boolean | null } }> } };

export type UpdateOverridesMutationVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  overrides: Array<OverrideInput> | OverrideInput;
}>;


export type UpdateOverridesMutation = { __typename?: 'Mutation', updateOverrides: boolean };

export type OverrideItemFragment = { __typename?: 'OverrideItem', key: string, value: string };

export type OverridesQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
}>;


export type OverridesQuery = { __typename?: 'Query', overrides: { __typename?: 'OverridesRes', list: Array<{ __typename?: 'OverrideItem', key: string, value: string }> } };

export type UpdateDeploymentAllowedPeeringAccountIDsMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  allowedPeeringAccountIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type UpdateDeploymentAllowedPeeringAccountIDsMutation = { __typename?: 'Mutation', updateDeployment: { __typename?: 'Deployment', _id: string } };

export type DepInstanceFragment = { __typename?: 'DeploymentInstance', _id: string, ownerName: string, deploymentName: string, instanceID: string, host: string, index: number, isPrimary: boolean, prettyName: string, volumeTypeName: string, instanceTypeName: string, volumeSizeGb: number, application: Application, hourlyCost: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } };

export type DeploymentInstancesQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DeploymentInstancesQuery = { __typename?: 'Query', deploymentInstances: { __typename?: 'DeploymentInstanceList', list: Array<{ __typename?: 'DeploymentInstance', _id: string, ownerName: string, deploymentName: string, instanceID: string, host: string, index: number, isPrimary: boolean, prettyName: string, volumeTypeName: string, instanceTypeName: string, volumeSizeGb: number, application: Application, hourlyCost: { __typename?: 'MoneyType', currencyCode: string, nanos: number, units: number } }> } };

export type LogLineFragment = { __typename?: 'LogLine', time: any, text: string };

export type GetLogsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
  prevPageToken?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  numLines: Scalars['Int']['input'];
  instanceID?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLogsQuery = { __typename?: 'Query', logs: { __typename?: 'LogsList', nextPageToken?: string | null, prevPageToken?: string | null, list: Array<{ __typename?: 'LogLine', time: any, text: string }> } };

export type DashboardFragment = { __typename?: 'Dashboard', _id: string, ownerName: string, deploymentName: string, dashboardName: string, displayName: string };

export type GetDashboardsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type GetDashboardsQuery = { __typename?: 'Query', dashboards: Array<{ __typename?: 'Dashboard', _id: string, ownerName: string, deploymentName: string, dashboardName: string, displayName: string }> };

export type GraphFragment = { __typename?: 'Graph', _id: string, ownerName: string, deploymentName: string, graphName: string, displayName: string };

export type GetGraphsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  dashboardName: Scalars['String']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
}>;


export type GetGraphsQuery = { __typename?: 'Query', graphs: Array<{ __typename?: 'Graph', _id: string, ownerName: string, deploymentName: string, graphName: string, displayName: string }> };

export type GraphDataFragment = { __typename?: 'GraphData', metricName: string, useY0: boolean, values: Array<number> };

export type GraphDataResFragment = { __typename?: 'GraphDataRes', startTime: any, endTime: any, valueTimestamps: Array<any>, xAxis: string, y0Axis: string, y1Axis: string, graphs: Array<{ __typename?: 'GraphData', metricName: string, useY0: boolean, values: Array<number> }> };

export type GetGraphDataQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  graphName: Scalars['String']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  instanceID?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGraphDataQuery = { __typename?: 'Query', graphData: { __typename?: 'GraphDataRes', startTime: any, endTime: any, valueTimestamps: Array<any>, xAxis: string, y0Axis: string, y1Axis: string, graphs: Array<{ __typename?: 'GraphData', metricName: string, useY0: boolean, values: Array<number> }> } };

export type DepCredsFragment = { __typename?: 'DeploymentCreds', ownerName: string, deploymentName: string, keyID: string, publicKey: string };

export type DeploymentCredentialsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DeploymentCredentialsQuery = { __typename?: 'Query', deploymentCreds?: { __typename?: 'DeploymentCreds', ownerName: string, deploymentName: string, keyID: string, publicKey: string } | null };

export type CreateDeploymentCredentialsMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type CreateDeploymentCredentialsMutation = { __typename?: 'Mutation', createDeploymentCreds: boolean };

export type DeleteDeploymentCredentialsMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DeleteDeploymentCredentialsMutation = { __typename?: 'Mutation', deleteDeploymentCreds: boolean };

export type RerollDeploymentCredentialsMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type RerollDeploymentCredentialsMutation = { __typename?: 'Mutation', rerollDeploymentCreds: boolean };

export type ServiceWindowFragment = { __typename?: 'ServiceWindow', _id: string, ownerName: string, deploymentName: string, serviceWindowID: string, startHourOfDay: number, endHourOfDay: number, dayOfWeek: number };

export type ServiceWindowsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type ServiceWindowsQuery = { __typename?: 'Query', serviceWindows: Array<{ __typename?: 'ServiceWindow', _id: string, ownerName: string, deploymentName: string, serviceWindowID: string, startHourOfDay: number, endHourOfDay: number, dayOfWeek: number }> };

export type UpdateServiceWindowMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  serviceWindowID: Scalars['String']['input'];
  startHourOfDay: Scalars['Int']['input'];
  endHourOfDay: Scalars['Int']['input'];
  dayOfWeek: Scalars['Int']['input'];
}>;


export type UpdateServiceWindowMutation = { __typename?: 'Mutation', updateServiceWindow: { __typename?: 'ServiceWindow', _id: string, ownerName: string, deploymentName: string, serviceWindowID: string, startHourOfDay: number, endHourOfDay: number, dayOfWeek: number } };

export type UpdateDisableAutomaticDoltUpdatesMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  disableAutomaticDoltUpdates: Scalars['Boolean']['input'];
}>;


export type UpdateDisableAutomaticDoltUpdatesMutation = { __typename?: 'Mutation', updateDeployment: { __typename?: 'Deployment', _id: string, ownerName: string, deploymentName: string, doltVersion: string, state: DeployState, cloud: CloudProvider, zone: string, instanceTypeName: string, volumeTypeName: string, volumeSizeGb: number, host: string, port: number, username: string, password: string, createdAt: any, destroyAt: any, role: DeploymentRole, webPKICert: boolean, hourlyCostUsd: number, serverCertCa: string, exposeRemotesapiEndpoint: boolean, numReplicas: number, createdBy?: string | null, destroyedBy?: string | null, disableAutomaticDoltUpdates: boolean, clusterType: ClusterType, privateLinkConfig?: { __typename?: 'PrivateLinkConfig', usePrivateLink: boolean, allowedPeeringAccountIds: Array<string>, endpoints: Array<{ __typename?: 'PrivateLinkEndpoint', hostname: string, state: PrivateLinkEndpointState, serviceName: string }> } | null, pscConfig?: { __typename?: 'PrivateServiceConnectConfig', usePrivateServiceConnect: boolean, allowedProjectIds: Array<string>, targetService: string, consumerServiceName: string, targetReadService: string, consumerReadServiceName: string, baseUrl: string, allowedVpcs: Array<{ __typename?: 'GcpVpcs', projectId: string, networkName: string }> } | null } };

export type DeploymentCollaboratorFragment = { __typename?: 'DeploymentCollaborator', _id: string, ownerName: string, deploymentName: string, collaboratorName: string, permissionLevel: CollabPermLevel, email?: string | null };

export type CreateCollabMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  collaboratorName: Scalars['String']['input'];
  permLevel: CollabPermLevel;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateCollabMutation = { __typename?: 'Mutation', createCollab: { __typename?: 'DeploymentCollaborator', _id: string, ownerName: string, deploymentName: string, collaboratorName: string, permissionLevel: CollabPermLevel, email?: string | null } };

export type TeamForTeamPermsFragment = { __typename?: 'Team', _id: string, teamName: string };

export type TeamsForPermsQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type TeamsForPermsQuery = { __typename?: 'Query', teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string }> } };

export type CreateDepTeamPermMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  permLevel: TeamPermLevel;
}>;


export type CreateDepTeamPermMutation = { __typename?: 'Mutation', createDepTeamPerm: { __typename?: 'DepTeamPerm', _id: string, ownerName: string, depName: string, teamName: string, permLevel: TeamPermLevel, team?: { __typename?: 'Team', _id: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string }> } } | null } };

export type ListCollabsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListCollabsQuery = { __typename?: 'Query', collaborators: { __typename?: 'CollabList', nextPageToken?: string | null, list: Array<{ __typename?: 'DeploymentCollaborator', _id: string, ownerName: string, deploymentName: string, collaboratorName: string, permissionLevel: CollabPermLevel, email?: string | null }> } };

export type DeleteCollabMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  collaboratorName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteCollabMutation = { __typename?: 'Mutation', deleteCollab: boolean };

export type UpdateCollabMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  collaboratorName: Scalars['String']['input'];
  permLevel: CollabPermLevel;
}>;


export type UpdateCollabMutation = { __typename?: 'Mutation', updateCollab: { __typename?: 'DeploymentCollaborator', _id: string, ownerName: string, deploymentName: string, collaboratorName: string, permissionLevel: CollabPermLevel, email?: string | null } };

export type DepTeamPermFragment = { __typename?: 'DepTeamPerm', _id: string, ownerName: string, depName: string, teamName: string, permLevel: TeamPermLevel, team?: { __typename?: 'Team', _id: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string }> } } | null };

export type DepTeamPermsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DepTeamPermsQuery = { __typename?: 'Query', depTeamPerms: { __typename?: 'DepTeamPermList', list: Array<{ __typename?: 'DepTeamPerm', _id: string, ownerName: string, depName: string, teamName: string, permLevel: TeamPermLevel, team?: { __typename?: 'Team', _id: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string }> } } | null }> } };

export type DeleteDepTeamPermMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
}>;


export type DeleteDepTeamPermMutation = { __typename?: 'Mutation', deleteDepTeamPerm: boolean };

export type UpdateDepTeamPermMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  permLevel: TeamPermLevel;
}>;


export type UpdateDepTeamPermMutation = { __typename?: 'Mutation', updateDepTeamPerm: { __typename?: 'DepTeamPerm', _id: string, ownerName: string, depName: string, teamName: string, permLevel: TeamPermLevel, team?: { __typename?: 'Team', _id: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string }> } } | null } };

export type DeploymentDatabasesQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DeploymentDatabasesQuery = { __typename?: 'Query', databases: Array<string> };

export type CreateDatabaseMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
}>;


export type CreateDatabaseMutation = { __typename?: 'Mutation', createDatabase: string };

export type WorkbenchSettingsFragment = { __typename?: 'WorkbenchSettings', _id: string, enableWrites: boolean, deleteSourceBranchOnAcceptedMerge: boolean };

export type WorkbenchSettingsQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type WorkbenchSettingsQuery = { __typename?: 'Query', workbenchSettings: { __typename?: 'WorkbenchSettings', _id: string, enableWrites: boolean, deleteSourceBranchOnAcceptedMerge: boolean } };

export type UpdateWorkbenchSettingsMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  enableWrites?: InputMaybe<Scalars['Boolean']['input']>;
  deleteSourceBranchOnAcceptedMerge?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateWorkbenchSettingsMutation = { __typename?: 'Mutation', updateWorkbenchSettings: { __typename?: 'WorkbenchSettings', _id: string, enableWrites: boolean, deleteSourceBranchOnAcceptedMerge: boolean } };

export type DestroyDeploymentMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type DestroyDeploymentMutation = { __typename?: 'Mutation', destroyDeployment: boolean };

export type UpdateDoltMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  desiredVersion: Scalars['String']['input'];
}>;


export type UpdateDoltMutation = { __typename?: 'Mutation', updateDolt: boolean };

export type ListDoltVersionsQueryVariables = Exact<{
  clusterType?: InputMaybe<ClusterType>;
}>;


export type ListDoltVersionsQuery = { __typename?: 'Query', doltVersions: Array<string> };

export type RebootInstanceMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
}>;


export type RebootInstanceMutation = { __typename?: 'Mutation', rebootInstance: boolean };

export type RestartDoltMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
}>;


export type RestartDoltMutation = { __typename?: 'Mutation', restartDolt: boolean };

export type PrivateLinkEndpointFragment = { __typename?: 'PrivateLinkEndpoint', hostname: string, state: PrivateLinkEndpointState, serviceName: string };

export type PrivateLinkConfigFragment = { __typename?: 'PrivateLinkConfig', usePrivateLink: boolean, allowedPeeringAccountIds: Array<string>, endpoints: Array<{ __typename?: 'PrivateLinkEndpoint', hostname: string, state: PrivateLinkEndpointState, serviceName: string }> };

export type PrivateServiceConnectConfigFragment = { __typename?: 'PrivateServiceConnectConfig', usePrivateServiceConnect: boolean, allowedProjectIds: Array<string>, targetService: string, consumerServiceName: string, targetReadService: string, consumerReadServiceName: string, baseUrl: string, allowedVpcs: Array<{ __typename?: 'GcpVpcs', projectId: string, networkName: string }> };

export type DeploymentFragment = { __typename?: 'Deployment', _id: string, ownerName: string, deploymentName: string, doltVersion: string, state: DeployState, cloud: CloudProvider, zone: string, instanceTypeName: string, volumeTypeName: string, volumeSizeGb: number, host: string, port: number, username: string, password: string, createdAt: any, destroyAt: any, role: DeploymentRole, webPKICert: boolean, hourlyCostUsd: number, serverCertCa: string, exposeRemotesapiEndpoint: boolean, numReplicas: number, createdBy?: string | null, destroyedBy?: string | null, disableAutomaticDoltUpdates: boolean, clusterType: ClusterType, privateLinkConfig?: { __typename?: 'PrivateLinkConfig', usePrivateLink: boolean, allowedPeeringAccountIds: Array<string>, endpoints: Array<{ __typename?: 'PrivateLinkEndpoint', hostname: string, state: PrivateLinkEndpointState, serviceName: string }> } | null, pscConfig?: { __typename?: 'PrivateServiceConnectConfig', usePrivateServiceConnect: boolean, allowedProjectIds: Array<string>, targetService: string, consumerServiceName: string, targetReadService: string, consumerReadServiceName: string, baseUrl: string, allowedVpcs: Array<{ __typename?: 'GcpVpcs', projectId: string, networkName: string }> } | null };

export type GetDeploymentQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
}>;


export type GetDeploymentQuery = { __typename?: 'Query', deployment: { __typename?: 'Deployment', _id: string, ownerName: string, deploymentName: string, doltVersion: string, state: DeployState, cloud: CloudProvider, zone: string, instanceTypeName: string, volumeTypeName: string, volumeSizeGb: number, host: string, port: number, username: string, password: string, createdAt: any, destroyAt: any, role: DeploymentRole, webPKICert: boolean, hourlyCostUsd: number, serverCertCa: string, exposeRemotesapiEndpoint: boolean, numReplicas: number, createdBy?: string | null, destroyedBy?: string | null, disableAutomaticDoltUpdates: boolean, clusterType: ClusterType, privateLinkConfig?: { __typename?: 'PrivateLinkConfig', usePrivateLink: boolean, allowedPeeringAccountIds: Array<string>, endpoints: Array<{ __typename?: 'PrivateLinkEndpoint', hostname: string, state: PrivateLinkEndpointState, serviceName: string }> } | null, pscConfig?: { __typename?: 'PrivateServiceConnectConfig', usePrivateServiceConnect: boolean, allowedProjectIds: Array<string>, targetService: string, consumerServiceName: string, targetReadService: string, consumerReadServiceName: string, baseUrl: string, allowedVpcs: Array<{ __typename?: 'GcpVpcs', projectId: string, networkName: string }> } | null } };

export type ChangePrimaryMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
}>;


export type ChangePrimaryMutation = { __typename?: 'Mutation', changePrimary: boolean };

export type DeleteInstanceMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  instanceID: Scalars['String']['input'];
}>;


export type DeleteInstanceMutation = { __typename?: 'Mutation', deleteInstance: boolean };

export type AddInstanceMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  instanceTypeId: Scalars['String']['input'];
  volumeTypeId: Scalars['String']['input'];
  volumeSize: Scalars['Int']['input'];
  backupName?: InputMaybe<BackupName>;
}>;


export type AddInstanceMutation = { __typename?: 'Mutation', addInstance: boolean };

export type VerifyEmailMutationVariables = Exact<{
  username: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  verifyToken: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: { __typename?: 'EmailAddress', _id: string, username: string, address: string } };

export type LoadDataMutationVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
  importOp: ImportOperation;
  fileType: FileType;
  file: Scalars['Upload']['input'];
  modifier?: InputMaybe<LoadDataModifier>;
}>;


export type LoadDataMutation = { __typename?: 'Mutation', loadDataFile: boolean };

export type UpdateOrgMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  billingEmail: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
}>;


export type UpdateOrgMutation = { __typename?: 'Mutation', updateOrg: { __typename?: 'Organization', _id: string, orgName: string, displayName: string, callerRole: OrgRole, billingEmail: string, hasSamlProvider?: boolean | null } };

export type DeleteOrgMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type DeleteOrgMutation = { __typename?: 'Mutation', deleteOrg: boolean };

export type OrgMemberFragment = { __typename?: 'OrgMember', _id: string, orgName: string, username: string, role: OrgRole, availableCallerActions: Array<OrgMemberAction>, emailAddress?: string | null, teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string }> } };

export type OrgMembersQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type OrgMembersQuery = { __typename?: 'Query', orgMembers: Array<{ __typename?: 'OrgMember', _id: string, orgName: string, username: string, role: OrgRole, availableCallerActions: Array<OrgMemberAction>, emailAddress?: string | null, teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string }> } }> };

export type CreateOrgMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  role: OrgRole;
  newMember?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrgMemberMutation = { __typename?: 'Mutation', createOrgMember: { __typename?: 'OrgMember', _id: string, orgName: string, username: string, role: OrgRole, availableCallerActions: Array<OrgMemberAction>, emailAddress?: string | null, teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string }> } } };

export type DeleteOrgMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteOrgMemberMutation = { __typename?: 'Mutation', deleteOrgMember: boolean };

export type UpdateOrgMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  username: Scalars['String']['input'];
  role: OrgRole;
}>;


export type UpdateOrgMemberMutation = { __typename?: 'Mutation', updateOrgMember: { __typename?: 'OrgMember', _id: string, orgName: string, username: string, role: OrgRole, availableCallerActions: Array<OrgMemberAction>, emailAddress?: string | null, teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string }> } } };

export type OrgIdentityProviderFragment = { __typename?: 'OrgIdentityProvider', _id: string, orgName: string };

export type CreateOrgIdentityProviderMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  metadataDescriptor: Scalars['String']['input'];
}>;


export type CreateOrgIdentityProviderMutation = { __typename?: 'Mutation', createOrgIdentityProvider: { __typename?: 'OrgIdentityProvider', _id: string, orgName: string } };

export type DeleteOrgIdentityProviderMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type DeleteOrgIdentityProviderMutation = { __typename?: 'Mutation', deleteOrgIdentityProvider: boolean };

export type UpdateOrgIdentityProviderMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  metadataDescriptor: Scalars['String']['input'];
}>;


export type UpdateOrgIdentityProviderMutation = { __typename?: 'Mutation', updateOrgIdentityProvider: { __typename?: 'OrgIdentityProvider', _id: string, orgName: string } };

export type GetOrgIdentityProviderResFragment = { __typename?: 'GetOrgIdentityProviderRes', descriptor?: string | null, acsUrl: string, audienceUri: string, providerCertCa: string, provider?: { __typename?: 'OrgIdentityProvider', _id: string, orgName: string } | null };

export type OrgIdentityProviderQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
  includeDescriptor?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrgIdentityProviderQuery = { __typename?: 'Query', orgIdentityProvider: { __typename?: 'GetOrgIdentityProviderRes', descriptor?: string | null, acsUrl: string, audienceUri: string, providerCertCa: string, provider?: { __typename?: 'OrgIdentityProvider', _id: string, orgName: string } | null } };

export type DeleteTeamMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: boolean };

export type UpdateTeamMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  callerRole: TeamRole;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } } };

export type CreateTeamMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  memberName: Scalars['String']['input'];
  role: TeamRole;
}>;


export type CreateTeamMemberMutation = { __typename?: 'Mutation', createTeamMember: { __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole } };

export type DeleteTeamMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  memberName: Scalars['String']['input'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'Mutation', deleteTeamMember: boolean };

export type UpdateTeamMemberMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  memberName: Scalars['String']['input'];
  role: TeamRole;
}>;


export type UpdateTeamMemberMutation = { __typename?: 'Mutation', updateTeamMember: { __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole } };

export type TeamForProfileFragment = { __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, callerRole: TeamRole, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } };

export type TeamForOrgQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
}>;


export type TeamForOrgQuery = { __typename?: 'Query', team: { __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, callerRole: TeamRole, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } } };

export type CreateTeamMutationVariables = Exact<{
  orgName: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  callerRole: TeamRole;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } } };

export type TeamMemberForOrgFragment = { __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole };

export type TeamForOrgFragment = { __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } };

export type TeamListForOrgFragment = { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } }> };

export type TeamListForOrgQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type TeamListForOrgQuery = { __typename?: 'Query', teams: { __typename?: 'TeamList', list: Array<{ __typename?: 'Team', _id: string, teamName: string, orgName: string, description: string, members: { __typename?: 'TeamMemberList', list: Array<{ __typename?: 'TeamMember', _id: string, memberName: string, role: TeamRole }> } }> } };

export type OrganizationFragment = { __typename?: 'Organization', _id: string, orgName: string, displayName: string, callerRole: OrgRole, billingEmail: string, hasSamlProvider?: boolean | null };

export type GetOrganizationQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', org: { __typename?: 'Organization', _id: string, orgName: string, displayName: string, callerRole: OrgRole, billingEmail: string, hasSamlProvider?: boolean | null } };

export type GetSamlAuthnRequestQueryVariables = Exact<{
  orgName: Scalars['String']['input'];
}>;


export type GetSamlAuthnRequestQuery = { __typename?: 'Query', samlAuthnRequest: { __typename?: 'SAMLAuthnRequest', authnRequest: string, httpPostUrl: string } };

export type UpdatePasswordFromTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordFromTokenMutation = { __typename?: 'Mutation', updatePasswordFromToken: boolean };

export type UpdateCurrentUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  company: Scalars['String']['input'];
}>;


export type UpdateCurrentUserMutation = { __typename?: 'Mutation', updateCurrentUser: { __typename?: 'User', _id: string, company: string, username: string, displayName: string, provider?: IdentityProvider | null, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean }> } };

export type CreateEmailMutationVariables = Exact<{
  username: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
}>;


export type CreateEmailMutation = { __typename?: 'Mutation', createEmail: { __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean } };

export type MakeEmailPrimaryMutationVariables = Exact<{
  username: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
}>;


export type MakeEmailPrimaryMutation = { __typename?: 'Mutation', makeEmailPrimary: { __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean } };

export type DeleteEmailMutationVariables = Exact<{
  username: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
}>;


export type DeleteEmailMutation = { __typename?: 'Mutation', deleteEmail: boolean };

export type UpdatePasswordMutationVariables = Exact<{
  username: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: boolean };

export type SendPasswordRecoveryEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendPasswordRecoveryEmailMutation = { __typename?: 'Mutation', sendPasswordRecoveryEmail: boolean };

export type AuthUserFragment = { __typename?: 'User', _id: string, username: string, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, isVerified: boolean }> };

export type CreateUserMutationVariables = Exact<{
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
  provider: IdentityProvider;
  email?: InputMaybe<Scalars['String']['input']>;
  samlOrgName?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', _id: string, username: string, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, isVerified: boolean }> } };

export type SigninMutationVariables = Exact<{
  token: Scalars['String']['input'];
  provider: IdentityProvider;
  username?: InputMaybe<Scalars['String']['input']>;
  samlOrgName?: InputMaybe<Scalars['String']['input']>;
}>;


export type SigninMutation = { __typename?: 'Mutation', signin: { __typename?: 'User', _id: string, username: string, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, isVerified: boolean }> } };

export type GetIncidentQueryVariables = Exact<{
  incidentName: Scalars['String']['input'];
}>;


export type GetIncidentQuery = { __typename?: 'Query', incident: { __typename?: 'Incident', _id: string, incidentName: string, impact: IncidentImpact, summary: string, title: string, status: IncidentStatus, createdBy: string, lastModifiedBy?: string | null, createdAt: any, lastModifiedAt?: any | null, resolvedAt?: any | null, relatedDeployments: Array<{ __typename?: 'DeploymentNameParams', _id: string, ownerName: string, deploymentName: string }> } };

export type AuthedPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthedPageQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', _id: string, username: string } };

export type ColumnForDataTableFragment = { __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, sourceTable?: string | null, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null };

export type ForeignKeyColumnForDataTableFragment = { __typename?: 'ForeignKeyColumn', referencedColumnName: string, referrerColumnIndex: number };

export type ForeignKeysForDataTableFragment = { __typename?: 'ForeignKey', tableName: string, columnName: string, referencedTableName: string, foreignKeyColumn: Array<{ __typename?: 'ForeignKeyColumn', referencedColumnName: string, referrerColumnIndex: number }> };

export type DataTableQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
}>;


export type DataTableQuery = { __typename?: 'Query', table: { __typename?: 'Table', columns: Array<{ __typename?: 'Column', name: string, isPrimaryKey: boolean, type: string, sourceTable?: string | null, constraints?: Array<{ __typename?: 'ColConstraint', notNull: boolean }> | null }>, foreignKeys: Array<{ __typename?: 'ForeignKey', tableName: string, columnName: string, referencedTableName: string, foreignKeyColumn: Array<{ __typename?: 'ForeignKeyColumn', referencedColumnName: string, referrerColumnIndex: number }> }> } };

export type RowForDataTableFragment = { __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> };

export type RowListRowsFragment = { __typename?: 'RowList', nextOffset?: number | null, list: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> };

export type RowsForDataTableQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  revisionType: RevisionType;
  tableName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RowsForDataTableQuery = { __typename?: 'Query', rows: { __typename?: 'RowList', nextOffset?: number | null, list: Array<{ __typename?: 'Row', columnValues: Array<{ __typename?: 'ColumnValue', displayValue: string }> }> } };

export type DiffSummaryFragment = { __typename?: 'DiffSummary', _id: string, fromTableName: string, toTableName: string, tableName: string, tableType: TableDiffType, hasDataChanges: boolean, hasSchemaChanges: boolean };

export type DiffSummariesQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  fromCommitId: Scalars['String']['input'];
  toCommitId: Scalars['String']['input'];
  refName?: InputMaybe<Scalars['String']['input']>;
}>;


export type DiffSummariesQuery = { __typename?: 'Query', diffSummaries: Array<{ __typename?: 'DiffSummary', _id: string, fromTableName: string, toTableName: string, tableName: string, tableType: TableDiffType, hasDataChanges: boolean, hasSchemaChanges: boolean }> };

export type BranchForCommitGraphFragment = { __typename?: 'Branch', branchName: string, head?: string | null };

export type BranchListForCommitGraphQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type BranchListForCommitGraphQuery = { __typename?: 'Query', branches: { __typename?: 'BranchList', nextOffset?: number | null, list: Array<{ __typename?: 'Branch', branchName: string, head?: string | null }> } };

export type EmailAddressFragment = { __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean };

export type CurrentUserFragment = { __typename?: 'User', _id: string, company: string, username: string, displayName: string, provider?: IdentityProvider | null, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean }> };

export type UseCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type UseCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', _id: string, company: string, username: string, displayName: string, provider?: IdentityProvider | null, emailAddressesList: Array<{ __typename?: 'EmailAddress', _id: string, address: string, isPrimary: boolean, isVerified: boolean }> } };

export type SubscriptionForBannerFragment = { __typename?: 'Subscription', _id: string, ownerName: string, ownerType: OwnerType, type: SubscriptionType };

export type CurrentUserSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserSubscriptionsQuery = { __typename?: 'Query', currentUserSubscriptions: Array<{ __typename?: 'Subscription', _id: string, ownerName: string, ownerType: OwnerType, type: SubscriptionType }> };

export type DepForDepRoleFragment = { __typename?: 'Deployment', _id: string, role: DeploymentRole, currentConfigItems?: Array<{ __typename?: 'OverrideItem', key: string, value: string }> | null };

export type DepForDepRoleQueryVariables = Exact<{
  deploymentName: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
}>;


export type DepForDepRoleQuery = { __typename?: 'Query', deployment: { __typename?: 'Deployment', _id: string, role: DeploymentRole, currentConfigItems?: Array<{ __typename?: 'OverrideItem', key: string, value: string }> | null } };

export type DeploymentOwnerQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
}>;


export type DeploymentOwnerQuery = { __typename?: 'Query', deploymentOwner: { __typename?: 'DeploymentOwner', username?: string | null, orgName?: string | null, displayName?: string | null } };

export type ResendVerifyEmailMutationVariables = Exact<{
  username: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
}>;


export type ResendVerifyEmailMutation = { __typename?: 'Mutation', resendVerifyEmail: boolean };

export type TableNamesQueryVariables = Exact<{
  ownerName: Scalars['String']['input'];
  deploymentName: Scalars['String']['input'];
  databaseName: Scalars['String']['input'];
  refName: Scalars['String']['input'];
  filterSystemTables?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TableNamesQuery = { __typename?: 'Query', tableNames: { __typename?: 'TableNames', list: Array<string> } };

export const SubscriptionForBillingFragmentDoc = gql`
    fragment SubscriptionForBilling on Subscription {
  _id
  type
}
    `;
export const OrganizationForBillingFragmentDoc = gql`
    fragment OrganizationForBilling on Organization {
  _id
  callerRole
}
    `;
export const DeployListItemFragmentDoc = gql`
    fragment DeployListItem on DeploymentListItem {
  _id
  ownerName
  deploymentName
  state
  cloud
  zone
  hourlyCostUsd
  numReplicas
  doltVersion
  lastBackupTime
  lastBackupSize
  clusterType
}
    `;
export const CommitForDiffSelectorFragmentDoc = gql`
    fragment CommitForDiffSelector on Commit {
  _id
  commitId
  message
  committedAt
  parents
  committer {
    _id
    displayName
    username
  }
}
    `;
export const CommitListForDiffSelectorFragmentDoc = gql`
    fragment CommitListForDiffSelector on CommitList {
  list {
    ...CommitForDiffSelector
  }
}
    ${CommitForDiffSelectorFragmentDoc}`;
export const PullSummaryForDiffSelectorFragmentDoc = gql`
    fragment PullSummaryForDiffSelector on PullSummary {
  _id
  commits {
    ...CommitListForDiffSelector
  }
}
    ${CommitListForDiffSelectorFragmentDoc}`;
export const DiffStatForDiffsFragmentDoc = gql`
    fragment DiffStatForDiffs on DiffStat {
  rowsUnmodified
  rowsAdded
  rowsDeleted
  rowsModified
  cellsModified
  rowCount
  cellCount
}
    `;
export const ColumnValueForTableListFragmentDoc = gql`
    fragment ColumnValueForTableList on ColumnValue {
  displayValue
}
    `;
export const RowForTableListFragmentDoc = gql`
    fragment RowForTableList on Row {
  columnValues {
    ...ColumnValueForTableList
  }
}
    ${ColumnValueForTableListFragmentDoc}`;
export const RowDiffForTableListFragmentDoc = gql`
    fragment RowDiffForTableList on RowDiff {
  added {
    ...RowForTableList
  }
  deleted {
    ...RowForTableList
  }
}
    ${RowForTableListFragmentDoc}`;
export const ColumnForDiffTableListFragmentDoc = gql`
    fragment ColumnForDiffTableList on Column {
  name
  isPrimaryKey
  type
  constraints {
    notNull
  }
}
    `;
export const RowDiffListWithColsFragmentDoc = gql`
    fragment RowDiffListWithCols on RowDiffList {
  list {
    ...RowDiffForTableList
  }
  columns {
    ...ColumnForDiffTableList
  }
  nextOffset
}
    ${RowDiffForTableListFragmentDoc}
${ColumnForDiffTableListFragmentDoc}`;
export const SchemaDiffForTableListFragmentDoc = gql`
    fragment SchemaDiffForTableList on TextDiff {
  leftLines
  rightLines
}
    `;
export const SchemaDiffFragmentDoc = gql`
    fragment SchemaDiff on SchemaDiff {
  schemaDiff {
    ...SchemaDiffForTableList
  }
  schemaPatch
}
    ${SchemaDiffForTableListFragmentDoc}`;
export const BranchForBranchSelectorFragmentDoc = gql`
    fragment BranchForBranchSelector on Branch {
  branchName
  deploymentName
  ownerName
  databaseName
}
    `;
export const ForeignKeyColumnForDataTableFragmentDoc = gql`
    fragment ForeignKeyColumnForDataTable on ForeignKeyColumn {
  referencedColumnName
  referrerColumnIndex
}
    `;
export const ForeignKeysForDataTableFragmentDoc = gql`
    fragment ForeignKeysForDataTable on ForeignKey {
  tableName
  columnName
  referencedTableName
  foreignKeyColumn {
    ...ForeignKeyColumnForDataTable
  }
}
    ${ForeignKeyColumnForDataTableFragmentDoc}`;
export const ColumnForTableListFragmentDoc = gql`
    fragment ColumnForTableList on Column {
  name
  type
  isPrimaryKey
  constraints {
    notNull
  }
}
    `;
export const ColumnsListForTableListFragmentDoc = gql`
    fragment ColumnsListForTableList on IndexColumn {
  name
  sqlType
}
    `;
export const IndexForTableListFragmentDoc = gql`
    fragment IndexForTableList on Index {
  name
  type
  comment
  columns {
    ...ColumnsListForTableList
  }
}
    ${ColumnsListForTableListFragmentDoc}`;
export const TableForSchemaListFragmentDoc = gql`
    fragment TableForSchemaList on Table {
  tableName
  foreignKeys {
    ...ForeignKeysForDataTable
  }
  columns {
    ...ColumnForTableList
  }
  indexes {
    ...IndexForTableList
  }
}
    ${ForeignKeysForDataTableFragmentDoc}
${ColumnForTableListFragmentDoc}
${IndexForTableListFragmentDoc}`;
export const RowForSqlDataTableFragmentDoc = gql`
    fragment RowForSqlDataTable on Row {
  columnValues {
    displayValue
  }
}
    `;
export const ColumnForSqlDataTableFragmentDoc = gql`
    fragment ColumnForSqlDataTable on Column {
  name
  isPrimaryKey
  type
  sourceTable
}
    `;
export const StatusFragmentDoc = gql`
    fragment Status on Status {
  _id
  refName
  tableName
  staged
  status
}
    `;
export const DeploymentNameParamsFragmentDoc = gql`
    fragment DeploymentNameParams on DeploymentNameParams {
  _id
  ownerName
  deploymentName
}
    `;
export const IncidentFragmentDoc = gql`
    fragment Incident on Incident {
  _id
  incidentName
  impact
  summary
  title
  status
  createdBy
  lastModifiedBy
  createdAt
  lastModifiedAt
  resolvedAt
  relatedDeployments {
    ...DeploymentNameParams
  }
}
    ${DeploymentNameParamsFragmentDoc}`;
export const DeployListItemForSupportFragmentDoc = gql`
    fragment DeployListItemForSupport on DeploymentListItem {
  _id
  ownerName
  deploymentName
  state
}
    `;
export const IncidentSummaryFragmentDoc = gql`
    fragment IncidentSummary on IncidentSummary {
  _id
  incidentName
  impact
  title
  status
  createdAt
  resolvedAt
}
    `;
export const TableWithColumnsFragmentDoc = gql`
    fragment TableWithColumns on Table {
  tableName
  columns {
    ...ColumnForTableList
  }
}
    ${ColumnForTableListFragmentDoc}`;
export const UserOrgMembershipFragmentDoc = gql`
    fragment UserOrgMembership on UserOrgMembership {
  _id
  orgName
  member {
    role
  }
  organization {
    _id
    displayName
    hasSubscription
  }
}
    `;
export const RowForSchemasFragmentDoc = gql`
    fragment RowForSchemas on Row {
  columnValues {
    displayValue
  }
}
    `;
export const SupportedZonesFragmentDoc = gql`
    fragment SupportedZones on SupportedZones {
  cloud
  supportedZones
}
    `;
export const MoneyTypeFragmentDoc = gql`
    fragment MoneyType on MoneyType {
  currencyCode
  nanos
  units
}
    `;
export const CostTypeFragmentDoc = gql`
    fragment CostType on CostType {
  amount {
    ...MoneyType
  }
  period
}
    ${MoneyTypeFragmentDoc}`;
export const InstanceTypeFragmentDoc = gql`
    fragment InstanceType on InstanceType {
  id
  name
  cpus
  mem
  network
  description
  costs {
    ...CostType
  }
}
    ${CostTypeFragmentDoc}`;
export const InstanceTypesFragmentDoc = gql`
    fragment InstanceTypes on AvailableInstanceTypes {
  cloud
  zone
  instanceTypes {
    ...InstanceType
  }
}
    ${InstanceTypeFragmentDoc}`;
export const StorageOptionFragmentDoc = gql`
    fragment StorageOption on StorageOption {
  id
  name
  description
  minSizeGb
  maxSizeGb
  cost
}
    `;
export const StorageOptionsFragmentDoc = gql`
    fragment StorageOptions on StorageOptions {
  cloud
  zone
  instanceTypeId
  storageOptions {
    ...StorageOption
  }
}
    ${StorageOptionFragmentDoc}`;
export const PullNameStateForBranchesListFragmentDoc = gql`
    fragment PullNameStateForBranchesList on PullNameState {
  deploymentName
  ownerName
  databaseName
  pullId
  state
}
    `;
export const BranchPullStateForBranchesListFragmentDoc = gql`
    fragment BranchPullStateForBranchesList on Branch {
  _id
  branchName
  deploymentName
  ownerName
  databaseName
  lastUpdated
  isDefault
  pull {
    ...PullNameStateForBranchesList
  }
}
    ${PullNameStateForBranchesListFragmentDoc}`;
export const BranchForCreateBranchFragmentDoc = gql`
    fragment BranchForCreateBranch on Branch {
  ownerName
  deploymentName
  databaseName
  branchName
}
    `;
export const DoltWriterForHistoryFragmentDoc = gql`
    fragment DoltWriterForHistory on DoltWriter {
  _id
  username
  displayName
  emailAddress
}
    `;
export const CommitForHistoryFragmentDoc = gql`
    fragment CommitForHistory on Commit {
  _id
  ownerName
  deploymentName
  committer {
    ...DoltWriterForHistory
  }
  message
  commitId
  committedAt
  parents
}
    ${DoltWriterForHistoryFragmentDoc}`;
export const CommitListForHistoryFragmentDoc = gql`
    fragment CommitListForHistory on CommitList {
  list {
    ...CommitForHistory
  }
  nextOffset
}
    ${CommitForHistoryFragmentDoc}`;
export const CommitForAfterCommitHistoryFragmentDoc = gql`
    fragment CommitForAfterCommitHistory on Commit {
  _id
  commitId
  parents
  message
  committedAt
  committer {
    _id
    displayName
    username
  }
}
    `;
export const DocRowForDocPageFragmentDoc = gql`
    fragment DocRowForDocPage on Row {
  columnValues {
    displayValue
  }
}
    `;
export const DocForDocPageFragmentDoc = gql`
    fragment DocForDocPage on Doc {
  docRow {
    ...DocRowForDocPage
  }
}
    ${DocRowForDocPageFragmentDoc}`;
export const DocListForDocPageFragmentDoc = gql`
    fragment DocListForDocPage on DocList {
  list {
    ...DocForDocPage
  }
}
    ${DocForDocPageFragmentDoc}`;
export const DocColumnValuesForDocPageFragmentDoc = gql`
    fragment DocColumnValuesForDocPage on Row {
  columnValues {
    displayValue
  }
}
    `;
export const BranchForNewPullFragmentDoc = gql`
    fragment BranchForNewPull on Branch {
  _id
  branchName
  isDefault
}
    `;
export const PullPreviewFragmentDoc = gql`
    fragment PullPreview on PullPreview {
  pullSummary {
    _id
    commits {
      list {
        _id
      }
    }
  }
  existingPullId
}
    `;
export const PullForPullDetailsFragmentDoc = gql`
    fragment PullForPullDetails on Pull {
  _id
  ownerName
  deploymentName
  databaseName
  pullId
  state
  title
  description
  fromBranchName
  toBranchName
  creatorName
  createdAt
  afterMergeCommit
}
    `;
export const PullDetailCommentFragmentDoc = gql`
    fragment PullDetailComment on PullDetailComment {
  _id
  authorName
  comment
  createdAt
  updatedAt
}
    `;
export const PullDetailCommitFragmentDoc = gql`
    fragment PullDetailCommit on PullDetailCommit {
  _id
  username
  message
  createdAt
  commitId
  parentCommitId
}
    `;
export const PullDetailSummaryFragmentDoc = gql`
    fragment PullDetailSummary on PullDetailSummary {
  _id
  username
  createdAt
  numCommits
}
    `;
export const PullDetailLogFragmentDoc = gql`
    fragment PullDetailLog on PullDetailLog {
  _id
  username
  createdAt
  activity
  involvedUser
}
    `;
export const PullDetailsForPullDetailsFragmentDoc = gql`
    fragment PullDetailsForPullDetails on PullDetails {
  ... on PullDetailComment {
    ...PullDetailComment
  }
  ... on PullDetailCommit {
    ...PullDetailCommit
  }
  ... on PullDetailSummary {
    ...PullDetailSummary
  }
  ... on PullDetailLog {
    ...PullDetailLog
  }
}
    ${PullDetailCommentFragmentDoc}
${PullDetailCommitFragmentDoc}
${PullDetailSummaryFragmentDoc}
${PullDetailLogFragmentDoc}`;
export const PullDetailsFragmentDoc = gql`
    fragment PullDetails on PullWithDetails {
  _id
  details {
    ...PullDetailsForPullDetails
  }
}
    ${PullDetailsForPullDetailsFragmentDoc}`;
export const PullSummaryForPullDetailsFragmentDoc = gql`
    fragment PullSummaryForPullDetails on PullSummary {
  _id
}
    `;
export const PullForPullListFragmentDoc = gql`
    fragment PullForPullList on Pull {
  _id
  createdAt
  ownerName
  deploymentName
  databaseName
  pullId
  creatorName
  description
  state
  title
  commentCount
}
    `;
export const PullListForPullListFragmentDoc = gql`
    fragment PullListForPullList on PullList {
  list {
    ...PullForPullList
  }
  nextPageToken
}
    ${PullForPullListFragmentDoc}`;
export const MergeStateFragmentDoc = gql`
    fragment MergeState on MergeState {
  premergeFromCommit
  premergeToCommit
  mergeBaseCommit
}
    `;
export const PullForDiffFragmentDoc = gql`
    fragment PullForDiff on Pull {
  _id
  fromBranchName
  toBranchName
  state
  mergeState {
    ...MergeState
  }
}
    ${MergeStateFragmentDoc}`;
export const TagForListFragmentDoc = gql`
    fragment TagForList on Tag {
  _id
  tagName
  deploymentName
  ownerName
  message
  taggedAt
  tagger {
    ...DoltWriterForHistory
  }
  commitId
}
    ${DoltWriterForHistoryFragmentDoc}`;
export const TagListForTagListFragmentDoc = gql`
    fragment TagListForTagList on TagList {
  list {
    ...TagForList
  }
}
    ${TagForListFragmentDoc}`;
export const BackupFragmentDoc = gql`
    fragment Backup on Backup {
  _id
  ownerName
  deploymentName
  backupName
  size
  databases
  createdAt
  instanceIndex
}
    `;
export const BoolOverrideValidationFragmentDoc = gql`
    fragment BoolOverrideValidation on BoolOverrideValidationType {
  isBool
}
    `;
export const IntOverrideValidationFragmentDoc = gql`
    fragment IntOverrideValidation on IntOverrideValidationType {
  maxValue
  minValue
}
    `;
export const StringOverrideValidationFragmentDoc = gql`
    fragment StringOverrideValidation on StringOverrideValidationType {
  validationRegex
}
    `;
export const EnumOverrideValidationFragmentDoc = gql`
    fragment EnumOverrideValidation on EnumOverrideValidationType {
  validValuesList
}
    `;
export const UnspecifiedValidationFragmentDoc = gql`
    fragment UnspecifiedValidation on UnspecifiedValidationType {
  unspecified
}
    `;
export const SupportedOverrideItemFragmentDoc = gql`
    fragment SupportedOverrideItem on SupportedOverrideItem {
  key
  value
  validation {
    ... on BoolOverrideValidationType {
      ...BoolOverrideValidation
    }
    ... on IntOverrideValidationType {
      ...IntOverrideValidation
    }
    ... on StringOverrideValidationType {
      ...StringOverrideValidation
    }
    ... on EnumOverrideValidationType {
      ...EnumOverrideValidation
    }
    ... on UnspecifiedValidationType {
      ...UnspecifiedValidation
    }
  }
}
    ${BoolOverrideValidationFragmentDoc}
${IntOverrideValidationFragmentDoc}
${StringOverrideValidationFragmentDoc}
${EnumOverrideValidationFragmentDoc}
${UnspecifiedValidationFragmentDoc}`;
export const DepInstanceFragmentDoc = gql`
    fragment DepInstance on DeploymentInstance {
  _id
  ownerName
  deploymentName
  instanceID
  host
  index
  isPrimary
  prettyName
  volumeTypeName
  instanceTypeName
  volumeSizeGb
  hourlyCost {
    ...MoneyType
  }
  application
}
    ${MoneyTypeFragmentDoc}`;
export const LogLineFragmentDoc = gql`
    fragment LogLine on LogLine {
  time
  text
}
    `;
export const DashboardFragmentDoc = gql`
    fragment Dashboard on Dashboard {
  _id
  ownerName
  deploymentName
  dashboardName
  displayName
}
    `;
export const GraphFragmentDoc = gql`
    fragment Graph on Graph {
  _id
  ownerName
  deploymentName
  graphName
  displayName
}
    `;
export const GraphDataFragmentDoc = gql`
    fragment GraphData on GraphData {
  metricName
  useY0
  values
}
    `;
export const GraphDataResFragmentDoc = gql`
    fragment GraphDataRes on GraphDataRes {
  startTime
  endTime
  valueTimestamps
  xAxis
  y0Axis
  y1Axis
  graphs {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;
export const DepCredsFragmentDoc = gql`
    fragment DepCreds on DeploymentCreds {
  ownerName
  deploymentName
  keyID
  publicKey
}
    `;
export const ServiceWindowFragmentDoc = gql`
    fragment ServiceWindow on ServiceWindow {
  _id
  ownerName
  deploymentName
  serviceWindowID
  startHourOfDay
  endHourOfDay
  dayOfWeek
}
    `;
export const DeploymentCollaboratorFragmentDoc = gql`
    fragment DeploymentCollaborator on DeploymentCollaborator {
  _id
  ownerName
  deploymentName
  collaboratorName
  permissionLevel
  email
}
    `;
export const TeamForTeamPermsFragmentDoc = gql`
    fragment TeamForTeamPerms on Team {
  _id
  teamName
}
    `;
export const DepTeamPermFragmentDoc = gql`
    fragment DepTeamPerm on DepTeamPerm {
  _id
  ownerName
  depName
  teamName
  permLevel
  team {
    _id
    members {
      list {
        _id
      }
    }
  }
}
    `;
export const WorkbenchSettingsFragmentDoc = gql`
    fragment WorkbenchSettings on WorkbenchSettings {
  _id
  enableWrites
  deleteSourceBranchOnAcceptedMerge
}
    `;
export const PrivateLinkEndpointFragmentDoc = gql`
    fragment PrivateLinkEndpoint on PrivateLinkEndpoint {
  hostname
  state
  serviceName
}
    `;
export const PrivateLinkConfigFragmentDoc = gql`
    fragment PrivateLinkConfig on PrivateLinkConfig {
  usePrivateLink
  allowedPeeringAccountIds
  endpoints {
    ...PrivateLinkEndpoint
  }
}
    ${PrivateLinkEndpointFragmentDoc}`;
export const PrivateServiceConnectConfigFragmentDoc = gql`
    fragment PrivateServiceConnectConfig on PrivateServiceConnectConfig {
  usePrivateServiceConnect
  allowedProjectIds
  allowedVpcs {
    projectId
    networkName
  }
  targetService
  consumerServiceName
  targetReadService
  consumerReadServiceName
  baseUrl
}
    `;
export const DeploymentFragmentDoc = gql`
    fragment Deployment on Deployment {
  _id
  ownerName
  deploymentName
  doltVersion
  state
  cloud
  zone
  instanceTypeName
  volumeTypeName
  volumeSizeGb
  host
  port
  username
  password
  createdAt
  destroyAt
  role
  webPKICert
  hourlyCostUsd
  serverCertCa
  exposeRemotesapiEndpoint
  numReplicas
  createdBy
  destroyedBy
  disableAutomaticDoltUpdates
  clusterType
  privateLinkConfig {
    ...PrivateLinkConfig
  }
  pscConfig {
    ...PrivateServiceConnectConfig
  }
}
    ${PrivateLinkConfigFragmentDoc}
${PrivateServiceConnectConfigFragmentDoc}`;
export const OrgMemberFragmentDoc = gql`
    fragment OrgMember on OrgMember {
  _id
  orgName
  username
  role
  availableCallerActions
  emailAddress
  teams {
    list {
      _id
      teamName
    }
  }
}
    `;
export const OrgIdentityProviderFragmentDoc = gql`
    fragment OrgIdentityProviderFragment on OrgIdentityProvider {
  _id
  orgName
}
    `;
export const GetOrgIdentityProviderResFragmentDoc = gql`
    fragment GetOrgIdentityProviderRes on GetOrgIdentityProviderRes {
  provider {
    ...OrgIdentityProviderFragment
  }
  descriptor
  acsUrl
  audienceUri
  providerCertCa
}
    ${OrgIdentityProviderFragmentDoc}`;
export const TeamMemberForOrgFragmentDoc = gql`
    fragment TeamMemberForOrg on TeamMember {
  _id
  memberName
  role
}
    `;
export const TeamForProfileFragmentDoc = gql`
    fragment TeamForProfile on Team {
  _id
  teamName
  orgName
  description
  callerRole
  members {
    list {
      ...TeamMemberForOrg
    }
  }
}
    ${TeamMemberForOrgFragmentDoc}`;
export const TeamForOrgFragmentDoc = gql`
    fragment TeamForOrg on Team {
  _id
  teamName
  orgName
  description
  members {
    list {
      ...TeamMemberForOrg
    }
  }
}
    ${TeamMemberForOrgFragmentDoc}`;
export const TeamListForOrgFragmentDoc = gql`
    fragment TeamListForOrg on TeamList {
  list {
    ...TeamForOrg
  }
}
    ${TeamForOrgFragmentDoc}`;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  _id
  orgName
  displayName
  callerRole
  billingEmail
  hasSamlProvider
}
    `;
export const AuthUserFragmentDoc = gql`
    fragment AuthUser on User {
  _id
  username
  emailAddressesList {
    _id
    isVerified
  }
}
    `;
export const ColumnForDataTableFragmentDoc = gql`
    fragment ColumnForDataTable on Column {
  name
  isPrimaryKey
  type
  sourceTable
  constraints {
    notNull
  }
}
    `;
export const RowForDataTableFragmentDoc = gql`
    fragment RowForDataTable on Row {
  columnValues {
    displayValue
  }
}
    `;
export const RowListRowsFragmentDoc = gql`
    fragment RowListRows on RowList {
  nextOffset
  list {
    ...RowForDataTable
  }
}
    ${RowForDataTableFragmentDoc}`;
export const DiffSummaryFragmentDoc = gql`
    fragment DiffSummary on DiffSummary {
  _id
  fromTableName
  toTableName
  tableName
  tableType
  hasDataChanges
  hasSchemaChanges
}
    `;
export const BranchForCommitGraphFragmentDoc = gql`
    fragment BranchForCommitGraph on Branch {
  branchName
  head
}
    `;
export const EmailAddressFragmentDoc = gql`
    fragment EmailAddress on EmailAddress {
  _id
  address
  isPrimary
  isVerified
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  _id
  company
  username
  displayName
  provider
  emailAddressesList {
    ...EmailAddress
  }
}
    ${EmailAddressFragmentDoc}`;
export const SubscriptionForBannerFragmentDoc = gql`
    fragment SubscriptionForBanner on Subscription {
  _id
  ownerName
  ownerType
  type
}
    `;
export const OverrideItemFragmentDoc = gql`
    fragment OverrideItem on OverrideItem {
  key
  value
}
    `;
export const DepForDepRoleFragmentDoc = gql`
    fragment DepForDepRole on Deployment {
  _id
  role
  currentConfigItems {
    ...OverrideItem
  }
}
    ${OverrideItemFragmentDoc}`;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($ownerName: String!, $token: String!, $provider: PaymentProviderType!, $ownerType: OwnerType!) {
  createSubscription(
    ownerName: $ownerName
    token: $token
    provider: $provider
    ownerType: $ownerType
  ) {
    _id
    type
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscription__
 *
 * To run a mutation, you first call `useCreateSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscription, { data, loading, error }] = useCreateSubscription({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      token: // value for 'token'
 *      provider: // value for 'provider'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useCreateSubscription(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionHookResult = ReturnType<typeof useCreateSubscription>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const SubscriptionForBillingSettingsDocument = gql`
    query SubscriptionForBillingSettings($ownerName: String!, $ownerType: OwnerType!) {
  subscription(ownerName: $ownerName, ownerType: $ownerType) {
    ...SubscriptionForBilling
  }
}
    ${SubscriptionForBillingFragmentDoc}`;

/**
 * __useSubscriptionForBillingSettings__
 *
 * To run a query within a React component, call `useSubscriptionForBillingSettings` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionForBillingSettings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionForBillingSettings({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useSubscriptionForBillingSettings(baseOptions: Apollo.QueryHookOptions<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables> & ({ variables: SubscriptionForBillingSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>(SubscriptionForBillingSettingsDocument, options);
      }
export function useSubscriptionForBillingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>(SubscriptionForBillingSettingsDocument, options);
        }
export function useSubscriptionForBillingSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>(SubscriptionForBillingSettingsDocument, options);
        }
export type SubscriptionForBillingSettingsHookResult = ReturnType<typeof useSubscriptionForBillingSettings>;
export type SubscriptionForBillingSettingsLazyQueryHookResult = ReturnType<typeof useSubscriptionForBillingSettingsLazyQuery>;
export type SubscriptionForBillingSettingsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionForBillingSettingsSuspenseQuery>;
export type SubscriptionForBillingSettingsQueryResult = Apollo.QueryResult<SubscriptionForBillingSettingsQuery, SubscriptionForBillingSettingsQueryVariables>;
export const OrgForBillingDocument = gql`
    query OrgForBilling($orgName: String!) {
  org(orgName: $orgName) {
    ...OrganizationForBilling
  }
}
    ${OrganizationForBillingFragmentDoc}`;

/**
 * __useOrgForBillingQuery__
 *
 * To run a query within a React component, call `useOrgForBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgForBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgForBillingQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrgForBillingQuery(baseOptions: Apollo.QueryHookOptions<OrgForBillingQuery, OrgForBillingQueryVariables> & ({ variables: OrgForBillingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgForBillingQuery, OrgForBillingQueryVariables>(OrgForBillingDocument, options);
      }
export function useOrgForBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgForBillingQuery, OrgForBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgForBillingQuery, OrgForBillingQueryVariables>(OrgForBillingDocument, options);
        }
export function useOrgForBillingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgForBillingQuery, OrgForBillingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgForBillingQuery, OrgForBillingQueryVariables>(OrgForBillingDocument, options);
        }
export type OrgForBillingQueryHookResult = ReturnType<typeof useOrgForBillingQuery>;
export type OrgForBillingLazyQueryHookResult = ReturnType<typeof useOrgForBillingLazyQuery>;
export type OrgForBillingSuspenseQueryHookResult = ReturnType<typeof useOrgForBillingSuspenseQuery>;
export type OrgForBillingQueryResult = Apollo.QueryResult<OrgForBillingQuery, OrgForBillingQueryVariables>;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($ownerName: String!, $ownerType: OwnerType!) {
  deleteSubscription(ownerName: $ownerName, ownerType: $ownerType)
}
    `;
export type DeleteSubscriptionMutationFn = Apollo.MutationFunction<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;

/**
 * __useDeleteSubscription__
 *
 * To run a mutation, you first call `useDeleteSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscription, { data, loading, error }] = useDeleteSubscription({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useDeleteSubscription(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument, options);
      }
export type DeleteSubscriptionHookResult = ReturnType<typeof useDeleteSubscription>;
export type DeleteSubscriptionMutationResult = Apollo.MutationResult<DeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;
export const CreateBillingPortalSessionDocument = gql`
    mutation CreateBillingPortalSession($ownerName: String!, $ownerType: OwnerType!) {
  createBillingPortalSession(ownerName: $ownerName, ownerType: $ownerType)
}
    `;
export type CreateBillingPortalSessionMutationFn = Apollo.MutationFunction<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;

/**
 * __useCreateBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingPortalSessionMutation, { data, loading, error }] = useCreateBillingPortalSessionMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useCreateBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>(CreateBillingPortalSessionDocument, options);
      }
export type CreateBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationResult = Apollo.MutationResult<CreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;
export const UsersFor404Document = gql`
    query UsersFor404 {
  currentUser {
    _id
  }
}
    `;

/**
 * __useUsersFor404Query__
 *
 * To run a query within a React component, call `useUsersFor404Query` and pass it any options that fit your needs.
 * When your component renders, `useUsersFor404Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersFor404Query({
 *   variables: {
 *   },
 * });
 */
export function useUsersFor404Query(baseOptions?: Apollo.QueryHookOptions<UsersFor404Query, UsersFor404QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersFor404Query, UsersFor404QueryVariables>(UsersFor404Document, options);
      }
export function useUsersFor404LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersFor404Query, UsersFor404QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersFor404Query, UsersFor404QueryVariables>(UsersFor404Document, options);
        }
export function useUsersFor404SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersFor404Query, UsersFor404QueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersFor404Query, UsersFor404QueryVariables>(UsersFor404Document, options);
        }
export type UsersFor404QueryHookResult = ReturnType<typeof useUsersFor404Query>;
export type UsersFor404LazyQueryHookResult = ReturnType<typeof useUsersFor404LazyQuery>;
export type UsersFor404SuspenseQueryHookResult = ReturnType<typeof useUsersFor404SuspenseQuery>;
export type UsersFor404QueryResult = Apollo.QueryResult<UsersFor404Query, UsersFor404QueryVariables>;
export const GetTagDocument = gql`
    query GetTag($ownerName: String!, $deploymentName: String!, $databaseName: String!, $tagName: String!) {
  tag(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    tagName: $tagName
  ) {
    ...TagForList
  }
}
    ${TagForListFragmentDoc}`;

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions: Apollo.QueryHookOptions<GetTagQuery, GetTagQueryVariables> & ({ variables: GetTagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
      }
export function useGetTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
        }
export function useGetTagSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
        }
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>;
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>;
export type GetTagSuspenseQueryHookResult = ReturnType<typeof useGetTagSuspenseQuery>;
export type GetTagQueryResult = Apollo.QueryResult<GetTagQuery, GetTagQueryVariables>;
export const SqlSelectForCsvDownloadDocument = gql`
    query SqlSelectForCsvDownload($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $queryString: String!) {
  sqlSelectForCsvDownload(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    queryString: $queryString
  )
}
    `;

/**
 * __useSqlSelectForCsvDownloadQuery__
 *
 * To run a query within a React component, call `useSqlSelectForCsvDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqlSelectForCsvDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqlSelectForCsvDownloadQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useSqlSelectForCsvDownloadQuery(baseOptions: Apollo.QueryHookOptions<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables> & ({ variables: SqlSelectForCsvDownloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>(SqlSelectForCsvDownloadDocument, options);
      }
export function useSqlSelectForCsvDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>(SqlSelectForCsvDownloadDocument, options);
        }
export function useSqlSelectForCsvDownloadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>(SqlSelectForCsvDownloadDocument, options);
        }
export type SqlSelectForCsvDownloadQueryHookResult = ReturnType<typeof useSqlSelectForCsvDownloadQuery>;
export type SqlSelectForCsvDownloadLazyQueryHookResult = ReturnType<typeof useSqlSelectForCsvDownloadLazyQuery>;
export type SqlSelectForCsvDownloadSuspenseQueryHookResult = ReturnType<typeof useSqlSelectForCsvDownloadSuspenseQuery>;
export type SqlSelectForCsvDownloadQueryResult = Apollo.QueryResult<SqlSelectForCsvDownloadQuery, SqlSelectForCsvDownloadQueryVariables>;
export const ListDeploymentsDocument = gql`
    query ListDeployments($ownerName: String!, $pageToken: String, $stateFilter: DeployState) {
  deployments(
    ownerName: $ownerName
    pageToken: $pageToken
    stateFilter: $stateFilter
  ) {
    nextPageToken
    list {
      ...DeployListItem
    }
  }
}
    ${DeployListItemFragmentDoc}`;

/**
 * __useListDeploymentsQuery__
 *
 * To run a query within a React component, call `useListDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeploymentsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      pageToken: // value for 'pageToken'
 *      stateFilter: // value for 'stateFilter'
 *   },
 * });
 */
export function useListDeploymentsQuery(baseOptions: Apollo.QueryHookOptions<ListDeploymentsQuery, ListDeploymentsQueryVariables> & ({ variables: ListDeploymentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDeploymentsQuery, ListDeploymentsQueryVariables>(ListDeploymentsDocument, options);
      }
export function useListDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDeploymentsQuery, ListDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDeploymentsQuery, ListDeploymentsQueryVariables>(ListDeploymentsDocument, options);
        }
export function useListDeploymentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDeploymentsQuery, ListDeploymentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDeploymentsQuery, ListDeploymentsQueryVariables>(ListDeploymentsDocument, options);
        }
export type ListDeploymentsQueryHookResult = ReturnType<typeof useListDeploymentsQuery>;
export type ListDeploymentsLazyQueryHookResult = ReturnType<typeof useListDeploymentsLazyQuery>;
export type ListDeploymentsSuspenseQueryHookResult = ReturnType<typeof useListDeploymentsSuspenseQuery>;
export type ListDeploymentsQueryResult = Apollo.QueryResult<ListDeploymentsQuery, ListDeploymentsQueryVariables>;
export const ListUserDeploymentsDocument = gql`
    query ListUserDeployments($ownerFilter: String, $pageToken: String, $stateFilter: DeployState) {
  currentUserDeployments(
    ownerFilter: $ownerFilter
    pageToken: $pageToken
    stateFilter: $stateFilter
  ) {
    nextPageToken
    list {
      ...DeployListItem
    }
  }
}
    ${DeployListItemFragmentDoc}`;

/**
 * __useListUserDeploymentsQuery__
 *
 * To run a query within a React component, call `useListUserDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDeploymentsQuery({
 *   variables: {
 *      ownerFilter: // value for 'ownerFilter'
 *      pageToken: // value for 'pageToken'
 *      stateFilter: // value for 'stateFilter'
 *   },
 * });
 */
export function useListUserDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>(ListUserDeploymentsDocument, options);
      }
export function useListUserDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>(ListUserDeploymentsDocument, options);
        }
export function useListUserDeploymentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>(ListUserDeploymentsDocument, options);
        }
export type ListUserDeploymentsQueryHookResult = ReturnType<typeof useListUserDeploymentsQuery>;
export type ListUserDeploymentsLazyQueryHookResult = ReturnType<typeof useListUserDeploymentsLazyQuery>;
export type ListUserDeploymentsSuspenseQueryHookResult = ReturnType<typeof useListUserDeploymentsSuspenseQuery>;
export type ListUserDeploymentsQueryResult = Apollo.QueryResult<ListUserDeploymentsQuery, ListUserDeploymentsQueryVariables>;
export const CommitsForDiffSelectorDocument = gql`
    query CommitsForDiffSelector($refName: String!, $deploymentName: String!, $databaseName: String!, $ownerName: String!) {
  commits(
    refName: $refName
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
  ) {
    ...CommitListForDiffSelector
  }
}
    ${CommitListForDiffSelectorFragmentDoc}`;

/**
 * __useCommitsForDiffSelectorQuery__
 *
 * To run a query within a React component, call `useCommitsForDiffSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommitsForDiffSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommitsForDiffSelectorQuery({
 *   variables: {
 *      refName: // value for 'refName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      ownerName: // value for 'ownerName'
 *   },
 * });
 */
export function useCommitsForDiffSelectorQuery(baseOptions: Apollo.QueryHookOptions<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables> & ({ variables: CommitsForDiffSelectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>(CommitsForDiffSelectorDocument, options);
      }
export function useCommitsForDiffSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>(CommitsForDiffSelectorDocument, options);
        }
export function useCommitsForDiffSelectorSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>(CommitsForDiffSelectorDocument, options);
        }
export type CommitsForDiffSelectorQueryHookResult = ReturnType<typeof useCommitsForDiffSelectorQuery>;
export type CommitsForDiffSelectorLazyQueryHookResult = ReturnType<typeof useCommitsForDiffSelectorLazyQuery>;
export type CommitsForDiffSelectorSuspenseQueryHookResult = ReturnType<typeof useCommitsForDiffSelectorSuspenseQuery>;
export type CommitsForDiffSelectorQueryResult = Apollo.QueryResult<CommitsForDiffSelectorQuery, CommitsForDiffSelectorQueryVariables>;
export const PullCommitsForDiffSelectorDocument = gql`
    query PullCommitsForDiffSelector($deploymentName: String!, $databaseName: String!, $ownerName: String!, $pullId: String!) {
  pullWithDetails(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    _id
    summary {
      ...PullSummaryForDiffSelector
    }
  }
}
    ${PullSummaryForDiffSelectorFragmentDoc}`;

/**
 * __usePullCommitsForDiffSelectorQuery__
 *
 * To run a query within a React component, call `usePullCommitsForDiffSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullCommitsForDiffSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullCommitsForDiffSelectorQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      ownerName: // value for 'ownerName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function usePullCommitsForDiffSelectorQuery(baseOptions: Apollo.QueryHookOptions<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables> & ({ variables: PullCommitsForDiffSelectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>(PullCommitsForDiffSelectorDocument, options);
      }
export function usePullCommitsForDiffSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>(PullCommitsForDiffSelectorDocument, options);
        }
export function usePullCommitsForDiffSelectorSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>(PullCommitsForDiffSelectorDocument, options);
        }
export type PullCommitsForDiffSelectorQueryHookResult = ReturnType<typeof usePullCommitsForDiffSelectorQuery>;
export type PullCommitsForDiffSelectorLazyQueryHookResult = ReturnType<typeof usePullCommitsForDiffSelectorLazyQuery>;
export type PullCommitsForDiffSelectorSuspenseQueryHookResult = ReturnType<typeof usePullCommitsForDiffSelectorSuspenseQuery>;
export type PullCommitsForDiffSelectorQueryResult = Apollo.QueryResult<PullCommitsForDiffSelectorQuery, PullCommitsForDiffSelectorQueryVariables>;
export const DiffStatDocument = gql`
    query DiffStat($ownerName: String!, $deploymentName: String!, $databaseName: String!, $fromRefName: String!, $toRefName: String!, $refName: String, $type: CommitDiffType, $tableName: String) {
  diffStat(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    fromRefName: $fromRefName
    toRefName: $toRefName
    refName: $refName
    type: $type
    tableName: $tableName
  ) {
    ...DiffStatForDiffs
  }
}
    ${DiffStatForDiffsFragmentDoc}`;

/**
 * __useDiffStatQuery__
 *
 * To run a query within a React component, call `useDiffStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiffStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiffStatQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      fromRefName: // value for 'fromRefName'
 *      toRefName: // value for 'toRefName'
 *      refName: // value for 'refName'
 *      type: // value for 'type'
 *      tableName: // value for 'tableName'
 *   },
 * });
 */
export function useDiffStatQuery(baseOptions: Apollo.QueryHookOptions<DiffStatQuery, DiffStatQueryVariables> & ({ variables: DiffStatQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiffStatQuery, DiffStatQueryVariables>(DiffStatDocument, options);
      }
export function useDiffStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiffStatQuery, DiffStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiffStatQuery, DiffStatQueryVariables>(DiffStatDocument, options);
        }
export function useDiffStatSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiffStatQuery, DiffStatQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiffStatQuery, DiffStatQueryVariables>(DiffStatDocument, options);
        }
export type DiffStatQueryHookResult = ReturnType<typeof useDiffStatQuery>;
export type DiffStatLazyQueryHookResult = ReturnType<typeof useDiffStatLazyQuery>;
export type DiffStatSuspenseQueryHookResult = ReturnType<typeof useDiffStatSuspenseQuery>;
export type DiffStatQueryResult = Apollo.QueryResult<DiffStatQuery, DiffStatQueryVariables>;
export const RowDiffsDocument = gql`
    query RowDiffs($ownerName: String!, $deploymentName: String!, $databaseName: String!, $tableName: String!, $fromCommitId: String!, $toCommitId: String!, $refName: String, $offset: Int, $filterByRowType: DiffRowType) {
  rowDiffs(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    tableName: $tableName
    fromCommitId: $fromCommitId
    toCommitId: $toCommitId
    refName: $refName
    offset: $offset
    filterByRowType: $filterByRowType
  ) {
    ...RowDiffListWithCols
  }
}
    ${RowDiffListWithColsFragmentDoc}`;

/**
 * __useRowDiffsQuery__
 *
 * To run a query within a React component, call `useRowDiffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowDiffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowDiffsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      tableName: // value for 'tableName'
 *      fromCommitId: // value for 'fromCommitId'
 *      toCommitId: // value for 'toCommitId'
 *      refName: // value for 'refName'
 *      offset: // value for 'offset'
 *      filterByRowType: // value for 'filterByRowType'
 *   },
 * });
 */
export function useRowDiffsQuery(baseOptions: Apollo.QueryHookOptions<RowDiffsQuery, RowDiffsQueryVariables> & ({ variables: RowDiffsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RowDiffsQuery, RowDiffsQueryVariables>(RowDiffsDocument, options);
      }
export function useRowDiffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowDiffsQuery, RowDiffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RowDiffsQuery, RowDiffsQueryVariables>(RowDiffsDocument, options);
        }
export function useRowDiffsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RowDiffsQuery, RowDiffsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RowDiffsQuery, RowDiffsQueryVariables>(RowDiffsDocument, options);
        }
export type RowDiffsQueryHookResult = ReturnType<typeof useRowDiffsQuery>;
export type RowDiffsLazyQueryHookResult = ReturnType<typeof useRowDiffsLazyQuery>;
export type RowDiffsSuspenseQueryHookResult = ReturnType<typeof useRowDiffsSuspenseQuery>;
export type RowDiffsQueryResult = Apollo.QueryResult<RowDiffsQuery, RowDiffsQueryVariables>;
export const SchemaDiffDocument = gql`
    query SchemaDiff($ownerName: String!, $deploymentName: String!, $databaseName: String!, $tableName: String!, $fromCommitId: String!, $toCommitId: String!, $refName: String) {
  schemaDiff(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    tableName: $tableName
    fromCommitId: $fromCommitId
    toCommitId: $toCommitId
    refName: $refName
  ) {
    ...SchemaDiff
  }
}
    ${SchemaDiffFragmentDoc}`;

/**
 * __useSchemaDiffQuery__
 *
 * To run a query within a React component, call `useSchemaDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemaDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemaDiffQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      tableName: // value for 'tableName'
 *      fromCommitId: // value for 'fromCommitId'
 *      toCommitId: // value for 'toCommitId'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useSchemaDiffQuery(baseOptions: Apollo.QueryHookOptions<SchemaDiffQuery, SchemaDiffQueryVariables> & ({ variables: SchemaDiffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemaDiffQuery, SchemaDiffQueryVariables>(SchemaDiffDocument, options);
      }
export function useSchemaDiffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemaDiffQuery, SchemaDiffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemaDiffQuery, SchemaDiffQueryVariables>(SchemaDiffDocument, options);
        }
export function useSchemaDiffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SchemaDiffQuery, SchemaDiffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SchemaDiffQuery, SchemaDiffQueryVariables>(SchemaDiffDocument, options);
        }
export type SchemaDiffQueryHookResult = ReturnType<typeof useSchemaDiffQuery>;
export type SchemaDiffLazyQueryHookResult = ReturnType<typeof useSchemaDiffLazyQuery>;
export type SchemaDiffSuspenseQueryHookResult = ReturnType<typeof useSchemaDiffSuspenseQuery>;
export type SchemaDiffQueryResult = Apollo.QueryResult<SchemaDiffQuery, SchemaDiffQueryVariables>;
export const BranchesForSelectorDocument = gql`
    query BranchesForSelector($ownerName: String!, $deploymentName: String!, $databaseName: String!) {
  allBranches(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    ...BranchForBranchSelector
  }
}
    ${BranchForBranchSelectorFragmentDoc}`;

/**
 * __useBranchesForSelectorQuery__
 *
 * To run a query within a React component, call `useBranchesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesForSelectorQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useBranchesForSelectorQuery(baseOptions: Apollo.QueryHookOptions<BranchesForSelectorQuery, BranchesForSelectorQueryVariables> & ({ variables: BranchesForSelectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>(BranchesForSelectorDocument, options);
      }
export function useBranchesForSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>(BranchesForSelectorDocument, options);
        }
export function useBranchesForSelectorSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>(BranchesForSelectorDocument, options);
        }
export type BranchesForSelectorQueryHookResult = ReturnType<typeof useBranchesForSelectorQuery>;
export type BranchesForSelectorLazyQueryHookResult = ReturnType<typeof useBranchesForSelectorLazyQuery>;
export type BranchesForSelectorSuspenseQueryHookResult = ReturnType<typeof useBranchesForSelectorSuspenseQuery>;
export type BranchesForSelectorQueryResult = Apollo.QueryResult<BranchesForSelectorQuery, BranchesForSelectorQueryVariables>;
export const TableNamesForBranchDocument = gql`
    query TableNamesForBranch($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  tableNames(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    filterSystemTables: true
  ) {
    list
  }
}
    `;

/**
 * __useTableNamesForBranchQuery__
 *
 * To run a query within a React component, call `useTableNamesForBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableNamesForBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableNamesForBranchQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useTableNamesForBranchQuery(baseOptions: Apollo.QueryHookOptions<TableNamesForBranchQuery, TableNamesForBranchQueryVariables> & ({ variables: TableNamesForBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>(TableNamesForBranchDocument, options);
      }
export function useTableNamesForBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>(TableNamesForBranchDocument, options);
        }
export function useTableNamesForBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>(TableNamesForBranchDocument, options);
        }
export type TableNamesForBranchQueryHookResult = ReturnType<typeof useTableNamesForBranchQuery>;
export type TableNamesForBranchLazyQueryHookResult = ReturnType<typeof useTableNamesForBranchLazyQuery>;
export type TableNamesForBranchSuspenseQueryHookResult = ReturnType<typeof useTableNamesForBranchSuspenseQuery>;
export type TableNamesForBranchQueryResult = Apollo.QueryResult<TableNamesForBranchQuery, TableNamesForBranchQueryVariables>;
export const TableListForSchemasDocument = gql`
    query TableListForSchemas($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $filterSystemTables: Boolean) {
  tables(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    filterSystemTables: $filterSystemTables
  ) {
    ...TableForSchemaList
  }
}
    ${TableForSchemaListFragmentDoc}`;

/**
 * __useTableListForSchemasQuery__
 *
 * To run a query within a React component, call `useTableListForSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableListForSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableListForSchemasQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      filterSystemTables: // value for 'filterSystemTables'
 *   },
 * });
 */
export function useTableListForSchemasQuery(baseOptions: Apollo.QueryHookOptions<TableListForSchemasQuery, TableListForSchemasQueryVariables> & ({ variables: TableListForSchemasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableListForSchemasQuery, TableListForSchemasQueryVariables>(TableListForSchemasDocument, options);
      }
export function useTableListForSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableListForSchemasQuery, TableListForSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableListForSchemasQuery, TableListForSchemasQueryVariables>(TableListForSchemasDocument, options);
        }
export function useTableListForSchemasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableListForSchemasQuery, TableListForSchemasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableListForSchemasQuery, TableListForSchemasQueryVariables>(TableListForSchemasDocument, options);
        }
export type TableListForSchemasQueryHookResult = ReturnType<typeof useTableListForSchemasQuery>;
export type TableListForSchemasLazyQueryHookResult = ReturnType<typeof useTableListForSchemasLazyQuery>;
export type TableListForSchemasSuspenseQueryHookResult = ReturnType<typeof useTableListForSchemasSuspenseQuery>;
export type TableListForSchemasQueryResult = Apollo.QueryResult<TableListForSchemasQuery, TableListForSchemasQueryVariables>;
export const RowsForDoltSchemasDocument = gql`
    query RowsForDoltSchemas($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  doltSchemas(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  ) {
    list {
      ...RowForSchemas
    }
  }
}
    ${RowForSchemasFragmentDoc}`;

/**
 * __useRowsForDoltSchemasQuery__
 *
 * To run a query within a React component, call `useRowsForDoltSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowsForDoltSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowsForDoltSchemasQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useRowsForDoltSchemasQuery(baseOptions: Apollo.QueryHookOptions<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables> & ({ variables: RowsForDoltSchemasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>(RowsForDoltSchemasDocument, options);
      }
export function useRowsForDoltSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>(RowsForDoltSchemasDocument, options);
        }
export function useRowsForDoltSchemasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>(RowsForDoltSchemasDocument, options);
        }
export type RowsForDoltSchemasQueryHookResult = ReturnType<typeof useRowsForDoltSchemasQuery>;
export type RowsForDoltSchemasLazyQueryHookResult = ReturnType<typeof useRowsForDoltSchemasLazyQuery>;
export type RowsForDoltSchemasSuspenseQueryHookResult = ReturnType<typeof useRowsForDoltSchemasSuspenseQuery>;
export type RowsForDoltSchemasQueryResult = Apollo.QueryResult<RowsForDoltSchemasQuery, RowsForDoltSchemasQueryVariables>;
export const RowsForDoltProceduresDocument = gql`
    query RowsForDoltProcedures($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  doltProcedures(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  ) {
    list {
      ...RowForSchemas
    }
  }
}
    ${RowForSchemasFragmentDoc}`;

/**
 * __useRowsForDoltProceduresQuery__
 *
 * To run a query within a React component, call `useRowsForDoltProceduresQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowsForDoltProceduresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowsForDoltProceduresQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useRowsForDoltProceduresQuery(baseOptions: Apollo.QueryHookOptions<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables> & ({ variables: RowsForDoltProceduresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>(RowsForDoltProceduresDocument, options);
      }
export function useRowsForDoltProceduresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>(RowsForDoltProceduresDocument, options);
        }
export function useRowsForDoltProceduresSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>(RowsForDoltProceduresDocument, options);
        }
export type RowsForDoltProceduresQueryHookResult = ReturnType<typeof useRowsForDoltProceduresQuery>;
export type RowsForDoltProceduresLazyQueryHookResult = ReturnType<typeof useRowsForDoltProceduresLazyQuery>;
export type RowsForDoltProceduresSuspenseQueryHookResult = ReturnType<typeof useRowsForDoltProceduresSuspenseQuery>;
export type RowsForDoltProceduresQueryResult = Apollo.QueryResult<RowsForDoltProceduresQuery, RowsForDoltProceduresQueryVariables>;
export const SignoutDocument = gql`
    mutation Signout {
  signout
}
    `;
export type SignoutMutationFn = Apollo.MutationFunction<SignoutMutation, SignoutMutationVariables>;

/**
 * __useSignoutMutation__
 *
 * To run a mutation, you first call `useSignoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signoutMutation, { data, loading, error }] = useSignoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignoutMutation(baseOptions?: Apollo.MutationHookOptions<SignoutMutation, SignoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignoutMutation, SignoutMutationVariables>(SignoutDocument, options);
      }
export type SignoutMutationHookResult = ReturnType<typeof useSignoutMutation>;
export type SignoutMutationResult = Apollo.MutationResult<SignoutMutation>;
export type SignoutMutationOptions = Apollo.BaseMutationOptions<SignoutMutation, SignoutMutationVariables>;
export const SqlSelectForSqlDataTableDocument = gql`
    query SqlSelectForSqlDataTable($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $queryString: String!) {
  sqlSelect(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    queryString: $queryString
  ) {
    queryExecutionStatus
    queryExecutionMessage
    columns {
      ...ColumnForSqlDataTable
    }
    rows {
      ...RowForSqlDataTable
    }
  }
}
    ${ColumnForSqlDataTableFragmentDoc}
${RowForSqlDataTableFragmentDoc}`;

/**
 * __useSqlSelectForSqlDataTableQuery__
 *
 * To run a query within a React component, call `useSqlSelectForSqlDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqlSelectForSqlDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqlSelectForSqlDataTableQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useSqlSelectForSqlDataTableQuery(baseOptions: Apollo.QueryHookOptions<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables> & ({ variables: SqlSelectForSqlDataTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>(SqlSelectForSqlDataTableDocument, options);
      }
export function useSqlSelectForSqlDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>(SqlSelectForSqlDataTableDocument, options);
        }
export function useSqlSelectForSqlDataTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>(SqlSelectForSqlDataTableDocument, options);
        }
export type SqlSelectForSqlDataTableQueryHookResult = ReturnType<typeof useSqlSelectForSqlDataTableQuery>;
export type SqlSelectForSqlDataTableLazyQueryHookResult = ReturnType<typeof useSqlSelectForSqlDataTableLazyQuery>;
export type SqlSelectForSqlDataTableSuspenseQueryHookResult = ReturnType<typeof useSqlSelectForSqlDataTableSuspenseQuery>;
export type SqlSelectForSqlDataTableQueryResult = Apollo.QueryResult<SqlSelectForSqlDataTableQuery, SqlSelectForSqlDataTableQueryVariables>;
export const GetStatusDocument = gql`
    query GetStatus($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  status(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  ) {
    ...Status
  }
}
    ${StatusFragmentDoc}`;

/**
 * __useGetStatusQuery__
 *
 * To run a query within a React component, call `useGetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useGetStatusQuery(baseOptions: Apollo.QueryHookOptions<GetStatusQuery, GetStatusQueryVariables> & ({ variables: GetStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
      }
export function useGetStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
        }
export function useGetStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
        }
export type GetStatusQueryHookResult = ReturnType<typeof useGetStatusQuery>;
export type GetStatusLazyQueryHookResult = ReturnType<typeof useGetStatusLazyQuery>;
export type GetStatusSuspenseQueryHookResult = ReturnType<typeof useGetStatusSuspenseQuery>;
export type GetStatusQueryResult = Apollo.QueryResult<GetStatusQuery, GetStatusQueryVariables>;
export const RestoreAllDocument = gql`
    mutation RestoreAll($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  restoreAllTables(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  )
}
    `;
export type RestoreAllMutationFn = Apollo.MutationFunction<RestoreAllMutation, RestoreAllMutationVariables>;

/**
 * __useRestoreAllMutation__
 *
 * To run a mutation, you first call `useRestoreAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreAllMutation, { data, loading, error }] = useRestoreAllMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useRestoreAllMutation(baseOptions?: Apollo.MutationHookOptions<RestoreAllMutation, RestoreAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreAllMutation, RestoreAllMutationVariables>(RestoreAllDocument, options);
      }
export type RestoreAllMutationHookResult = ReturnType<typeof useRestoreAllMutation>;
export type RestoreAllMutationResult = Apollo.MutationResult<RestoreAllMutation>;
export type RestoreAllMutationOptions = Apollo.BaseMutationOptions<RestoreAllMutation, RestoreAllMutationVariables>;
export const CreateIncidentDocument = gql`
    mutation CreateIncident($username: String!, $deployments: [DeployInput!]!, $title: String!, $summary: String!, $impact: IncidentImpact!) {
  createIncident(
    username: $username
    deployments: $deployments
    title: $title
    summary: $summary
    impact: $impact
  ) {
    ...Incident
  }
}
    ${IncidentFragmentDoc}`;
export type CreateIncidentMutationFn = Apollo.MutationFunction<CreateIncidentMutation, CreateIncidentMutationVariables>;

/**
 * __useCreateIncidentMutation__
 *
 * To run a mutation, you first call `useCreateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentMutation, { data, loading, error }] = useCreateIncidentMutation({
 *   variables: {
 *      username: // value for 'username'
 *      deployments: // value for 'deployments'
 *      title: // value for 'title'
 *      summary: // value for 'summary'
 *      impact: // value for 'impact'
 *   },
 * });
 */
export function useCreateIncidentMutation(baseOptions?: Apollo.MutationHookOptions<CreateIncidentMutation, CreateIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIncidentMutation, CreateIncidentMutationVariables>(CreateIncidentDocument, options);
      }
export type CreateIncidentMutationHookResult = ReturnType<typeof useCreateIncidentMutation>;
export type CreateIncidentMutationResult = Apollo.MutationResult<CreateIncidentMutation>;
export type CreateIncidentMutationOptions = Apollo.BaseMutationOptions<CreateIncidentMutation, CreateIncidentMutationVariables>;
export const UpdateIncidentDocument = gql`
    mutation UpdateIncident($incidentName: String!, $deployments: [DeployInput!]!, $title: String!, $summary: String!, $impact: IncidentImpact!, $status: IncidentStatus!) {
  updateIncident(
    incidentName: $incidentName
    deployments: $deployments
    title: $title
    summary: $summary
    impact: $impact
    status: $status
  ) {
    ...Incident
  }
}
    ${IncidentFragmentDoc}`;
export type UpdateIncidentMutationFn = Apollo.MutationFunction<UpdateIncidentMutation, UpdateIncidentMutationVariables>;

/**
 * __useUpdateIncidentMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentMutation, { data, loading, error }] = useUpdateIncidentMutation({
 *   variables: {
 *      incidentName: // value for 'incidentName'
 *      deployments: // value for 'deployments'
 *      title: // value for 'title'
 *      summary: // value for 'summary'
 *      impact: // value for 'impact'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateIncidentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncidentMutation, UpdateIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncidentMutation, UpdateIncidentMutationVariables>(UpdateIncidentDocument, options);
      }
export type UpdateIncidentMutationHookResult = ReturnType<typeof useUpdateIncidentMutation>;
export type UpdateIncidentMutationResult = Apollo.MutationResult<UpdateIncidentMutation>;
export type UpdateIncidentMutationOptions = Apollo.BaseMutationOptions<UpdateIncidentMutation, UpdateIncidentMutationVariables>;
export const ListUserDeploymentsForSupportDocument = gql`
    query ListUserDeploymentsForSupport($query: String) {
  currentUserDeployments(query: $query) {
    nextPageToken
    list {
      ...DeployListItemForSupport
    }
  }
}
    ${DeployListItemForSupportFragmentDoc}`;

/**
 * __useListUserDeploymentsForSupportQuery__
 *
 * To run a query within a React component, call `useListUserDeploymentsForSupportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDeploymentsForSupportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDeploymentsForSupportQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListUserDeploymentsForSupportQuery(baseOptions?: Apollo.QueryHookOptions<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>(ListUserDeploymentsForSupportDocument, options);
      }
export function useListUserDeploymentsForSupportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>(ListUserDeploymentsForSupportDocument, options);
        }
export function useListUserDeploymentsForSupportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>(ListUserDeploymentsForSupportDocument, options);
        }
export type ListUserDeploymentsForSupportQueryHookResult = ReturnType<typeof useListUserDeploymentsForSupportQuery>;
export type ListUserDeploymentsForSupportLazyQueryHookResult = ReturnType<typeof useListUserDeploymentsForSupportLazyQuery>;
export type ListUserDeploymentsForSupportSuspenseQueryHookResult = ReturnType<typeof useListUserDeploymentsForSupportSuspenseQuery>;
export type ListUserDeploymentsForSupportQueryResult = Apollo.QueryResult<ListUserDeploymentsForSupportQuery, ListUserDeploymentsForSupportQueryVariables>;
export const ListIncidentsForCurrentUserDocument = gql`
    query ListIncidentsForCurrentUser($filterByImpact: IncidentImpact, $filterByStatus: IncidentStatus, $pageToken: String) {
  currentUserIncidents(
    filterByImpact: $filterByImpact
    filterByStatus: $filterByStatus
    pageToken: $pageToken
  ) {
    nextPageToken
    list {
      ...IncidentSummary
    }
  }
}
    ${IncidentSummaryFragmentDoc}`;

/**
 * __useListIncidentsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useListIncidentsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentsForCurrentUserQuery({
 *   variables: {
 *      filterByImpact: // value for 'filterByImpact'
 *      filterByStatus: // value for 'filterByStatus'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useListIncidentsForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>(ListIncidentsForCurrentUserDocument, options);
      }
export function useListIncidentsForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>(ListIncidentsForCurrentUserDocument, options);
        }
export function useListIncidentsForCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>(ListIncidentsForCurrentUserDocument, options);
        }
export type ListIncidentsForCurrentUserQueryHookResult = ReturnType<typeof useListIncidentsForCurrentUserQuery>;
export type ListIncidentsForCurrentUserLazyQueryHookResult = ReturnType<typeof useListIncidentsForCurrentUserLazyQuery>;
export type ListIncidentsForCurrentUserSuspenseQueryHookResult = ReturnType<typeof useListIncidentsForCurrentUserSuspenseQuery>;
export type ListIncidentsForCurrentUserQueryResult = Apollo.QueryResult<ListIncidentsForCurrentUserQuery, ListIncidentsForCurrentUserQueryVariables>;
export const TableForBranchDocument = gql`
    query TableForBranch($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $tableName: String!) {
  table(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    tableName: $tableName
  ) {
    ...TableWithColumns
  }
}
    ${TableWithColumnsFragmentDoc}`;

/**
 * __useTableForBranchQuery__
 *
 * To run a query within a React component, call `useTableForBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableForBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableForBranchQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      tableName: // value for 'tableName'
 *   },
 * });
 */
export function useTableForBranchQuery(baseOptions: Apollo.QueryHookOptions<TableForBranchQuery, TableForBranchQueryVariables> & ({ variables: TableForBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableForBranchQuery, TableForBranchQueryVariables>(TableForBranchDocument, options);
      }
export function useTableForBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableForBranchQuery, TableForBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableForBranchQuery, TableForBranchQueryVariables>(TableForBranchDocument, options);
        }
export function useTableForBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableForBranchQuery, TableForBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableForBranchQuery, TableForBranchQueryVariables>(TableForBranchDocument, options);
        }
export type TableForBranchQueryHookResult = ReturnType<typeof useTableForBranchQuery>;
export type TableForBranchLazyQueryHookResult = ReturnType<typeof useTableForBranchLazyQuery>;
export type TableForBranchSuspenseQueryHookResult = ReturnType<typeof useTableForBranchSuspenseQuery>;
export type TableForBranchQueryResult = Apollo.QueryResult<TableForBranchQuery, TableForBranchQueryVariables>;
export const OrgMemberListDocument = gql`
    query OrgMemberList($orgName: String!) {
  orgMembers(orgName: $orgName) {
    _id
  }
}
    `;

/**
 * __useOrgMemberListQuery__
 *
 * To run a query within a React component, call `useOrgMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMemberListQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrgMemberListQuery(baseOptions: Apollo.QueryHookOptions<OrgMemberListQuery, OrgMemberListQueryVariables> & ({ variables: OrgMemberListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgMemberListQuery, OrgMemberListQueryVariables>(OrgMemberListDocument, options);
      }
export function useOrgMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgMemberListQuery, OrgMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgMemberListQuery, OrgMemberListQueryVariables>(OrgMemberListDocument, options);
        }
export function useOrgMemberListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgMemberListQuery, OrgMemberListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgMemberListQuery, OrgMemberListQueryVariables>(OrgMemberListDocument, options);
        }
export type OrgMemberListQueryHookResult = ReturnType<typeof useOrgMemberListQuery>;
export type OrgMemberListLazyQueryHookResult = ReturnType<typeof useOrgMemberListLazyQuery>;
export type OrgMemberListSuspenseQueryHookResult = ReturnType<typeof useOrgMemberListSuspenseQuery>;
export type OrgMemberListQueryResult = Apollo.QueryResult<OrgMemberListQuery, OrgMemberListQueryVariables>;
export const CurrentUserOrgMembershipsDocument = gql`
    query CurrentUserOrgMemberships {
  currentUserOrgMemberships {
    ...UserOrgMembership
  }
}
    ${UserOrgMembershipFragmentDoc}`;

/**
 * __useCurrentUserOrgMembershipsQuery__
 *
 * To run a query within a React component, call `useCurrentUserOrgMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserOrgMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserOrgMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserOrgMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>(CurrentUserOrgMembershipsDocument, options);
      }
export function useCurrentUserOrgMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>(CurrentUserOrgMembershipsDocument, options);
        }
export function useCurrentUserOrgMembershipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>(CurrentUserOrgMembershipsDocument, options);
        }
export type CurrentUserOrgMembershipsQueryHookResult = ReturnType<typeof useCurrentUserOrgMembershipsQuery>;
export type CurrentUserOrgMembershipsLazyQueryHookResult = ReturnType<typeof useCurrentUserOrgMembershipsLazyQuery>;
export type CurrentUserOrgMembershipsSuspenseQueryHookResult = ReturnType<typeof useCurrentUserOrgMembershipsSuspenseQuery>;
export type CurrentUserOrgMembershipsQueryResult = Apollo.QueryResult<CurrentUserOrgMembershipsQuery, CurrentUserOrgMembershipsQueryVariables>;
export const CreateOrgDocument = gql`
    mutation CreateOrg($orgName: String!, $billingEmail: String!, $displayName: String!) {
  createOrg(
    orgName: $orgName
    billingEmail: $billingEmail
    displayName: $displayName
  ) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type CreateOrgMutationFn = Apollo.MutationFunction<CreateOrgMutation, CreateOrgMutationVariables>;

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      billingEmail: // value for 'billingEmail'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useCreateOrgMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, options);
      }
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<CreateOrgMutation, CreateOrgMutationVariables>;
export const RowsForViewsDocument = gql`
    query RowsForViews($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!) {
  views(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  ) {
    list {
      ...RowForSchemas
    }
  }
}
    ${RowForSchemasFragmentDoc}`;

/**
 * __useRowsForViewsQuery__
 *
 * To run a query within a React component, call `useRowsForViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowsForViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowsForViewsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useRowsForViewsQuery(baseOptions: Apollo.QueryHookOptions<RowsForViewsQuery, RowsForViewsQueryVariables> & ({ variables: RowsForViewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RowsForViewsQuery, RowsForViewsQueryVariables>(RowsForViewsDocument, options);
      }
export function useRowsForViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowsForViewsQuery, RowsForViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RowsForViewsQuery, RowsForViewsQueryVariables>(RowsForViewsDocument, options);
        }
export function useRowsForViewsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RowsForViewsQuery, RowsForViewsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RowsForViewsQuery, RowsForViewsQueryVariables>(RowsForViewsDocument, options);
        }
export type RowsForViewsQueryHookResult = ReturnType<typeof useRowsForViewsQuery>;
export type RowsForViewsLazyQueryHookResult = ReturnType<typeof useRowsForViewsLazyQuery>;
export type RowsForViewsSuspenseQueryHookResult = ReturnType<typeof useRowsForViewsSuspenseQuery>;
export type RowsForViewsQueryResult = Apollo.QueryResult<RowsForViewsQuery, RowsForViewsQueryVariables>;
export const SupportedZonesQueryDocument = gql`
    query SupportedZonesQuery($cloud: CloudProvider!) {
  supportedZones(cloud: $cloud) {
    ...SupportedZones
  }
}
    ${SupportedZonesFragmentDoc}`;

/**
 * __useSupportedZonesQuery__
 *
 * To run a query within a React component, call `useSupportedZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedZonesQuery({
 *   variables: {
 *      cloud: // value for 'cloud'
 *   },
 * });
 */
export function useSupportedZonesQuery(baseOptions: Apollo.QueryHookOptions<SupportedZonesQuery, SupportedZonesQueryVariables> & ({ variables: SupportedZonesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedZonesQuery, SupportedZonesQueryVariables>(SupportedZonesQueryDocument, options);
      }
export function useSupportedZonesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedZonesQuery, SupportedZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedZonesQuery, SupportedZonesQueryVariables>(SupportedZonesQueryDocument, options);
        }
export function useSupportedZonesQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupportedZonesQuery, SupportedZonesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupportedZonesQuery, SupportedZonesQueryVariables>(SupportedZonesQueryDocument, options);
        }
export type SupportedZonesQueryHookResult = ReturnType<typeof useSupportedZonesQuery>;
export type SupportedZonesQueryLazyQueryHookResult = ReturnType<typeof useSupportedZonesQueryLazyQuery>;
export type SupportedZonesQuerySuspenseQueryHookResult = ReturnType<typeof useSupportedZonesQuerySuspenseQuery>;
export type SupportedZonesQueryQueryResult = Apollo.QueryResult<SupportedZonesQuery, SupportedZonesQueryVariables>;
export const AvailableInstanceTypesQueryDocument = gql`
    query AvailableInstanceTypesQuery($cloud: CloudProvider!, $zone: String!) {
  availableInstanceTypes(cloud: $cloud, zone: $zone) {
    ...InstanceTypes
  }
}
    ${InstanceTypesFragmentDoc}`;

/**
 * __useAvailableInstanceTypesQuery__
 *
 * To run a query within a React component, call `useAvailableInstanceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableInstanceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableInstanceTypesQuery({
 *   variables: {
 *      cloud: // value for 'cloud'
 *      zone: // value for 'zone'
 *   },
 * });
 */
export function useAvailableInstanceTypesQuery(baseOptions: Apollo.QueryHookOptions<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables> & ({ variables: AvailableInstanceTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>(AvailableInstanceTypesQueryDocument, options);
      }
export function useAvailableInstanceTypesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>(AvailableInstanceTypesQueryDocument, options);
        }
export function useAvailableInstanceTypesQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>(AvailableInstanceTypesQueryDocument, options);
        }
export type AvailableInstanceTypesQueryHookResult = ReturnType<typeof useAvailableInstanceTypesQuery>;
export type AvailableInstanceTypesQueryLazyQueryHookResult = ReturnType<typeof useAvailableInstanceTypesQueryLazyQuery>;
export type AvailableInstanceTypesQuerySuspenseQueryHookResult = ReturnType<typeof useAvailableInstanceTypesQuerySuspenseQuery>;
export type AvailableInstanceTypesQueryQueryResult = Apollo.QueryResult<AvailableInstanceTypesQuery, AvailableInstanceTypesQueryVariables>;
export const StorageOptionsQueryDocument = gql`
    query StorageOptionsQuery($cloud: CloudProvider!, $zone: String!, $instanceTypeId: String!) {
  storageOptions(cloud: $cloud, zone: $zone, instanceTypeId: $instanceTypeId) {
    ...StorageOptions
  }
}
    ${StorageOptionsFragmentDoc}`;

/**
 * __useStorageOptionsQuery__
 *
 * To run a query within a React component, call `useStorageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageOptionsQuery({
 *   variables: {
 *      cloud: // value for 'cloud'
 *      zone: // value for 'zone'
 *      instanceTypeId: // value for 'instanceTypeId'
 *   },
 * });
 */
export function useStorageOptionsQuery(baseOptions: Apollo.QueryHookOptions<StorageOptionsQuery, StorageOptionsQueryVariables> & ({ variables: StorageOptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorageOptionsQuery, StorageOptionsQueryVariables>(StorageOptionsQueryDocument, options);
      }
export function useStorageOptionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorageOptionsQuery, StorageOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorageOptionsQuery, StorageOptionsQueryVariables>(StorageOptionsQueryDocument, options);
        }
export function useStorageOptionsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StorageOptionsQuery, StorageOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StorageOptionsQuery, StorageOptionsQueryVariables>(StorageOptionsQueryDocument, options);
        }
export type StorageOptionsQueryHookResult = ReturnType<typeof useStorageOptionsQuery>;
export type StorageOptionsQueryLazyQueryHookResult = ReturnType<typeof useStorageOptionsQueryLazyQuery>;
export type StorageOptionsQuerySuspenseQueryHookResult = ReturnType<typeof useStorageOptionsQuerySuspenseQuery>;
export type StorageOptionsQueryQueryResult = Apollo.QueryResult<StorageOptionsQuery, StorageOptionsQueryVariables>;
export const CalcCostDocument = gql`
    query CalcCost($cloud: CloudProvider!, $zone: String!, $instanceTypeId: String!, $volumeTypeId: String!, $volumeSize: Int!, $replicas: Int) {
  calcCost(
    cloud: $cloud
    zone: $zone
    instanceTypeId: $instanceTypeId
    volumeTypeId: $volumeTypeId
    volumeSize: $volumeSize
    replicas: $replicas
  ) {
    costs {
      ...CostType
    }
  }
}
    ${CostTypeFragmentDoc}`;

/**
 * __useCalcCostQuery__
 *
 * To run a query within a React component, call `useCalcCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalcCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalcCostQuery({
 *   variables: {
 *      cloud: // value for 'cloud'
 *      zone: // value for 'zone'
 *      instanceTypeId: // value for 'instanceTypeId'
 *      volumeTypeId: // value for 'volumeTypeId'
 *      volumeSize: // value for 'volumeSize'
 *      replicas: // value for 'replicas'
 *   },
 * });
 */
export function useCalcCostQuery(baseOptions: Apollo.QueryHookOptions<CalcCostQuery, CalcCostQueryVariables> & ({ variables: CalcCostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalcCostQuery, CalcCostQueryVariables>(CalcCostDocument, options);
      }
export function useCalcCostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalcCostQuery, CalcCostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalcCostQuery, CalcCostQueryVariables>(CalcCostDocument, options);
        }
export function useCalcCostSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalcCostQuery, CalcCostQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalcCostQuery, CalcCostQueryVariables>(CalcCostDocument, options);
        }
export type CalcCostQueryHookResult = ReturnType<typeof useCalcCostQuery>;
export type CalcCostLazyQueryHookResult = ReturnType<typeof useCalcCostLazyQuery>;
export type CalcCostSuspenseQueryHookResult = ReturnType<typeof useCalcCostSuspenseQuery>;
export type CalcCostQueryResult = Apollo.QueryResult<CalcCostQuery, CalcCostQueryVariables>;
export const CreateDeploymentDocument = gql`
    mutation CreateDeployment($ownerName: String!, $deploymentName: String!, $cloud: CloudProvider!, $zone: String!, $instanceTypeId: String!, $volumeTypeId: String!, $volumeSize: Int!, $flag: DeploymentFlag, $webPKICert: Boolean, $canCreateWorkbenchUsers: Boolean!, $backupName: BackupName, $exposeRemotesapiEndpoint: Boolean, $existingDepSettings: ExistingDepSettings, $numReplicas: Int, $clusterType: ClusterType, $usePrivateLink: Boolean, $allowedPeeringAccountIds: [String!], $allowedProjectIds: [String!], $allowedVpcs: [GcpVpcInput!]) {
  createDeployment(
    ownerName: $ownerName
    deploymentName: $deploymentName
    cloud: $cloud
    zone: $zone
    instanceTypeId: $instanceTypeId
    volumeTypeId: $volumeTypeId
    volumeSize: $volumeSize
    flag: $flag
    webPKICert: $webPKICert
    canCreateWorkbenchUsers: $canCreateWorkbenchUsers
    backupName: $backupName
    exposeRemotesapiEndpoint: $exposeRemotesapiEndpoint
    existingDepSettings: $existingDepSettings
    numReplicas: $numReplicas
    clusterType: $clusterType
    usePrivateLink: $usePrivateLink
    allowedPeeringAccountIds: $allowedPeeringAccountIds
    allowedProjectIds: $allowedProjectIds
    allowedVpcs: $allowedVpcs
  ) {
    _id
    deploymentName
    ownerName
  }
}
    `;
export type CreateDeploymentMutationFn = Apollo.MutationFunction<CreateDeploymentMutation, CreateDeploymentMutationVariables>;

/**
 * __useCreateDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentMutation, { data, loading, error }] = useCreateDeploymentMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      cloud: // value for 'cloud'
 *      zone: // value for 'zone'
 *      instanceTypeId: // value for 'instanceTypeId'
 *      volumeTypeId: // value for 'volumeTypeId'
 *      volumeSize: // value for 'volumeSize'
 *      flag: // value for 'flag'
 *      webPKICert: // value for 'webPKICert'
 *      canCreateWorkbenchUsers: // value for 'canCreateWorkbenchUsers'
 *      backupName: // value for 'backupName'
 *      exposeRemotesapiEndpoint: // value for 'exposeRemotesapiEndpoint'
 *      existingDepSettings: // value for 'existingDepSettings'
 *      numReplicas: // value for 'numReplicas'
 *      clusterType: // value for 'clusterType'
 *      usePrivateLink: // value for 'usePrivateLink'
 *      allowedPeeringAccountIds: // value for 'allowedPeeringAccountIds'
 *      allowedProjectIds: // value for 'allowedProjectIds'
 *      allowedVpcs: // value for 'allowedVpcs'
 *   },
 * });
 */
export function useCreateDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentMutation, CreateDeploymentMutationVariables>(CreateDeploymentDocument, options);
      }
export type CreateDeploymentMutationHookResult = ReturnType<typeof useCreateDeploymentMutation>;
export type CreateDeploymentMutationResult = Apollo.MutationResult<CreateDeploymentMutation>;
export type CreateDeploymentMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>;
export const BranchPullStateDocument = gql`
    query BranchPullState($deploymentName: String!, $ownerName: String!, $databaseName: String!, $offset: Int, $sortBy: SortBranchesBy) {
  branchesPullState(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    offset: $offset
    sortBy: $sortBy
  ) {
    list {
      ...BranchPullStateForBranchesList
    }
    nextOffset
  }
}
    ${BranchPullStateForBranchesListFragmentDoc}`;

/**
 * __useBranchPullStateQuery__
 *
 * To run a query within a React component, call `useBranchPullStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchPullStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchPullStateQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBranchPullStateQuery(baseOptions: Apollo.QueryHookOptions<BranchPullStateQuery, BranchPullStateQueryVariables> & ({ variables: BranchPullStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchPullStateQuery, BranchPullStateQueryVariables>(BranchPullStateDocument, options);
      }
export function useBranchPullStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchPullStateQuery, BranchPullStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchPullStateQuery, BranchPullStateQueryVariables>(BranchPullStateDocument, options);
        }
export function useBranchPullStateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchPullStateQuery, BranchPullStateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchPullStateQuery, BranchPullStateQueryVariables>(BranchPullStateDocument, options);
        }
export type BranchPullStateQueryHookResult = ReturnType<typeof useBranchPullStateQuery>;
export type BranchPullStateLazyQueryHookResult = ReturnType<typeof useBranchPullStateLazyQuery>;
export type BranchPullStateSuspenseQueryHookResult = ReturnType<typeof useBranchPullStateSuspenseQuery>;
export type BranchPullStateQueryResult = Apollo.QueryResult<BranchPullStateQuery, BranchPullStateQueryVariables>;
export const CreateBranchDocument = gql`
    mutation CreateBranch($newBranchName: String!, $ownerName: String!, $deploymentName: String!, $databaseName: String!, $fromRefName: String!) {
  createBranch(
    newBranchName: $newBranchName
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    fromRefName: $fromRefName
  ) {
    ...BranchForCreateBranch
  }
}
    ${BranchForCreateBranchFragmentDoc}`;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      newBranchName: // value for 'newBranchName'
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      fromRefName: // value for 'fromRefName'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;
export const HistoryForBranchDocument = gql`
    query HistoryForBranch($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $offset: Int) {
  commits(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    offset: $offset
  ) {
    ...CommitListForHistory
  }
}
    ${CommitListForHistoryFragmentDoc}`;

/**
 * __useHistoryForBranchQuery__
 *
 * To run a query within a React component, call `useHistoryForBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryForBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryForBranchQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useHistoryForBranchQuery(baseOptions: Apollo.QueryHookOptions<HistoryForBranchQuery, HistoryForBranchQueryVariables> & ({ variables: HistoryForBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryForBranchQuery, HistoryForBranchQueryVariables>(HistoryForBranchDocument, options);
      }
export function useHistoryForBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryForBranchQuery, HistoryForBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryForBranchQuery, HistoryForBranchQueryVariables>(HistoryForBranchDocument, options);
        }
export function useHistoryForBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HistoryForBranchQuery, HistoryForBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HistoryForBranchQuery, HistoryForBranchQueryVariables>(HistoryForBranchDocument, options);
        }
export type HistoryForBranchQueryHookResult = ReturnType<typeof useHistoryForBranchQuery>;
export type HistoryForBranchLazyQueryHookResult = ReturnType<typeof useHistoryForBranchLazyQuery>;
export type HistoryForBranchSuspenseQueryHookResult = ReturnType<typeof useHistoryForBranchSuspenseQuery>;
export type HistoryForBranchQueryResult = Apollo.QueryResult<HistoryForBranchQuery, HistoryForBranchQueryVariables>;
export const HistoryForCommitDocument = gql`
    query HistoryForCommit($deploymentName: String!, $ownerName: String!, $databaseName: String!, $afterCommitId: String!) {
  commits(
    deploymentName: $deploymentName
    ownerName: $ownerName
    afterCommitId: $afterCommitId
    databaseName: $databaseName
  ) {
    list {
      ...CommitForAfterCommitHistory
    }
  }
}
    ${CommitForAfterCommitHistoryFragmentDoc}`;

/**
 * __useHistoryForCommitQuery__
 *
 * To run a query within a React component, call `useHistoryForCommitQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryForCommitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryForCommitQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      afterCommitId: // value for 'afterCommitId'
 *   },
 * });
 */
export function useHistoryForCommitQuery(baseOptions: Apollo.QueryHookOptions<HistoryForCommitQuery, HistoryForCommitQueryVariables> & ({ variables: HistoryForCommitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryForCommitQuery, HistoryForCommitQueryVariables>(HistoryForCommitDocument, options);
      }
export function useHistoryForCommitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryForCommitQuery, HistoryForCommitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryForCommitQuery, HistoryForCommitQueryVariables>(HistoryForCommitDocument, options);
        }
export function useHistoryForCommitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HistoryForCommitQuery, HistoryForCommitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HistoryForCommitQuery, HistoryForCommitQueryVariables>(HistoryForCommitDocument, options);
        }
export type HistoryForCommitQueryHookResult = ReturnType<typeof useHistoryForCommitQuery>;
export type HistoryForCommitLazyQueryHookResult = ReturnType<typeof useHistoryForCommitLazyQuery>;
export type HistoryForCommitSuspenseQueryHookResult = ReturnType<typeof useHistoryForCommitSuspenseQuery>;
export type HistoryForCommitQueryResult = Apollo.QueryResult<HistoryForCommitQuery, HistoryForCommitQueryVariables>;
export const DefaultBranchPageQueryDocument = gql`
    query DefaultBranchPageQuery($deploymentName: String!, $ownerName: String!, $databaseName: String!, $filterSystemTables: Boolean) {
  defaultBranch(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    _id
    branchName
    tableNames(filterSystemTables: $filterSystemTables)
  }
}
    `;

/**
 * __useDefaultBranchPageQuery__
 *
 * To run a query within a React component, call `useDefaultBranchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultBranchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultBranchPageQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      filterSystemTables: // value for 'filterSystemTables'
 *   },
 * });
 */
export function useDefaultBranchPageQuery(baseOptions: Apollo.QueryHookOptions<DefaultBranchPageQuery, DefaultBranchPageQueryVariables> & ({ variables: DefaultBranchPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>(DefaultBranchPageQueryDocument, options);
      }
export function useDefaultBranchPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>(DefaultBranchPageQueryDocument, options);
        }
export function useDefaultBranchPageQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>(DefaultBranchPageQueryDocument, options);
        }
export type DefaultBranchPageQueryHookResult = ReturnType<typeof useDefaultBranchPageQuery>;
export type DefaultBranchPageQueryLazyQueryHookResult = ReturnType<typeof useDefaultBranchPageQueryLazyQuery>;
export type DefaultBranchPageQuerySuspenseQueryHookResult = ReturnType<typeof useDefaultBranchPageQuerySuspenseQuery>;
export type DefaultBranchPageQueryQueryResult = Apollo.QueryResult<DefaultBranchPageQuery, DefaultBranchPageQueryVariables>;
export const DocsRowsForDocPageQueryDocument = gql`
    query DocsRowsForDocPageQuery($deploymentName: String!, $ownerName: String!, $databaseName: String!, $refName: String!) {
  docs(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
  ) {
    ...DocListForDocPage
  }
}
    ${DocListForDocPageFragmentDoc}`;

/**
 * __useDocsRowsForDocPageQuery__
 *
 * To run a query within a React component, call `useDocsRowsForDocPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocsRowsForDocPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocsRowsForDocPageQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useDocsRowsForDocPageQuery(baseOptions: Apollo.QueryHookOptions<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables> & ({ variables: DocsRowsForDocPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>(DocsRowsForDocPageQueryDocument, options);
      }
export function useDocsRowsForDocPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>(DocsRowsForDocPageQueryDocument, options);
        }
export function useDocsRowsForDocPageQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>(DocsRowsForDocPageQueryDocument, options);
        }
export type DocsRowsForDocPageQueryHookResult = ReturnType<typeof useDocsRowsForDocPageQuery>;
export type DocsRowsForDocPageQueryLazyQueryHookResult = ReturnType<typeof useDocsRowsForDocPageQueryLazyQuery>;
export type DocsRowsForDocPageQuerySuspenseQueryHookResult = ReturnType<typeof useDocsRowsForDocPageQuerySuspenseQuery>;
export type DocsRowsForDocPageQueryQueryResult = Apollo.QueryResult<DocsRowsForDocPageQuery, DocsRowsForDocPageQueryVariables>;
export const DocDataForDocPageDocument = gql`
    query DocDataForDocPage($deploymentName: String!, $ownerName: String!, $databaseName: String!, $refName: String!, $docType: DocType) {
  docOrDefaultDoc(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    docType: $docType
  ) {
    docRow {
      ...DocColumnValuesForDocPage
    }
  }
}
    ${DocColumnValuesForDocPageFragmentDoc}`;

/**
 * __useDocDataForDocPageQuery__
 *
 * To run a query within a React component, call `useDocDataForDocPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocDataForDocPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocDataForDocPageQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      docType: // value for 'docType'
 *   },
 * });
 */
export function useDocDataForDocPageQuery(baseOptions: Apollo.QueryHookOptions<DocDataForDocPageQuery, DocDataForDocPageQueryVariables> & ({ variables: DocDataForDocPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>(DocDataForDocPageDocument, options);
      }
export function useDocDataForDocPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>(DocDataForDocPageDocument, options);
        }
export function useDocDataForDocPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>(DocDataForDocPageDocument, options);
        }
export type DocDataForDocPageQueryHookResult = ReturnType<typeof useDocDataForDocPageQuery>;
export type DocDataForDocPageLazyQueryHookResult = ReturnType<typeof useDocDataForDocPageLazyQuery>;
export type DocDataForDocPageSuspenseQueryHookResult = ReturnType<typeof useDocDataForDocPageSuspenseQuery>;
export type DocDataForDocPageQueryResult = Apollo.QueryResult<DocDataForDocPageQuery, DocDataForDocPageQueryVariables>;
export const DocPageQueryNoBranchDocument = gql`
    query DocPageQueryNoBranch($ownerName: String!, $deploymentName: String!, $databaseName: String!) {
  branchOrDefault(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    _id
    branchName
  }
}
    `;

/**
 * __useDocPageQueryNoBranch__
 *
 * To run a query within a React component, call `useDocPageQueryNoBranch` and pass it any options that fit your needs.
 * When your component renders, `useDocPageQueryNoBranch` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocPageQueryNoBranch({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useDocPageQueryNoBranch(baseOptions: Apollo.QueryHookOptions<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables> & ({ variables: DocPageQueryNoBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>(DocPageQueryNoBranchDocument, options);
      }
export function useDocPageQueryNoBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>(DocPageQueryNoBranchDocument, options);
        }
export function useDocPageQueryNoBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>(DocPageQueryNoBranchDocument, options);
        }
export type DocPageQueryNoBranchHookResult = ReturnType<typeof useDocPageQueryNoBranch>;
export type DocPageQueryNoBranchLazyQueryHookResult = ReturnType<typeof useDocPageQueryNoBranchLazyQuery>;
export type DocPageQueryNoBranchSuspenseQueryHookResult = ReturnType<typeof useDocPageQueryNoBranchSuspenseQuery>;
export type DocPageQueryNoBranchQueryResult = Apollo.QueryResult<DocPageQueryNoBranchQuery, DocPageQueryNoBranchQueryVariables>;
export const CreatePullRequestDocument = gql`
    mutation CreatePullRequest($title: String!, $description: String!, $fromBranchName: String!, $toBranchName: String!, $deploymentName: String!, $ownerName: String!, $databaseName: String!) {
  createPull(
    title: $title
    description: $description
    fromBranchName: $fromBranchName
    toBranchName: $toBranchName
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    _id
    pullId
  }
}
    `;
export type CreatePullRequestMutationFn = Apollo.MutationFunction<CreatePullRequestMutation, CreatePullRequestMutationVariables>;

/**
 * __useCreatePullRequestMutation__
 *
 * To run a mutation, you first call `useCreatePullRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePullRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPullRequestMutation, { data, loading, error }] = useCreatePullRequestMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      fromBranchName: // value for 'fromBranchName'
 *      toBranchName: // value for 'toBranchName'
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useCreatePullRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePullRequestMutation, CreatePullRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePullRequestMutation, CreatePullRequestMutationVariables>(CreatePullRequestDocument, options);
      }
export type CreatePullRequestMutationHookResult = ReturnType<typeof useCreatePullRequestMutation>;
export type CreatePullRequestMutationResult = Apollo.MutationResult<CreatePullRequestMutation>;
export type CreatePullRequestMutationOptions = Apollo.BaseMutationOptions<CreatePullRequestMutation, CreatePullRequestMutationVariables>;
export const BranchesForNewPullDocument = gql`
    query BranchesForNewPull($ownerName: String!, $deploymentName: String!, $databaseName: String!) {
  allBranches(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    ...BranchForNewPull
  }
}
    ${BranchForNewPullFragmentDoc}`;

/**
 * __useBranchesForNewPullQuery__
 *
 * To run a query within a React component, call `useBranchesForNewPullQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesForNewPullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesForNewPullQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useBranchesForNewPullQuery(baseOptions: Apollo.QueryHookOptions<BranchesForNewPullQuery, BranchesForNewPullQueryVariables> & ({ variables: BranchesForNewPullQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>(BranchesForNewPullDocument, options);
      }
export function useBranchesForNewPullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>(BranchesForNewPullDocument, options);
        }
export function useBranchesForNewPullSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>(BranchesForNewPullDocument, options);
        }
export type BranchesForNewPullQueryHookResult = ReturnType<typeof useBranchesForNewPullQuery>;
export type BranchesForNewPullLazyQueryHookResult = ReturnType<typeof useBranchesForNewPullLazyQuery>;
export type BranchesForNewPullSuspenseQueryHookResult = ReturnType<typeof useBranchesForNewPullSuspenseQuery>;
export type BranchesForNewPullQueryResult = Apollo.QueryResult<BranchesForNewPullQuery, BranchesForNewPullQueryVariables>;
export const PullPreviewForNewPullDocument = gql`
    query PullPreviewForNewPull($fromBranchName: String!, $toBranchName: String!, $deploymentName: String!, $ownerName: String!, $databaseName: String!) {
  pullPreview(
    fromBranchName: $fromBranchName
    toBranchName: $toBranchName
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    ...PullPreview
  }
}
    ${PullPreviewFragmentDoc}`;

/**
 * __usePullPreviewForNewPullQuery__
 *
 * To run a query within a React component, call `usePullPreviewForNewPullQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullPreviewForNewPullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullPreviewForNewPullQuery({
 *   variables: {
 *      fromBranchName: // value for 'fromBranchName'
 *      toBranchName: // value for 'toBranchName'
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function usePullPreviewForNewPullQuery(baseOptions: Apollo.QueryHookOptions<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables> & ({ variables: PullPreviewForNewPullQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>(PullPreviewForNewPullDocument, options);
      }
export function usePullPreviewForNewPullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>(PullPreviewForNewPullDocument, options);
        }
export function usePullPreviewForNewPullSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>(PullPreviewForNewPullDocument, options);
        }
export type PullPreviewForNewPullQueryHookResult = ReturnType<typeof usePullPreviewForNewPullQuery>;
export type PullPreviewForNewPullLazyQueryHookResult = ReturnType<typeof usePullPreviewForNewPullLazyQuery>;
export type PullPreviewForNewPullSuspenseQueryHookResult = ReturnType<typeof usePullPreviewForNewPullSuspenseQuery>;
export type PullPreviewForNewPullQueryResult = Apollo.QueryResult<PullPreviewForNewPullQuery, PullPreviewForNewPullQueryVariables>;
export const BranchesForPull404PageDocument = gql`
    query BranchesForPull404Page($deploymentName: String!, $ownerName: String!, $databaseName: String!, $pullId: String!) {
  pull(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    _id
    fromBranchName
    toBranchName
  }
}
    `;

/**
 * __useBranchesForPull404PageQuery__
 *
 * To run a query within a React component, call `useBranchesForPull404PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesForPull404PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesForPull404PageQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function useBranchesForPull404PageQuery(baseOptions: Apollo.QueryHookOptions<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables> & ({ variables: BranchesForPull404PageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>(BranchesForPull404PageDocument, options);
      }
export function useBranchesForPull404PageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>(BranchesForPull404PageDocument, options);
        }
export function useBranchesForPull404PageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>(BranchesForPull404PageDocument, options);
        }
export type BranchesForPull404PageQueryHookResult = ReturnType<typeof useBranchesForPull404PageQuery>;
export type BranchesForPull404PageLazyQueryHookResult = ReturnType<typeof useBranchesForPull404PageLazyQuery>;
export type BranchesForPull404PageSuspenseQueryHookResult = ReturnType<typeof useBranchesForPull404PageSuspenseQuery>;
export type BranchesForPull404PageQueryResult = Apollo.QueryResult<BranchesForPull404PageQuery, BranchesForPull404PageQueryVariables>;
export const DeleteBranchDocument = gql`
    mutation DeleteBranch($deploymentName: String!, $ownerName: String!, $branchName: String!, $databaseName: String!) {
  deleteBranch(
    deploymentName: $deploymentName
    ownerName: $ownerName
    branchName: $branchName
    databaseName: $databaseName
  )
}
    `;
export type DeleteBranchMutationFn = Apollo.MutationFunction<DeleteBranchMutation, DeleteBranchMutationVariables>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      branchName: // value for 'branchName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useDeleteBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBranchMutation, DeleteBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(DeleteBranchDocument, options);
      }
export type DeleteBranchMutationHookResult = ReturnType<typeof useDeleteBranchMutation>;
export type DeleteBranchMutationResult = Apollo.MutationResult<DeleteBranchMutation>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const GetBranchForPullDocument = gql`
    query GetBranchForPull($deploymentName: String!, $ownerName: String!, $branchName: String!, $databaseName: String!) {
  branch(
    deploymentName: $deploymentName
    ownerName: $ownerName
    branchName: $branchName
    databaseName: $databaseName
  ) {
    _id
    isDefault
  }
}
    `;

/**
 * __useGetBranchForPullQuery__
 *
 * To run a query within a React component, call `useGetBranchForPullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchForPullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchForPullQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      branchName: // value for 'branchName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useGetBranchForPullQuery(baseOptions: Apollo.QueryHookOptions<GetBranchForPullQuery, GetBranchForPullQueryVariables> & ({ variables: GetBranchForPullQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchForPullQuery, GetBranchForPullQueryVariables>(GetBranchForPullDocument, options);
      }
export function useGetBranchForPullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchForPullQuery, GetBranchForPullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchForPullQuery, GetBranchForPullQueryVariables>(GetBranchForPullDocument, options);
        }
export function useGetBranchForPullSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchForPullQuery, GetBranchForPullQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchForPullQuery, GetBranchForPullQueryVariables>(GetBranchForPullDocument, options);
        }
export type GetBranchForPullQueryHookResult = ReturnType<typeof useGetBranchForPullQuery>;
export type GetBranchForPullLazyQueryHookResult = ReturnType<typeof useGetBranchForPullLazyQuery>;
export type GetBranchForPullSuspenseQueryHookResult = ReturnType<typeof useGetBranchForPullSuspenseQuery>;
export type GetBranchForPullQueryResult = Apollo.QueryResult<GetBranchForPullQuery, GetBranchForPullQueryVariables>;
export const MergePullDocument = gql`
    mutation MergePull($ownerName: String!, $deploymentName: String!, $databaseName: String!, $pullId: String!) {
  mergePull(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    ...PullForPullDetails
  }
}
    ${PullForPullDetailsFragmentDoc}`;
export type MergePullMutationFn = Apollo.MutationFunction<MergePullMutation, MergePullMutationVariables>;

/**
 * __useMergePullMutation__
 *
 * To run a mutation, you first call `useMergePullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePullMutation, { data, loading, error }] = useMergePullMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function useMergePullMutation(baseOptions?: Apollo.MutationHookOptions<MergePullMutation, MergePullMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergePullMutation, MergePullMutationVariables>(MergePullDocument, options);
      }
export type MergePullMutationHookResult = ReturnType<typeof useMergePullMutation>;
export type MergePullMutationResult = Apollo.MutationResult<MergePullMutation>;
export type MergePullMutationOptions = Apollo.BaseMutationOptions<MergePullMutation, MergePullMutationVariables>;
export const DeletePullCommentDocument = gql`
    mutation DeletePullComment($_id: ID!) {
  deletePullComment(_id: $_id) {
    ...PullSummaryForPullDetails
  }
}
    ${PullSummaryForPullDetailsFragmentDoc}`;
export type DeletePullCommentMutationFn = Apollo.MutationFunction<DeletePullCommentMutation, DeletePullCommentMutationVariables>;

/**
 * __useDeletePullCommentMutation__
 *
 * To run a mutation, you first call `useDeletePullCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePullCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePullCommentMutation, { data, loading, error }] = useDeletePullCommentMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeletePullCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePullCommentMutation, DeletePullCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePullCommentMutation, DeletePullCommentMutationVariables>(DeletePullCommentDocument, options);
      }
export type DeletePullCommentMutationHookResult = ReturnType<typeof useDeletePullCommentMutation>;
export type DeletePullCommentMutationResult = Apollo.MutationResult<DeletePullCommentMutation>;
export type DeletePullCommentMutationOptions = Apollo.BaseMutationOptions<DeletePullCommentMutation, DeletePullCommentMutationVariables>;
export const UpdatePullCommentDocument = gql`
    mutation UpdatePullComment($_id: ID!, $authorName: String!, $comment: String!) {
  updatePullComment(_id: $_id, authorName: $authorName, comment: $comment) {
    ...PullSummaryForPullDetails
  }
}
    ${PullSummaryForPullDetailsFragmentDoc}`;
export type UpdatePullCommentMutationFn = Apollo.MutationFunction<UpdatePullCommentMutation, UpdatePullCommentMutationVariables>;

/**
 * __useUpdatePullCommentMutation__
 *
 * To run a mutation, you first call `useUpdatePullCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePullCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePullCommentMutation, { data, loading, error }] = useUpdatePullCommentMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      authorName: // value for 'authorName'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdatePullCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePullCommentMutation, UpdatePullCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePullCommentMutation, UpdatePullCommentMutationVariables>(UpdatePullCommentDocument, options);
      }
export type UpdatePullCommentMutationHookResult = ReturnType<typeof useUpdatePullCommentMutation>;
export type UpdatePullCommentMutationResult = Apollo.MutationResult<UpdatePullCommentMutation>;
export type UpdatePullCommentMutationOptions = Apollo.BaseMutationOptions<UpdatePullCommentMutation, UpdatePullCommentMutationVariables>;
export const PullForPullDetailsQueryDocument = gql`
    query PullForPullDetailsQuery($deploymentName: String!, $ownerName: String!, $databaseName: String!, $pullId: String!) {
  pull(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    ...PullForPullDetails
  }
}
    ${PullForPullDetailsFragmentDoc}`;

/**
 * __usePullForPullDetailsQuery__
 *
 * To run a query within a React component, call `usePullForPullDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullForPullDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullForPullDetailsQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function usePullForPullDetailsQuery(baseOptions: Apollo.QueryHookOptions<PullForPullDetailsQuery, PullForPullDetailsQueryVariables> & ({ variables: PullForPullDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>(PullForPullDetailsQueryDocument, options);
      }
export function usePullForPullDetailsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>(PullForPullDetailsQueryDocument, options);
        }
export function usePullForPullDetailsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>(PullForPullDetailsQueryDocument, options);
        }
export type PullForPullDetailsQueryHookResult = ReturnType<typeof usePullForPullDetailsQuery>;
export type PullForPullDetailsQueryLazyQueryHookResult = ReturnType<typeof usePullForPullDetailsQueryLazyQuery>;
export type PullForPullDetailsQuerySuspenseQueryHookResult = ReturnType<typeof usePullForPullDetailsQuerySuspenseQuery>;
export type PullForPullDetailsQueryQueryResult = Apollo.QueryResult<PullForPullDetailsQuery, PullForPullDetailsQueryVariables>;
export const PullDetailsForPullDetailsDocument = gql`
    query PullDetailsForPullDetails($deploymentName: String!, $ownerName: String!, $databaseName: String!, $pullId: String!) {
  pullWithDetails(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    ...PullDetails
  }
}
    ${PullDetailsFragmentDoc}`;

/**
 * __usePullDetailsForPullDetailsQuery__
 *
 * To run a query within a React component, call `usePullDetailsForPullDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullDetailsForPullDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullDetailsForPullDetailsQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function usePullDetailsForPullDetailsQuery(baseOptions: Apollo.QueryHookOptions<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables> & ({ variables: PullDetailsForPullDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>(PullDetailsForPullDetailsDocument, options);
      }
export function usePullDetailsForPullDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>(PullDetailsForPullDetailsDocument, options);
        }
export function usePullDetailsForPullDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>(PullDetailsForPullDetailsDocument, options);
        }
export type PullDetailsForPullDetailsQueryHookResult = ReturnType<typeof usePullDetailsForPullDetailsQuery>;
export type PullDetailsForPullDetailsLazyQueryHookResult = ReturnType<typeof usePullDetailsForPullDetailsLazyQuery>;
export type PullDetailsForPullDetailsSuspenseQueryHookResult = ReturnType<typeof usePullDetailsForPullDetailsSuspenseQuery>;
export type PullDetailsForPullDetailsQueryResult = Apollo.QueryResult<PullDetailsForPullDetailsQuery, PullDetailsForPullDetailsQueryVariables>;
export const CreatePullCommentDocument = gql`
    mutation CreatePullComment($deploymentName: String!, $ownerName: String!, $parentId: String!, $comment: String!) {
  createPullComment(
    deploymentName: $deploymentName
    ownerName: $ownerName
    pullId: $parentId
    comment: $comment
  ) {
    ...PullSummaryForPullDetails
  }
}
    ${PullSummaryForPullDetailsFragmentDoc}`;
export type CreatePullCommentMutationFn = Apollo.MutationFunction<CreatePullCommentMutation, CreatePullCommentMutationVariables>;

/**
 * __useCreatePullCommentMutation__
 *
 * To run a mutation, you first call `useCreatePullCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePullCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPullCommentMutation, { data, loading, error }] = useCreatePullCommentMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      parentId: // value for 'parentId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreatePullCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePullCommentMutation, CreatePullCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePullCommentMutation, CreatePullCommentMutationVariables>(CreatePullCommentDocument, options);
      }
export type CreatePullCommentMutationHookResult = ReturnType<typeof useCreatePullCommentMutation>;
export type CreatePullCommentMutationResult = Apollo.MutationResult<CreatePullCommentMutation>;
export type CreatePullCommentMutationOptions = Apollo.BaseMutationOptions<CreatePullCommentMutation, CreatePullCommentMutationVariables>;
export const UpdatePullInfoDocument = gql`
    mutation UpdatePullInfo($deploymentName: String!, $ownerName: String!, $databaseName: String!, $pullId: String!, $title: String!, $description: String!, $state: PullState!) {
  updatePull(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
    title: $title
    description: $description
    state: $state
  ) {
    _id
  }
}
    `;
export type UpdatePullInfoMutationFn = Apollo.MutationFunction<UpdatePullInfoMutation, UpdatePullInfoMutationVariables>;

/**
 * __useUpdatePullInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePullInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePullInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePullInfoMutation, { data, loading, error }] = useUpdatePullInfoMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdatePullInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePullInfoMutation, UpdatePullInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePullInfoMutation, UpdatePullInfoMutationVariables>(UpdatePullInfoDocument, options);
      }
export type UpdatePullInfoMutationHookResult = ReturnType<typeof useUpdatePullInfoMutation>;
export type UpdatePullInfoMutationResult = Apollo.MutationResult<UpdatePullInfoMutation>;
export type UpdatePullInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePullInfoMutation, UpdatePullInfoMutationVariables>;
export const PullsForDatabaseDocument = gql`
    query PullsForDatabase($ownerName: String!, $deploymentName: String!, $databaseName: String!, $pageToken: String, $filterByState: PullState, $query: String) {
  pulls(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    pageToken: $pageToken
    filterByState: $filterByState
    query: $query
  ) {
    ...PullListForPullList
  }
}
    ${PullListForPullListFragmentDoc}`;

/**
 * __usePullsForDatabaseQuery__
 *
 * To run a query within a React component, call `usePullsForDatabaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullsForDatabaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullsForDatabaseQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      pageToken: // value for 'pageToken'
 *      filterByState: // value for 'filterByState'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePullsForDatabaseQuery(baseOptions: Apollo.QueryHookOptions<PullsForDatabaseQuery, PullsForDatabaseQueryVariables> & ({ variables: PullsForDatabaseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>(PullsForDatabaseDocument, options);
      }
export function usePullsForDatabaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>(PullsForDatabaseDocument, options);
        }
export function usePullsForDatabaseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>(PullsForDatabaseDocument, options);
        }
export type PullsForDatabaseQueryHookResult = ReturnType<typeof usePullsForDatabaseQuery>;
export type PullsForDatabaseLazyQueryHookResult = ReturnType<typeof usePullsForDatabaseLazyQuery>;
export type PullsForDatabaseSuspenseQueryHookResult = ReturnType<typeof usePullsForDatabaseSuspenseQuery>;
export type PullsForDatabaseQueryResult = Apollo.QueryResult<PullsForDatabaseQuery, PullsForDatabaseQueryVariables>;
export const PullForPullPageDocument = gql`
    query PullForPullPage($deploymentName: String!, $databaseName: String!, $ownerName: String!, $pullId: String!) {
  pull(
    deploymentName: $deploymentName
    databaseName: $databaseName
    ownerName: $ownerName
    pullId: $pullId
  ) {
    _id
    ownerName
    deploymentName
    databaseName
  }
}
    `;

/**
 * __usePullForPullPageQuery__
 *
 * To run a query within a React component, call `usePullForPullPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullForPullPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullForPullPageQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      ownerName: // value for 'ownerName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function usePullForPullPageQuery(baseOptions: Apollo.QueryHookOptions<PullForPullPageQuery, PullForPullPageQueryVariables> & ({ variables: PullForPullPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullForPullPageQuery, PullForPullPageQueryVariables>(PullForPullPageDocument, options);
      }
export function usePullForPullPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullForPullPageQuery, PullForPullPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullForPullPageQuery, PullForPullPageQueryVariables>(PullForPullPageDocument, options);
        }
export function usePullForPullPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullForPullPageQuery, PullForPullPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullForPullPageQuery, PullForPullPageQueryVariables>(PullForPullPageDocument, options);
        }
export type PullForPullPageQueryHookResult = ReturnType<typeof usePullForPullPageQuery>;
export type PullForPullPageLazyQueryHookResult = ReturnType<typeof usePullForPullPageLazyQuery>;
export type PullForPullPageSuspenseQueryHookResult = ReturnType<typeof usePullForPullPageSuspenseQuery>;
export type PullForPullPageQueryResult = Apollo.QueryResult<PullForPullPageQuery, PullForPullPageQueryVariables>;
export const PullForDiffDocument = gql`
    query PullForDiff($ownerName: String!, $deploymentName: String!, $databaseName: String!, $pullId: String!) {
  pull(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    pullId: $pullId
  ) {
    ...PullForDiff
  }
}
    ${PullForDiffFragmentDoc}`;

/**
 * __usePullForDiffQuery__
 *
 * To run a query within a React component, call `usePullForDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullForDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullForDiffQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      pullId: // value for 'pullId'
 *   },
 * });
 */
export function usePullForDiffQuery(baseOptions: Apollo.QueryHookOptions<PullForDiffQuery, PullForDiffQueryVariables> & ({ variables: PullForDiffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullForDiffQuery, PullForDiffQueryVariables>(PullForDiffDocument, options);
      }
export function usePullForDiffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullForDiffQuery, PullForDiffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullForDiffQuery, PullForDiffQueryVariables>(PullForDiffDocument, options);
        }
export function usePullForDiffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PullForDiffQuery, PullForDiffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PullForDiffQuery, PullForDiffQueryVariables>(PullForDiffDocument, options);
        }
export type PullForDiffQueryHookResult = ReturnType<typeof usePullForDiffQuery>;
export type PullForDiffLazyQueryHookResult = ReturnType<typeof usePullForDiffLazyQuery>;
export type PullForDiffSuspenseQueryHookResult = ReturnType<typeof usePullForDiffSuspenseQuery>;
export type PullForDiffQueryResult = Apollo.QueryResult<PullForDiffQuery, PullForDiffQueryVariables>;
export const RefPageQueryDocument = gql`
    query RefPageQuery($refName: String!, $deploymentName: String!, $databaseName: String!, $ownerName: String!, $filterSystemTables: Boolean) {
  branch(
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    branchName: $refName
  ) {
    _id
  }
  tableNames(
    refName: $refName
    deploymentName: $deploymentName
    ownerName: $ownerName
    databaseName: $databaseName
    filterSystemTables: $filterSystemTables
  ) {
    list
  }
}
    `;

/**
 * __useRefPageQuery__
 *
 * To run a query within a React component, call `useRefPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefPageQuery({
 *   variables: {
 *      refName: // value for 'refName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      ownerName: // value for 'ownerName'
 *      filterSystemTables: // value for 'filterSystemTables'
 *   },
 * });
 */
export function useRefPageQuery(baseOptions: Apollo.QueryHookOptions<RefPageQuery, RefPageQueryVariables> & ({ variables: RefPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefPageQuery, RefPageQueryVariables>(RefPageQueryDocument, options);
      }
export function useRefPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefPageQuery, RefPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefPageQuery, RefPageQueryVariables>(RefPageQueryDocument, options);
        }
export function useRefPageQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RefPageQuery, RefPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefPageQuery, RefPageQueryVariables>(RefPageQueryDocument, options);
        }
export type RefPageQueryHookResult = ReturnType<typeof useRefPageQuery>;
export type RefPageQueryLazyQueryHookResult = ReturnType<typeof useRefPageQueryLazyQuery>;
export type RefPageQuerySuspenseQueryHookResult = ReturnType<typeof useRefPageQuerySuspenseQuery>;
export type RefPageQueryQueryResult = Apollo.QueryResult<RefPageQuery, RefPageQueryVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($ownerName: String!, $deploymentName: String!, $databaseName: String!, $tagName: String!, $message: String, $fromRefName: String!, $author: AuthorInfo) {
  createTag(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    tagName: $tagName
    message: $message
    fromRefName: $fromRefName
    author: $author
  ) {
    ...TagForList
  }
}
    ${TagForListFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      tagName: // value for 'tagName'
 *      message: // value for 'message'
 *      fromRefName: // value for 'fromRefName'
 *      author: // value for 'author'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const TagListDocument = gql`
    query TagList($ownerName: String!, $deploymentName: String!, $databaseName: String!) {
  tags(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  ) {
    ...TagListForTagList
  }
}
    ${TagListForTagListFragmentDoc}`;

/**
 * __useTagListQuery__
 *
 * To run a query within a React component, call `useTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useTagListQuery(baseOptions: Apollo.QueryHookOptions<TagListQuery, TagListQueryVariables> & ({ variables: TagListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
      }
export function useTagListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagListQuery, TagListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
        }
export function useTagListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagListQuery, TagListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
        }
export type TagListQueryHookResult = ReturnType<typeof useTagListQuery>;
export type TagListLazyQueryHookResult = ReturnType<typeof useTagListLazyQuery>;
export type TagListSuspenseQueryHookResult = ReturnType<typeof useTagListSuspenseQuery>;
export type TagListQueryResult = Apollo.QueryResult<TagListQuery, TagListQueryVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($deploymentName: String!, $databaseName: String!, $ownerName: String!, $tagName: String!) {
  deleteTag(
    deploymentName: $deploymentName
    databaseName: $databaseName
    ownerName: $ownerName
    tagName: $tagName
  )
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      ownerName: // value for 'ownerName'
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const BackupsDocument = gql`
    query Backups($ownerName: String!, $deploymentName: String!) {
  backups(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...Backup
  }
}
    ${BackupFragmentDoc}`;

/**
 * __useBackupsQuery__
 *
 * To run a query within a React component, call `useBackupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useBackupsQuery(baseOptions: Apollo.QueryHookOptions<BackupsQuery, BackupsQueryVariables> & ({ variables: BackupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackupsQuery, BackupsQueryVariables>(BackupsDocument, options);
      }
export function useBackupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackupsQuery, BackupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackupsQuery, BackupsQueryVariables>(BackupsDocument, options);
        }
export function useBackupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BackupsQuery, BackupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackupsQuery, BackupsQueryVariables>(BackupsDocument, options);
        }
export type BackupsQueryHookResult = ReturnType<typeof useBackupsQuery>;
export type BackupsLazyQueryHookResult = ReturnType<typeof useBackupsLazyQuery>;
export type BackupsSuspenseQueryHookResult = ReturnType<typeof useBackupsSuspenseQuery>;
export type BackupsQueryResult = Apollo.QueryResult<BackupsQuery, BackupsQueryVariables>;
export const CreateBackupDocument = gql`
    mutation CreateBackup($ownerName: String!, $deploymentName: String!) {
  createBackup(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;
export type CreateBackupMutationFn = Apollo.MutationFunction<CreateBackupMutation, CreateBackupMutationVariables>;

/**
 * __useCreateBackupMutation__
 *
 * To run a mutation, you first call `useCreateBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackupMutation, { data, loading, error }] = useCreateBackupMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useCreateBackupMutation(baseOptions?: Apollo.MutationHookOptions<CreateBackupMutation, CreateBackupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBackupMutation, CreateBackupMutationVariables>(CreateBackupDocument, options);
      }
export type CreateBackupMutationHookResult = ReturnType<typeof useCreateBackupMutation>;
export type CreateBackupMutationResult = Apollo.MutationResult<CreateBackupMutation>;
export type CreateBackupMutationOptions = Apollo.BaseMutationOptions<CreateBackupMutation, CreateBackupMutationVariables>;
export const UpdateDeploymentRemotesapiDocument = gql`
    mutation UpdateDeploymentRemotesapi($ownerName: String!, $deploymentName: String!, $exposeRemotesapiEndpoint: Boolean!) {
  updateRemotesapiEndpoint(
    ownerName: $ownerName
    deploymentName: $deploymentName
    exposeRemotesapiEndpoint: $exposeRemotesapiEndpoint
  )
}
    `;
export type UpdateDeploymentRemotesapiMutationFn = Apollo.MutationFunction<UpdateDeploymentRemotesapiMutation, UpdateDeploymentRemotesapiMutationVariables>;

/**
 * __useUpdateDeploymentRemotesapiMutation__
 *
 * To run a mutation, you first call `useUpdateDeploymentRemotesapiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeploymentRemotesapiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeploymentRemotesapiMutation, { data, loading, error }] = useUpdateDeploymentRemotesapiMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      exposeRemotesapiEndpoint: // value for 'exposeRemotesapiEndpoint'
 *   },
 * });
 */
export function useUpdateDeploymentRemotesapiMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeploymentRemotesapiMutation, UpdateDeploymentRemotesapiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeploymentRemotesapiMutation, UpdateDeploymentRemotesapiMutationVariables>(UpdateDeploymentRemotesapiDocument, options);
      }
export type UpdateDeploymentRemotesapiMutationHookResult = ReturnType<typeof useUpdateDeploymentRemotesapiMutation>;
export type UpdateDeploymentRemotesapiMutationResult = Apollo.MutationResult<UpdateDeploymentRemotesapiMutation>;
export type UpdateDeploymentRemotesapiMutationOptions = Apollo.BaseMutationOptions<UpdateDeploymentRemotesapiMutation, UpdateDeploymentRemotesapiMutationVariables>;
export const SupportedOverridesDocument = gql`
    query SupportedOverrides {
  supportedOverrides {
    list {
      ...SupportedOverrideItem
    }
  }
}
    ${SupportedOverrideItemFragmentDoc}`;

/**
 * __useSupportedOverridesQuery__
 *
 * To run a query within a React component, call `useSupportedOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedOverridesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportedOverridesQuery(baseOptions?: Apollo.QueryHookOptions<SupportedOverridesQuery, SupportedOverridesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedOverridesQuery, SupportedOverridesQueryVariables>(SupportedOverridesDocument, options);
      }
export function useSupportedOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedOverridesQuery, SupportedOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedOverridesQuery, SupportedOverridesQueryVariables>(SupportedOverridesDocument, options);
        }
export function useSupportedOverridesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupportedOverridesQuery, SupportedOverridesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupportedOverridesQuery, SupportedOverridesQueryVariables>(SupportedOverridesDocument, options);
        }
export type SupportedOverridesQueryHookResult = ReturnType<typeof useSupportedOverridesQuery>;
export type SupportedOverridesLazyQueryHookResult = ReturnType<typeof useSupportedOverridesLazyQuery>;
export type SupportedOverridesSuspenseQueryHookResult = ReturnType<typeof useSupportedOverridesSuspenseQuery>;
export type SupportedOverridesQueryResult = Apollo.QueryResult<SupportedOverridesQuery, SupportedOverridesQueryVariables>;
export const UpdateOverridesDocument = gql`
    mutation UpdateOverrides($deploymentName: String!, $ownerName: String!, $overrides: [OverrideInput!]!) {
  updateOverrides(
    deploymentName: $deploymentName
    ownerName: $ownerName
    overrides: $overrides
  )
}
    `;
export type UpdateOverridesMutationFn = Apollo.MutationFunction<UpdateOverridesMutation, UpdateOverridesMutationVariables>;

/**
 * __useUpdateOverridesMutation__
 *
 * To run a mutation, you first call `useUpdateOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOverridesMutation, { data, loading, error }] = useUpdateOverridesMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      overrides: // value for 'overrides'
 *   },
 * });
 */
export function useUpdateOverridesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOverridesMutation, UpdateOverridesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOverridesMutation, UpdateOverridesMutationVariables>(UpdateOverridesDocument, options);
      }
export type UpdateOverridesMutationHookResult = ReturnType<typeof useUpdateOverridesMutation>;
export type UpdateOverridesMutationResult = Apollo.MutationResult<UpdateOverridesMutation>;
export type UpdateOverridesMutationOptions = Apollo.BaseMutationOptions<UpdateOverridesMutation, UpdateOverridesMutationVariables>;
export const OverridesDocument = gql`
    query Overrides($deploymentName: String!, $ownerName: String!) {
  overrides(deploymentName: $deploymentName, ownerName: $ownerName) {
    list {
      ...OverrideItem
    }
  }
}
    ${OverrideItemFragmentDoc}`;

/**
 * __useOverridesQuery__
 *
 * To run a query within a React component, call `useOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverridesQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *   },
 * });
 */
export function useOverridesQuery(baseOptions: Apollo.QueryHookOptions<OverridesQuery, OverridesQueryVariables> & ({ variables: OverridesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverridesQuery, OverridesQueryVariables>(OverridesDocument, options);
      }
export function useOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverridesQuery, OverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverridesQuery, OverridesQueryVariables>(OverridesDocument, options);
        }
export function useOverridesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OverridesQuery, OverridesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OverridesQuery, OverridesQueryVariables>(OverridesDocument, options);
        }
export type OverridesQueryHookResult = ReturnType<typeof useOverridesQuery>;
export type OverridesLazyQueryHookResult = ReturnType<typeof useOverridesLazyQuery>;
export type OverridesSuspenseQueryHookResult = ReturnType<typeof useOverridesSuspenseQuery>;
export type OverridesQueryResult = Apollo.QueryResult<OverridesQuery, OverridesQueryVariables>;
export const UpdateDeploymentAllowedPeeringAccountIDsDocument = gql`
    mutation UpdateDeploymentAllowedPeeringAccountIDs($ownerName: String!, $deploymentName: String!, $allowedPeeringAccountIds: [String!]) {
  updateDeployment(
    ownerName: $ownerName
    deploymentName: $deploymentName
    allowedPeeringAccountIds: $allowedPeeringAccountIds
  ) {
    _id
  }
}
    `;
export type UpdateDeploymentAllowedPeeringAccountIDsMutationFn = Apollo.MutationFunction<UpdateDeploymentAllowedPeeringAccountIDsMutation, UpdateDeploymentAllowedPeeringAccountIDsMutationVariables>;

/**
 * __useUpdateDeploymentAllowedPeeringAccountIDsMutation__
 *
 * To run a mutation, you first call `useUpdateDeploymentAllowedPeeringAccountIDsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeploymentAllowedPeeringAccountIDsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeploymentAllowedPeeringAccountIDsMutation, { data, loading, error }] = useUpdateDeploymentAllowedPeeringAccountIDsMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      allowedPeeringAccountIds: // value for 'allowedPeeringAccountIds'
 *   },
 * });
 */
export function useUpdateDeploymentAllowedPeeringAccountIDsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeploymentAllowedPeeringAccountIDsMutation, UpdateDeploymentAllowedPeeringAccountIDsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeploymentAllowedPeeringAccountIDsMutation, UpdateDeploymentAllowedPeeringAccountIDsMutationVariables>(UpdateDeploymentAllowedPeeringAccountIDsDocument, options);
      }
export type UpdateDeploymentAllowedPeeringAccountIDsMutationHookResult = ReturnType<typeof useUpdateDeploymentAllowedPeeringAccountIDsMutation>;
export type UpdateDeploymentAllowedPeeringAccountIDsMutationResult = Apollo.MutationResult<UpdateDeploymentAllowedPeeringAccountIDsMutation>;
export type UpdateDeploymentAllowedPeeringAccountIDsMutationOptions = Apollo.BaseMutationOptions<UpdateDeploymentAllowedPeeringAccountIDsMutation, UpdateDeploymentAllowedPeeringAccountIDsMutationVariables>;
export const DeploymentInstancesDocument = gql`
    query DeploymentInstances($ownerName: String!, $deploymentName: String!) {
  deploymentInstances(ownerName: $ownerName, deploymentName: $deploymentName) {
    list {
      ...DepInstance
    }
  }
}
    ${DepInstanceFragmentDoc}`;

/**
 * __useDeploymentInstancesQuery__
 *
 * To run a query within a React component, call `useDeploymentInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentInstancesQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDeploymentInstancesQuery(baseOptions: Apollo.QueryHookOptions<DeploymentInstancesQuery, DeploymentInstancesQueryVariables> & ({ variables: DeploymentInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>(DeploymentInstancesDocument, options);
      }
export function useDeploymentInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>(DeploymentInstancesDocument, options);
        }
export function useDeploymentInstancesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>(DeploymentInstancesDocument, options);
        }
export type DeploymentInstancesQueryHookResult = ReturnType<typeof useDeploymentInstancesQuery>;
export type DeploymentInstancesLazyQueryHookResult = ReturnType<typeof useDeploymentInstancesLazyQuery>;
export type DeploymentInstancesSuspenseQueryHookResult = ReturnType<typeof useDeploymentInstancesSuspenseQuery>;
export type DeploymentInstancesQueryResult = Apollo.QueryResult<DeploymentInstancesQuery, DeploymentInstancesQueryVariables>;
export const GetLogsDocument = gql`
    query GetLogs($ownerName: String!, $deploymentName: String!, $nextPageToken: String, $prevPageToken: String, $startTime: Timestamp!, $endTime: Timestamp!, $numLines: Int!, $instanceID: String) {
  logs(
    ownerName: $ownerName
    deploymentName: $deploymentName
    nextPageToken: $nextPageToken
    prevPageToken: $prevPageToken
    startTime: $startTime
    endTime: $endTime
    numLines: $numLines
    instanceID: $instanceID
  ) {
    nextPageToken
    prevPageToken
    list {
      ...LogLine
    }
  }
}
    ${LogLineFragmentDoc}`;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      nextPageToken: // value for 'nextPageToken'
 *      prevPageToken: // value for 'prevPageToken'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      numLines: // value for 'numLines'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useGetLogsQuery(baseOptions: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables> & ({ variables: GetLogsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
      }
export function useGetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export function useGetLogsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsSuspenseQueryHookResult = ReturnType<typeof useGetLogsSuspenseQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<GetLogsQuery, GetLogsQueryVariables>;
export const GetDashboardsDocument = gql`
    query GetDashboards($ownerName: String!, $deploymentName: String!) {
  dashboards(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;

/**
 * __useGetDashboardsQuery__
 *
 * To run a query within a React component, call `useGetDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useGetDashboardsQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables> & ({ variables: GetDashboardsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(GetDashboardsDocument, options);
      }
export function useGetDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(GetDashboardsDocument, options);
        }
export function useGetDashboardsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(GetDashboardsDocument, options);
        }
export type GetDashboardsQueryHookResult = ReturnType<typeof useGetDashboardsQuery>;
export type GetDashboardsLazyQueryHookResult = ReturnType<typeof useGetDashboardsLazyQuery>;
export type GetDashboardsSuspenseQueryHookResult = ReturnType<typeof useGetDashboardsSuspenseQuery>;
export type GetDashboardsQueryResult = Apollo.QueryResult<GetDashboardsQuery, GetDashboardsQueryVariables>;
export const GetGraphsDocument = gql`
    query GetGraphs($ownerName: String!, $deploymentName: String!, $dashboardName: String!, $startTime: Timestamp!, $endTime: Timestamp!) {
  graphs(
    ownerName: $ownerName
    deploymentName: $deploymentName
    dashboardName: $dashboardName
    startTime: $startTime
    endTime: $endTime
  ) {
    ...Graph
  }
}
    ${GraphFragmentDoc}`;

/**
 * __useGetGraphsQuery__
 *
 * To run a query within a React component, call `useGetGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      dashboardName: // value for 'dashboardName'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetGraphsQuery(baseOptions: Apollo.QueryHookOptions<GetGraphsQuery, GetGraphsQueryVariables> & ({ variables: GetGraphsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphsQuery, GetGraphsQueryVariables>(GetGraphsDocument, options);
      }
export function useGetGraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphsQuery, GetGraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphsQuery, GetGraphsQueryVariables>(GetGraphsDocument, options);
        }
export function useGetGraphsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraphsQuery, GetGraphsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraphsQuery, GetGraphsQueryVariables>(GetGraphsDocument, options);
        }
export type GetGraphsQueryHookResult = ReturnType<typeof useGetGraphsQuery>;
export type GetGraphsLazyQueryHookResult = ReturnType<typeof useGetGraphsLazyQuery>;
export type GetGraphsSuspenseQueryHookResult = ReturnType<typeof useGetGraphsSuspenseQuery>;
export type GetGraphsQueryResult = Apollo.QueryResult<GetGraphsQuery, GetGraphsQueryVariables>;
export const GetGraphDataDocument = gql`
    query GetGraphData($ownerName: String!, $deploymentName: String!, $graphName: String!, $startTime: Timestamp!, $endTime: Timestamp!, $instanceID: String) {
  graphData(
    ownerName: $ownerName
    deploymentName: $deploymentName
    graphName: $graphName
    startTime: $startTime
    endTime: $endTime
    instanceID: $instanceID
  ) {
    ...GraphDataRes
  }
}
    ${GraphDataResFragmentDoc}`;

/**
 * __useGetGraphDataQuery__
 *
 * To run a query within a React component, call `useGetGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphDataQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      graphName: // value for 'graphName'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useGetGraphDataQuery(baseOptions: Apollo.QueryHookOptions<GetGraphDataQuery, GetGraphDataQueryVariables> & ({ variables: GetGraphDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphDataQuery, GetGraphDataQueryVariables>(GetGraphDataDocument, options);
      }
export function useGetGraphDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphDataQuery, GetGraphDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphDataQuery, GetGraphDataQueryVariables>(GetGraphDataDocument, options);
        }
export function useGetGraphDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraphDataQuery, GetGraphDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraphDataQuery, GetGraphDataQueryVariables>(GetGraphDataDocument, options);
        }
export type GetGraphDataQueryHookResult = ReturnType<typeof useGetGraphDataQuery>;
export type GetGraphDataLazyQueryHookResult = ReturnType<typeof useGetGraphDataLazyQuery>;
export type GetGraphDataSuspenseQueryHookResult = ReturnType<typeof useGetGraphDataSuspenseQuery>;
export type GetGraphDataQueryResult = Apollo.QueryResult<GetGraphDataQuery, GetGraphDataQueryVariables>;
export const DeploymentCredentialsDocument = gql`
    query DeploymentCredentials($ownerName: String!, $deploymentName: String!) {
  deploymentCreds(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...DepCreds
  }
}
    ${DepCredsFragmentDoc}`;

/**
 * __useDeploymentCredentialsQuery__
 *
 * To run a query within a React component, call `useDeploymentCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentCredentialsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDeploymentCredentialsQuery(baseOptions: Apollo.QueryHookOptions<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables> & ({ variables: DeploymentCredentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>(DeploymentCredentialsDocument, options);
      }
export function useDeploymentCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>(DeploymentCredentialsDocument, options);
        }
export function useDeploymentCredentialsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>(DeploymentCredentialsDocument, options);
        }
export type DeploymentCredentialsQueryHookResult = ReturnType<typeof useDeploymentCredentialsQuery>;
export type DeploymentCredentialsLazyQueryHookResult = ReturnType<typeof useDeploymentCredentialsLazyQuery>;
export type DeploymentCredentialsSuspenseQueryHookResult = ReturnType<typeof useDeploymentCredentialsSuspenseQuery>;
export type DeploymentCredentialsQueryResult = Apollo.QueryResult<DeploymentCredentialsQuery, DeploymentCredentialsQueryVariables>;
export const CreateDeploymentCredentialsDocument = gql`
    mutation CreateDeploymentCredentials($ownerName: String!, $deploymentName: String!) {
  createDeploymentCreds(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;
export type CreateDeploymentCredentialsMutationFn = Apollo.MutationFunction<CreateDeploymentCredentialsMutation, CreateDeploymentCredentialsMutationVariables>;

/**
 * __useCreateDeploymentCredentialsMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentCredentialsMutation, { data, loading, error }] = useCreateDeploymentCredentialsMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useCreateDeploymentCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentCredentialsMutation, CreateDeploymentCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentCredentialsMutation, CreateDeploymentCredentialsMutationVariables>(CreateDeploymentCredentialsDocument, options);
      }
export type CreateDeploymentCredentialsMutationHookResult = ReturnType<typeof useCreateDeploymentCredentialsMutation>;
export type CreateDeploymentCredentialsMutationResult = Apollo.MutationResult<CreateDeploymentCredentialsMutation>;
export type CreateDeploymentCredentialsMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentCredentialsMutation, CreateDeploymentCredentialsMutationVariables>;
export const DeleteDeploymentCredentialsDocument = gql`
    mutation DeleteDeploymentCredentials($ownerName: String!, $deploymentName: String!) {
  deleteDeploymentCreds(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;
export type DeleteDeploymentCredentialsMutationFn = Apollo.MutationFunction<DeleteDeploymentCredentialsMutation, DeleteDeploymentCredentialsMutationVariables>;

/**
 * __useDeleteDeploymentCredentialsMutation__
 *
 * To run a mutation, you first call `useDeleteDeploymentCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeploymentCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeploymentCredentialsMutation, { data, loading, error }] = useDeleteDeploymentCredentialsMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDeleteDeploymentCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeploymentCredentialsMutation, DeleteDeploymentCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeploymentCredentialsMutation, DeleteDeploymentCredentialsMutationVariables>(DeleteDeploymentCredentialsDocument, options);
      }
export type DeleteDeploymentCredentialsMutationHookResult = ReturnType<typeof useDeleteDeploymentCredentialsMutation>;
export type DeleteDeploymentCredentialsMutationResult = Apollo.MutationResult<DeleteDeploymentCredentialsMutation>;
export type DeleteDeploymentCredentialsMutationOptions = Apollo.BaseMutationOptions<DeleteDeploymentCredentialsMutation, DeleteDeploymentCredentialsMutationVariables>;
export const RerollDeploymentCredentialsDocument = gql`
    mutation RerollDeploymentCredentials($ownerName: String!, $deploymentName: String!) {
  rerollDeploymentCreds(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;
export type RerollDeploymentCredentialsMutationFn = Apollo.MutationFunction<RerollDeploymentCredentialsMutation, RerollDeploymentCredentialsMutationVariables>;

/**
 * __useRerollDeploymentCredentialsMutation__
 *
 * To run a mutation, you first call `useRerollDeploymentCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRerollDeploymentCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rerollDeploymentCredentialsMutation, { data, loading, error }] = useRerollDeploymentCredentialsMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useRerollDeploymentCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RerollDeploymentCredentialsMutation, RerollDeploymentCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RerollDeploymentCredentialsMutation, RerollDeploymentCredentialsMutationVariables>(RerollDeploymentCredentialsDocument, options);
      }
export type RerollDeploymentCredentialsMutationHookResult = ReturnType<typeof useRerollDeploymentCredentialsMutation>;
export type RerollDeploymentCredentialsMutationResult = Apollo.MutationResult<RerollDeploymentCredentialsMutation>;
export type RerollDeploymentCredentialsMutationOptions = Apollo.BaseMutationOptions<RerollDeploymentCredentialsMutation, RerollDeploymentCredentialsMutationVariables>;
export const ServiceWindowsDocument = gql`
    query ServiceWindows($ownerName: String!, $deploymentName: String!) {
  serviceWindows(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...ServiceWindow
  }
}
    ${ServiceWindowFragmentDoc}`;

/**
 * __useServiceWindowsQuery__
 *
 * To run a query within a React component, call `useServiceWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceWindowsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useServiceWindowsQuery(baseOptions: Apollo.QueryHookOptions<ServiceWindowsQuery, ServiceWindowsQueryVariables> & ({ variables: ServiceWindowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceWindowsQuery, ServiceWindowsQueryVariables>(ServiceWindowsDocument, options);
      }
export function useServiceWindowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceWindowsQuery, ServiceWindowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceWindowsQuery, ServiceWindowsQueryVariables>(ServiceWindowsDocument, options);
        }
export function useServiceWindowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ServiceWindowsQuery, ServiceWindowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceWindowsQuery, ServiceWindowsQueryVariables>(ServiceWindowsDocument, options);
        }
export type ServiceWindowsQueryHookResult = ReturnType<typeof useServiceWindowsQuery>;
export type ServiceWindowsLazyQueryHookResult = ReturnType<typeof useServiceWindowsLazyQuery>;
export type ServiceWindowsSuspenseQueryHookResult = ReturnType<typeof useServiceWindowsSuspenseQuery>;
export type ServiceWindowsQueryResult = Apollo.QueryResult<ServiceWindowsQuery, ServiceWindowsQueryVariables>;
export const UpdateServiceWindowDocument = gql`
    mutation UpdateServiceWindow($ownerName: String!, $deploymentName: String!, $serviceWindowID: String!, $startHourOfDay: Int!, $endHourOfDay: Int!, $dayOfWeek: Int!) {
  updateServiceWindow(
    ownerName: $ownerName
    deploymentName: $deploymentName
    serviceWindowID: $serviceWindowID
    startHourOfDay: $startHourOfDay
    endHourOfDay: $endHourOfDay
    dayOfWeek: $dayOfWeek
  ) {
    ...ServiceWindow
  }
}
    ${ServiceWindowFragmentDoc}`;
export type UpdateServiceWindowMutationFn = Apollo.MutationFunction<UpdateServiceWindowMutation, UpdateServiceWindowMutationVariables>;

/**
 * __useUpdateServiceWindowMutation__
 *
 * To run a mutation, you first call `useUpdateServiceWindowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceWindowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceWindowMutation, { data, loading, error }] = useUpdateServiceWindowMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      serviceWindowID: // value for 'serviceWindowID'
 *      startHourOfDay: // value for 'startHourOfDay'
 *      endHourOfDay: // value for 'endHourOfDay'
 *      dayOfWeek: // value for 'dayOfWeek'
 *   },
 * });
 */
export function useUpdateServiceWindowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceWindowMutation, UpdateServiceWindowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceWindowMutation, UpdateServiceWindowMutationVariables>(UpdateServiceWindowDocument, options);
      }
export type UpdateServiceWindowMutationHookResult = ReturnType<typeof useUpdateServiceWindowMutation>;
export type UpdateServiceWindowMutationResult = Apollo.MutationResult<UpdateServiceWindowMutation>;
export type UpdateServiceWindowMutationOptions = Apollo.BaseMutationOptions<UpdateServiceWindowMutation, UpdateServiceWindowMutationVariables>;
export const UpdateDisableAutomaticDoltUpdatesDocument = gql`
    mutation UpdateDisableAutomaticDoltUpdates($ownerName: String!, $deploymentName: String!, $disableAutomaticDoltUpdates: Boolean!) {
  updateDeployment(
    ownerName: $ownerName
    deploymentName: $deploymentName
    disableAutomaticDoltUpdates: $disableAutomaticDoltUpdates
  ) {
    ...Deployment
  }
}
    ${DeploymentFragmentDoc}`;
export type UpdateDisableAutomaticDoltUpdatesMutationFn = Apollo.MutationFunction<UpdateDisableAutomaticDoltUpdatesMutation, UpdateDisableAutomaticDoltUpdatesMutationVariables>;

/**
 * __useUpdateDisableAutomaticDoltUpdatesMutation__
 *
 * To run a mutation, you first call `useUpdateDisableAutomaticDoltUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisableAutomaticDoltUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisableAutomaticDoltUpdatesMutation, { data, loading, error }] = useUpdateDisableAutomaticDoltUpdatesMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      disableAutomaticDoltUpdates: // value for 'disableAutomaticDoltUpdates'
 *   },
 * });
 */
export function useUpdateDisableAutomaticDoltUpdatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisableAutomaticDoltUpdatesMutation, UpdateDisableAutomaticDoltUpdatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisableAutomaticDoltUpdatesMutation, UpdateDisableAutomaticDoltUpdatesMutationVariables>(UpdateDisableAutomaticDoltUpdatesDocument, options);
      }
export type UpdateDisableAutomaticDoltUpdatesMutationHookResult = ReturnType<typeof useUpdateDisableAutomaticDoltUpdatesMutation>;
export type UpdateDisableAutomaticDoltUpdatesMutationResult = Apollo.MutationResult<UpdateDisableAutomaticDoltUpdatesMutation>;
export type UpdateDisableAutomaticDoltUpdatesMutationOptions = Apollo.BaseMutationOptions<UpdateDisableAutomaticDoltUpdatesMutation, UpdateDisableAutomaticDoltUpdatesMutationVariables>;
export const CreateCollabDocument = gql`
    mutation CreateCollab($ownerName: String!, $deploymentName: String!, $collaboratorName: String!, $permLevel: CollabPermLevel!, $email: String) {
  createCollab(
    ownerName: $ownerName
    deploymentName: $deploymentName
    collaboratorName: $collaboratorName
    permLevel: $permLevel
    email: $email
  ) {
    ...DeploymentCollaborator
  }
}
    ${DeploymentCollaboratorFragmentDoc}`;
export type CreateCollabMutationFn = Apollo.MutationFunction<CreateCollabMutation, CreateCollabMutationVariables>;

/**
 * __useCreateCollabMutation__
 *
 * To run a mutation, you first call `useCreateCollabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollabMutation, { data, loading, error }] = useCreateCollabMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      collaboratorName: // value for 'collaboratorName'
 *      permLevel: // value for 'permLevel'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateCollabMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollabMutation, CreateCollabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollabMutation, CreateCollabMutationVariables>(CreateCollabDocument, options);
      }
export type CreateCollabMutationHookResult = ReturnType<typeof useCreateCollabMutation>;
export type CreateCollabMutationResult = Apollo.MutationResult<CreateCollabMutation>;
export type CreateCollabMutationOptions = Apollo.BaseMutationOptions<CreateCollabMutation, CreateCollabMutationVariables>;
export const TeamsForPermsDocument = gql`
    query TeamsForPerms($orgName: String!) {
  teams(orgName: $orgName) {
    list {
      ...TeamForTeamPerms
    }
  }
}
    ${TeamForTeamPermsFragmentDoc}`;

/**
 * __useTeamsForPermsQuery__
 *
 * To run a query within a React component, call `useTeamsForPermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForPermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForPermsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useTeamsForPermsQuery(baseOptions: Apollo.QueryHookOptions<TeamsForPermsQuery, TeamsForPermsQueryVariables> & ({ variables: TeamsForPermsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsForPermsQuery, TeamsForPermsQueryVariables>(TeamsForPermsDocument, options);
      }
export function useTeamsForPermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsForPermsQuery, TeamsForPermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsForPermsQuery, TeamsForPermsQueryVariables>(TeamsForPermsDocument, options);
        }
export function useTeamsForPermsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamsForPermsQuery, TeamsForPermsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamsForPermsQuery, TeamsForPermsQueryVariables>(TeamsForPermsDocument, options);
        }
export type TeamsForPermsQueryHookResult = ReturnType<typeof useTeamsForPermsQuery>;
export type TeamsForPermsLazyQueryHookResult = ReturnType<typeof useTeamsForPermsLazyQuery>;
export type TeamsForPermsSuspenseQueryHookResult = ReturnType<typeof useTeamsForPermsSuspenseQuery>;
export type TeamsForPermsQueryResult = Apollo.QueryResult<TeamsForPermsQuery, TeamsForPermsQueryVariables>;
export const CreateDepTeamPermDocument = gql`
    mutation CreateDepTeamPerm($ownerName: String!, $deploymentName: String!, $teamName: String!, $permLevel: TeamPermLevel!) {
  createDepTeamPerm(
    ownerName: $ownerName
    deploymentName: $deploymentName
    teamName: $teamName
    permLevel: $permLevel
  ) {
    ...DepTeamPerm
  }
}
    ${DepTeamPermFragmentDoc}`;
export type CreateDepTeamPermMutationFn = Apollo.MutationFunction<CreateDepTeamPermMutation, CreateDepTeamPermMutationVariables>;

/**
 * __useCreateDepTeamPermMutation__
 *
 * To run a mutation, you first call `useCreateDepTeamPermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepTeamPermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepTeamPermMutation, { data, loading, error }] = useCreateDepTeamPermMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      teamName: // value for 'teamName'
 *      permLevel: // value for 'permLevel'
 *   },
 * });
 */
export function useCreateDepTeamPermMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepTeamPermMutation, CreateDepTeamPermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepTeamPermMutation, CreateDepTeamPermMutationVariables>(CreateDepTeamPermDocument, options);
      }
export type CreateDepTeamPermMutationHookResult = ReturnType<typeof useCreateDepTeamPermMutation>;
export type CreateDepTeamPermMutationResult = Apollo.MutationResult<CreateDepTeamPermMutation>;
export type CreateDepTeamPermMutationOptions = Apollo.BaseMutationOptions<CreateDepTeamPermMutation, CreateDepTeamPermMutationVariables>;
export const ListCollabsDocument = gql`
    query ListCollabs($ownerName: String!, $deploymentName: String!, $pageToken: String) {
  collaborators(
    ownerName: $ownerName
    deploymentName: $deploymentName
    pageToken: $pageToken
  ) {
    list {
      ...DeploymentCollaborator
    }
    nextPageToken
  }
}
    ${DeploymentCollaboratorFragmentDoc}`;

/**
 * __useListCollabsQuery__
 *
 * To run a query within a React component, call `useListCollabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollabsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useListCollabsQuery(baseOptions: Apollo.QueryHookOptions<ListCollabsQuery, ListCollabsQueryVariables> & ({ variables: ListCollabsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCollabsQuery, ListCollabsQueryVariables>(ListCollabsDocument, options);
      }
export function useListCollabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCollabsQuery, ListCollabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCollabsQuery, ListCollabsQueryVariables>(ListCollabsDocument, options);
        }
export function useListCollabsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCollabsQuery, ListCollabsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCollabsQuery, ListCollabsQueryVariables>(ListCollabsDocument, options);
        }
export type ListCollabsQueryHookResult = ReturnType<typeof useListCollabsQuery>;
export type ListCollabsLazyQueryHookResult = ReturnType<typeof useListCollabsLazyQuery>;
export type ListCollabsSuspenseQueryHookResult = ReturnType<typeof useListCollabsSuspenseQuery>;
export type ListCollabsQueryResult = Apollo.QueryResult<ListCollabsQuery, ListCollabsQueryVariables>;
export const DeleteCollabDocument = gql`
    mutation DeleteCollab($ownerName: String!, $deploymentName: String!, $collaboratorName: String!, $email: String) {
  deleteCollab(
    ownerName: $ownerName
    deploymentName: $deploymentName
    collaboratorName: $collaboratorName
    email: $email
  )
}
    `;
export type DeleteCollabMutationFn = Apollo.MutationFunction<DeleteCollabMutation, DeleteCollabMutationVariables>;

/**
 * __useDeleteCollabMutation__
 *
 * To run a mutation, you first call `useDeleteCollabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollabMutation, { data, loading, error }] = useDeleteCollabMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      collaboratorName: // value for 'collaboratorName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteCollabMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollabMutation, DeleteCollabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollabMutation, DeleteCollabMutationVariables>(DeleteCollabDocument, options);
      }
export type DeleteCollabMutationHookResult = ReturnType<typeof useDeleteCollabMutation>;
export type DeleteCollabMutationResult = Apollo.MutationResult<DeleteCollabMutation>;
export type DeleteCollabMutationOptions = Apollo.BaseMutationOptions<DeleteCollabMutation, DeleteCollabMutationVariables>;
export const UpdateCollabDocument = gql`
    mutation UpdateCollab($ownerName: String!, $deploymentName: String!, $collaboratorName: String!, $permLevel: CollabPermLevel!) {
  updateCollab(
    ownerName: $ownerName
    deploymentName: $deploymentName
    collaboratorName: $collaboratorName
    permLevel: $permLevel
  ) {
    ...DeploymentCollaborator
  }
}
    ${DeploymentCollaboratorFragmentDoc}`;
export type UpdateCollabMutationFn = Apollo.MutationFunction<UpdateCollabMutation, UpdateCollabMutationVariables>;

/**
 * __useUpdateCollabMutation__
 *
 * To run a mutation, you first call `useUpdateCollabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollabMutation, { data, loading, error }] = useUpdateCollabMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      collaboratorName: // value for 'collaboratorName'
 *      permLevel: // value for 'permLevel'
 *   },
 * });
 */
export function useUpdateCollabMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollabMutation, UpdateCollabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollabMutation, UpdateCollabMutationVariables>(UpdateCollabDocument, options);
      }
export type UpdateCollabMutationHookResult = ReturnType<typeof useUpdateCollabMutation>;
export type UpdateCollabMutationResult = Apollo.MutationResult<UpdateCollabMutation>;
export type UpdateCollabMutationOptions = Apollo.BaseMutationOptions<UpdateCollabMutation, UpdateCollabMutationVariables>;
export const DepTeamPermsDocument = gql`
    query DepTeamPerms($ownerName: String!, $deploymentName: String!) {
  depTeamPerms(ownerName: $ownerName, deploymentName: $deploymentName) {
    list {
      ...DepTeamPerm
    }
  }
}
    ${DepTeamPermFragmentDoc}`;

/**
 * __useDepTeamPermsQuery__
 *
 * To run a query within a React component, call `useDepTeamPermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepTeamPermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepTeamPermsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDepTeamPermsQuery(baseOptions: Apollo.QueryHookOptions<DepTeamPermsQuery, DepTeamPermsQueryVariables> & ({ variables: DepTeamPermsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepTeamPermsQuery, DepTeamPermsQueryVariables>(DepTeamPermsDocument, options);
      }
export function useDepTeamPermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepTeamPermsQuery, DepTeamPermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepTeamPermsQuery, DepTeamPermsQueryVariables>(DepTeamPermsDocument, options);
        }
export function useDepTeamPermsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DepTeamPermsQuery, DepTeamPermsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepTeamPermsQuery, DepTeamPermsQueryVariables>(DepTeamPermsDocument, options);
        }
export type DepTeamPermsQueryHookResult = ReturnType<typeof useDepTeamPermsQuery>;
export type DepTeamPermsLazyQueryHookResult = ReturnType<typeof useDepTeamPermsLazyQuery>;
export type DepTeamPermsSuspenseQueryHookResult = ReturnType<typeof useDepTeamPermsSuspenseQuery>;
export type DepTeamPermsQueryResult = Apollo.QueryResult<DepTeamPermsQuery, DepTeamPermsQueryVariables>;
export const DeleteDepTeamPermDocument = gql`
    mutation DeleteDepTeamPerm($ownerName: String!, $deploymentName: String!, $teamName: String!) {
  deleteDepTeamPerm(
    ownerName: $ownerName
    deploymentName: $deploymentName
    teamName: $teamName
  )
}
    `;
export type DeleteDepTeamPermMutationFn = Apollo.MutationFunction<DeleteDepTeamPermMutation, DeleteDepTeamPermMutationVariables>;

/**
 * __useDeleteDepTeamPermMutation__
 *
 * To run a mutation, you first call `useDeleteDepTeamPermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepTeamPermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepTeamPermMutation, { data, loading, error }] = useDeleteDepTeamPermMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useDeleteDepTeamPermMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepTeamPermMutation, DeleteDepTeamPermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepTeamPermMutation, DeleteDepTeamPermMutationVariables>(DeleteDepTeamPermDocument, options);
      }
export type DeleteDepTeamPermMutationHookResult = ReturnType<typeof useDeleteDepTeamPermMutation>;
export type DeleteDepTeamPermMutationResult = Apollo.MutationResult<DeleteDepTeamPermMutation>;
export type DeleteDepTeamPermMutationOptions = Apollo.BaseMutationOptions<DeleteDepTeamPermMutation, DeleteDepTeamPermMutationVariables>;
export const UpdateDepTeamPermDocument = gql`
    mutation UpdateDepTeamPerm($ownerName: String!, $deploymentName: String!, $teamName: String!, $permLevel: TeamPermLevel!) {
  updateDepTeamPerm(
    ownerName: $ownerName
    deploymentName: $deploymentName
    teamName: $teamName
    permLevel: $permLevel
  ) {
    ...DepTeamPerm
  }
}
    ${DepTeamPermFragmentDoc}`;
export type UpdateDepTeamPermMutationFn = Apollo.MutationFunction<UpdateDepTeamPermMutation, UpdateDepTeamPermMutationVariables>;

/**
 * __useUpdateDepTeamPermMutation__
 *
 * To run a mutation, you first call `useUpdateDepTeamPermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepTeamPermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepTeamPermMutation, { data, loading, error }] = useUpdateDepTeamPermMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      teamName: // value for 'teamName'
 *      permLevel: // value for 'permLevel'
 *   },
 * });
 */
export function useUpdateDepTeamPermMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepTeamPermMutation, UpdateDepTeamPermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepTeamPermMutation, UpdateDepTeamPermMutationVariables>(UpdateDepTeamPermDocument, options);
      }
export type UpdateDepTeamPermMutationHookResult = ReturnType<typeof useUpdateDepTeamPermMutation>;
export type UpdateDepTeamPermMutationResult = Apollo.MutationResult<UpdateDepTeamPermMutation>;
export type UpdateDepTeamPermMutationOptions = Apollo.BaseMutationOptions<UpdateDepTeamPermMutation, UpdateDepTeamPermMutationVariables>;
export const DeploymentDatabasesDocument = gql`
    query DeploymentDatabases($ownerName: String!, $deploymentName: String!) {
  databases(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;

/**
 * __useDeploymentDatabasesQuery__
 *
 * To run a query within a React component, call `useDeploymentDatabasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentDatabasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentDatabasesQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDeploymentDatabasesQuery(baseOptions: Apollo.QueryHookOptions<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables> & ({ variables: DeploymentDatabasesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>(DeploymentDatabasesDocument, options);
      }
export function useDeploymentDatabasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>(DeploymentDatabasesDocument, options);
        }
export function useDeploymentDatabasesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>(DeploymentDatabasesDocument, options);
        }
export type DeploymentDatabasesQueryHookResult = ReturnType<typeof useDeploymentDatabasesQuery>;
export type DeploymentDatabasesLazyQueryHookResult = ReturnType<typeof useDeploymentDatabasesLazyQuery>;
export type DeploymentDatabasesSuspenseQueryHookResult = ReturnType<typeof useDeploymentDatabasesSuspenseQuery>;
export type DeploymentDatabasesQueryResult = Apollo.QueryResult<DeploymentDatabasesQuery, DeploymentDatabasesQueryVariables>;
export const CreateDatabaseDocument = gql`
    mutation CreateDatabase($ownerName: String!, $deploymentName: String!, $databaseName: String!) {
  createDatabase(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
  )
}
    `;
export type CreateDatabaseMutationFn = Apollo.MutationFunction<CreateDatabaseMutation, CreateDatabaseMutationVariables>;

/**
 * __useCreateDatabaseMutation__
 *
 * To run a mutation, you first call `useCreateDatabaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatabaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatabaseMutation, { data, loading, error }] = useCreateDatabaseMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *   },
 * });
 */
export function useCreateDatabaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateDatabaseMutation, CreateDatabaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDatabaseMutation, CreateDatabaseMutationVariables>(CreateDatabaseDocument, options);
      }
export type CreateDatabaseMutationHookResult = ReturnType<typeof useCreateDatabaseMutation>;
export type CreateDatabaseMutationResult = Apollo.MutationResult<CreateDatabaseMutation>;
export type CreateDatabaseMutationOptions = Apollo.BaseMutationOptions<CreateDatabaseMutation, CreateDatabaseMutationVariables>;
export const WorkbenchSettingsDocument = gql`
    query WorkbenchSettings($ownerName: String!, $deploymentName: String!) {
  workbenchSettings(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...WorkbenchSettings
  }
}
    ${WorkbenchSettingsFragmentDoc}`;

/**
 * __useWorkbenchSettingsQuery__
 *
 * To run a query within a React component, call `useWorkbenchSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkbenchSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkbenchSettingsQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useWorkbenchSettingsQuery(baseOptions: Apollo.QueryHookOptions<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables> & ({ variables: WorkbenchSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>(WorkbenchSettingsDocument, options);
      }
export function useWorkbenchSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>(WorkbenchSettingsDocument, options);
        }
export function useWorkbenchSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>(WorkbenchSettingsDocument, options);
        }
export type WorkbenchSettingsQueryHookResult = ReturnType<typeof useWorkbenchSettingsQuery>;
export type WorkbenchSettingsLazyQueryHookResult = ReturnType<typeof useWorkbenchSettingsLazyQuery>;
export type WorkbenchSettingsSuspenseQueryHookResult = ReturnType<typeof useWorkbenchSettingsSuspenseQuery>;
export type WorkbenchSettingsQueryResult = Apollo.QueryResult<WorkbenchSettingsQuery, WorkbenchSettingsQueryVariables>;
export const UpdateWorkbenchSettingsDocument = gql`
    mutation UpdateWorkbenchSettings($ownerName: String!, $deploymentName: String!, $enableWrites: Boolean, $deleteSourceBranchOnAcceptedMerge: Boolean) {
  updateWorkbenchSettings(
    ownerName: $ownerName
    deploymentName: $deploymentName
    enableWrites: $enableWrites
    deleteSourceBranchOnAcceptedMerge: $deleteSourceBranchOnAcceptedMerge
  ) {
    ...WorkbenchSettings
  }
}
    ${WorkbenchSettingsFragmentDoc}`;
export type UpdateWorkbenchSettingsMutationFn = Apollo.MutationFunction<UpdateWorkbenchSettingsMutation, UpdateWorkbenchSettingsMutationVariables>;

/**
 * __useUpdateWorkbenchSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWorkbenchSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkbenchSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkbenchSettingsMutation, { data, loading, error }] = useUpdateWorkbenchSettingsMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      enableWrites: // value for 'enableWrites'
 *      deleteSourceBranchOnAcceptedMerge: // value for 'deleteSourceBranchOnAcceptedMerge'
 *   },
 * });
 */
export function useUpdateWorkbenchSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkbenchSettingsMutation, UpdateWorkbenchSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkbenchSettingsMutation, UpdateWorkbenchSettingsMutationVariables>(UpdateWorkbenchSettingsDocument, options);
      }
export type UpdateWorkbenchSettingsMutationHookResult = ReturnType<typeof useUpdateWorkbenchSettingsMutation>;
export type UpdateWorkbenchSettingsMutationResult = Apollo.MutationResult<UpdateWorkbenchSettingsMutation>;
export type UpdateWorkbenchSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWorkbenchSettingsMutation, UpdateWorkbenchSettingsMutationVariables>;
export const DestroyDeploymentDocument = gql`
    mutation DestroyDeployment($ownerName: String!, $deploymentName: String!) {
  destroyDeployment(ownerName: $ownerName, deploymentName: $deploymentName)
}
    `;
export type DestroyDeploymentMutationFn = Apollo.MutationFunction<DestroyDeploymentMutation, DestroyDeploymentMutationVariables>;

/**
 * __useDestroyDeploymentMutation__
 *
 * To run a mutation, you first call `useDestroyDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyDeploymentMutation, { data, loading, error }] = useDestroyDeploymentMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useDestroyDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<DestroyDeploymentMutation, DestroyDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyDeploymentMutation, DestroyDeploymentMutationVariables>(DestroyDeploymentDocument, options);
      }
export type DestroyDeploymentMutationHookResult = ReturnType<typeof useDestroyDeploymentMutation>;
export type DestroyDeploymentMutationResult = Apollo.MutationResult<DestroyDeploymentMutation>;
export type DestroyDeploymentMutationOptions = Apollo.BaseMutationOptions<DestroyDeploymentMutation, DestroyDeploymentMutationVariables>;
export const UpdateDoltDocument = gql`
    mutation UpdateDolt($ownerName: String!, $deploymentName: String!, $desiredVersion: String!) {
  updateDolt(
    ownerName: $ownerName
    deploymentName: $deploymentName
    desiredVersion: $desiredVersion
  )
}
    `;
export type UpdateDoltMutationFn = Apollo.MutationFunction<UpdateDoltMutation, UpdateDoltMutationVariables>;

/**
 * __useUpdateDoltMutation__
 *
 * To run a mutation, you first call `useUpdateDoltMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoltMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoltMutation, { data, loading, error }] = useUpdateDoltMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      desiredVersion: // value for 'desiredVersion'
 *   },
 * });
 */
export function useUpdateDoltMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDoltMutation, UpdateDoltMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDoltMutation, UpdateDoltMutationVariables>(UpdateDoltDocument, options);
      }
export type UpdateDoltMutationHookResult = ReturnType<typeof useUpdateDoltMutation>;
export type UpdateDoltMutationResult = Apollo.MutationResult<UpdateDoltMutation>;
export type UpdateDoltMutationOptions = Apollo.BaseMutationOptions<UpdateDoltMutation, UpdateDoltMutationVariables>;
export const ListDoltVersionsDocument = gql`
    query ListDoltVersions($clusterType: ClusterType) {
  doltVersions(clusterType: $clusterType)
}
    `;

/**
 * __useListDoltVersionsQuery__
 *
 * To run a query within a React component, call `useListDoltVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDoltVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDoltVersionsQuery({
 *   variables: {
 *      clusterType: // value for 'clusterType'
 *   },
 * });
 */
export function useListDoltVersionsQuery(baseOptions?: Apollo.QueryHookOptions<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>(ListDoltVersionsDocument, options);
      }
export function useListDoltVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>(ListDoltVersionsDocument, options);
        }
export function useListDoltVersionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>(ListDoltVersionsDocument, options);
        }
export type ListDoltVersionsQueryHookResult = ReturnType<typeof useListDoltVersionsQuery>;
export type ListDoltVersionsLazyQueryHookResult = ReturnType<typeof useListDoltVersionsLazyQuery>;
export type ListDoltVersionsSuspenseQueryHookResult = ReturnType<typeof useListDoltVersionsSuspenseQuery>;
export type ListDoltVersionsQueryResult = Apollo.QueryResult<ListDoltVersionsQuery, ListDoltVersionsQueryVariables>;
export const RebootInstanceDocument = gql`
    mutation RebootInstance($ownerName: String!, $deploymentName: String!, $instanceID: String!) {
  rebootInstance(
    ownerName: $ownerName
    deploymentName: $deploymentName
    instanceID: $instanceID
  )
}
    `;
export type RebootInstanceMutationFn = Apollo.MutationFunction<RebootInstanceMutation, RebootInstanceMutationVariables>;

/**
 * __useRebootInstanceMutation__
 *
 * To run a mutation, you first call `useRebootInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebootInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebootInstanceMutation, { data, loading, error }] = useRebootInstanceMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useRebootInstanceMutation(baseOptions?: Apollo.MutationHookOptions<RebootInstanceMutation, RebootInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebootInstanceMutation, RebootInstanceMutationVariables>(RebootInstanceDocument, options);
      }
export type RebootInstanceMutationHookResult = ReturnType<typeof useRebootInstanceMutation>;
export type RebootInstanceMutationResult = Apollo.MutationResult<RebootInstanceMutation>;
export type RebootInstanceMutationOptions = Apollo.BaseMutationOptions<RebootInstanceMutation, RebootInstanceMutationVariables>;
export const RestartDoltDocument = gql`
    mutation RestartDolt($ownerName: String!, $deploymentName: String!, $instanceID: String!) {
  restartDolt(
    ownerName: $ownerName
    deploymentName: $deploymentName
    instanceID: $instanceID
  )
}
    `;
export type RestartDoltMutationFn = Apollo.MutationFunction<RestartDoltMutation, RestartDoltMutationVariables>;

/**
 * __useRestartDoltMutation__
 *
 * To run a mutation, you first call `useRestartDoltMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartDoltMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartDoltMutation, { data, loading, error }] = useRestartDoltMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useRestartDoltMutation(baseOptions?: Apollo.MutationHookOptions<RestartDoltMutation, RestartDoltMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartDoltMutation, RestartDoltMutationVariables>(RestartDoltDocument, options);
      }
export type RestartDoltMutationHookResult = ReturnType<typeof useRestartDoltMutation>;
export type RestartDoltMutationResult = Apollo.MutationResult<RestartDoltMutation>;
export type RestartDoltMutationOptions = Apollo.BaseMutationOptions<RestartDoltMutation, RestartDoltMutationVariables>;
export const GetDeploymentDocument = gql`
    query GetDeployment($ownerName: String!, $deploymentName: String!) {
  deployment(ownerName: $ownerName, deploymentName: $deploymentName) {
    ...Deployment
  }
}
    ${DeploymentFragmentDoc}`;

/**
 * __useGetDeploymentQuery__
 *
 * To run a query within a React component, call `useGetDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeploymentQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *   },
 * });
 */
export function useGetDeploymentQuery(baseOptions: Apollo.QueryHookOptions<GetDeploymentQuery, GetDeploymentQueryVariables> & ({ variables: GetDeploymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(GetDeploymentDocument, options);
      }
export function useGetDeploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeploymentQuery, GetDeploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(GetDeploymentDocument, options);
        }
export function useGetDeploymentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeploymentQuery, GetDeploymentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(GetDeploymentDocument, options);
        }
export type GetDeploymentQueryHookResult = ReturnType<typeof useGetDeploymentQuery>;
export type GetDeploymentLazyQueryHookResult = ReturnType<typeof useGetDeploymentLazyQuery>;
export type GetDeploymentSuspenseQueryHookResult = ReturnType<typeof useGetDeploymentSuspenseQuery>;
export type GetDeploymentQueryResult = Apollo.QueryResult<GetDeploymentQuery, GetDeploymentQueryVariables>;
export const ChangePrimaryDocument = gql`
    mutation ChangePrimary($ownerName: String!, $deploymentName: String!, $instanceID: String!) {
  changePrimary(
    ownerName: $ownerName
    deploymentName: $deploymentName
    instanceID: $instanceID
  )
}
    `;
export type ChangePrimaryMutationFn = Apollo.MutationFunction<ChangePrimaryMutation, ChangePrimaryMutationVariables>;

/**
 * __useChangePrimaryMutation__
 *
 * To run a mutation, you first call `useChangePrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePrimaryMutation, { data, loading, error }] = useChangePrimaryMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useChangePrimaryMutation(baseOptions?: Apollo.MutationHookOptions<ChangePrimaryMutation, ChangePrimaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePrimaryMutation, ChangePrimaryMutationVariables>(ChangePrimaryDocument, options);
      }
export type ChangePrimaryMutationHookResult = ReturnType<typeof useChangePrimaryMutation>;
export type ChangePrimaryMutationResult = Apollo.MutationResult<ChangePrimaryMutation>;
export type ChangePrimaryMutationOptions = Apollo.BaseMutationOptions<ChangePrimaryMutation, ChangePrimaryMutationVariables>;
export const DeleteInstanceDocument = gql`
    mutation DeleteInstance($ownerName: String!, $deploymentName: String!, $instanceID: String!) {
  deleteInstance(
    ownerName: $ownerName
    deploymentName: $deploymentName
    instanceID: $instanceID
  )
}
    `;
export type DeleteInstanceMutationFn = Apollo.MutationFunction<DeleteInstanceMutation, DeleteInstanceMutationVariables>;

/**
 * __useDeleteInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstanceMutation, { data, loading, error }] = useDeleteInstanceMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      instanceID: // value for 'instanceID'
 *   },
 * });
 */
export function useDeleteInstanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstanceMutation, DeleteInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstanceMutation, DeleteInstanceMutationVariables>(DeleteInstanceDocument, options);
      }
export type DeleteInstanceMutationHookResult = ReturnType<typeof useDeleteInstanceMutation>;
export type DeleteInstanceMutationResult = Apollo.MutationResult<DeleteInstanceMutation>;
export type DeleteInstanceMutationOptions = Apollo.BaseMutationOptions<DeleteInstanceMutation, DeleteInstanceMutationVariables>;
export const AddInstanceDocument = gql`
    mutation AddInstance($ownerName: String!, $deploymentName: String!, $instanceTypeId: String!, $volumeTypeId: String!, $volumeSize: Int!, $backupName: BackupName) {
  addInstance(
    ownerName: $ownerName
    deploymentName: $deploymentName
    instanceTypeId: $instanceTypeId
    volumeTypeId: $volumeTypeId
    volumeSize: $volumeSize
    backupName: $backupName
  )
}
    `;
export type AddInstanceMutationFn = Apollo.MutationFunction<AddInstanceMutation, AddInstanceMutationVariables>;

/**
 * __useAddInstanceMutation__
 *
 * To run a mutation, you first call `useAddInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInstanceMutation, { data, loading, error }] = useAddInstanceMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      instanceTypeId: // value for 'instanceTypeId'
 *      volumeTypeId: // value for 'volumeTypeId'
 *      volumeSize: // value for 'volumeSize'
 *      backupName: // value for 'backupName'
 *   },
 * });
 */
export function useAddInstanceMutation(baseOptions?: Apollo.MutationHookOptions<AddInstanceMutation, AddInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInstanceMutation, AddInstanceMutationVariables>(AddInstanceDocument, options);
      }
export type AddInstanceMutationHookResult = ReturnType<typeof useAddInstanceMutation>;
export type AddInstanceMutationResult = Apollo.MutationResult<AddInstanceMutation>;
export type AddInstanceMutationOptions = Apollo.BaseMutationOptions<AddInstanceMutation, AddInstanceMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($username: String!, $emailAddress: String!, $verifyToken: String!) {
  verifyEmail(
    username: $username
    emailAddress: $emailAddress
    verifyToken: $verifyToken
  ) {
    _id
    username
    address
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *      verifyToken: // value for 'verifyToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const LoadDataDocument = gql`
    mutation LoadData($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $tableName: String!, $importOp: ImportOperation!, $fileType: FileType!, $file: Upload!, $modifier: LoadDataModifier) {
  loadDataFile(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    tableName: $tableName
    importOp: $importOp
    fileType: $fileType
    file: $file
    modifier: $modifier
  )
}
    `;
export type LoadDataMutationFn = Apollo.MutationFunction<LoadDataMutation, LoadDataMutationVariables>;

/**
 * __useLoadDataMutation__
 *
 * To run a mutation, you first call `useLoadDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadDataMutation, { data, loading, error }] = useLoadDataMutation({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      tableName: // value for 'tableName'
 *      importOp: // value for 'importOp'
 *      fileType: // value for 'fileType'
 *      file: // value for 'file'
 *      modifier: // value for 'modifier'
 *   },
 * });
 */
export function useLoadDataMutation(baseOptions?: Apollo.MutationHookOptions<LoadDataMutation, LoadDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadDataMutation, LoadDataMutationVariables>(LoadDataDocument, options);
      }
export type LoadDataMutationHookResult = ReturnType<typeof useLoadDataMutation>;
export type LoadDataMutationResult = Apollo.MutationResult<LoadDataMutation>;
export type LoadDataMutationOptions = Apollo.BaseMutationOptions<LoadDataMutation, LoadDataMutationVariables>;
export const UpdateOrgDocument = gql`
    mutation UpdateOrg($orgName: String!, $billingEmail: String!, $displayName: String!) {
  updateOrg(
    orgName: $orgName
    billingEmail: $billingEmail
    displayName: $displayName
  ) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type UpdateOrgMutationFn = Apollo.MutationFunction<UpdateOrgMutation, UpdateOrgMutationVariables>;

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      billingEmail: // value for 'billingEmail'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpdateOrgMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgMutation, UpdateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(UpdateOrgDocument, options);
      }
export type UpdateOrgMutationHookResult = ReturnType<typeof useUpdateOrgMutation>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<UpdateOrgMutation, UpdateOrgMutationVariables>;
export const DeleteOrgDocument = gql`
    mutation DeleteOrg($orgName: String!) {
  deleteOrg(orgName: $orgName)
}
    `;
export type DeleteOrgMutationFn = Apollo.MutationFunction<DeleteOrgMutation, DeleteOrgMutationVariables>;

/**
 * __useDeleteOrgMutation__
 *
 * To run a mutation, you first call `useDeleteOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgMutation, { data, loading, error }] = useDeleteOrgMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useDeleteOrgMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgMutation, DeleteOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgMutation, DeleteOrgMutationVariables>(DeleteOrgDocument, options);
      }
export type DeleteOrgMutationHookResult = ReturnType<typeof useDeleteOrgMutation>;
export type DeleteOrgMutationResult = Apollo.MutationResult<DeleteOrgMutation>;
export type DeleteOrgMutationOptions = Apollo.BaseMutationOptions<DeleteOrgMutation, DeleteOrgMutationVariables>;
export const OrgMembersDocument = gql`
    query OrgMembers($orgName: String!) {
  orgMembers(orgName: $orgName) {
    ...OrgMember
  }
}
    ${OrgMemberFragmentDoc}`;

/**
 * __useOrgMembersQuery__
 *
 * To run a query within a React component, call `useOrgMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMembersQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrgMembersQuery(baseOptions: Apollo.QueryHookOptions<OrgMembersQuery, OrgMembersQueryVariables> & ({ variables: OrgMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgMembersQuery, OrgMembersQueryVariables>(OrgMembersDocument, options);
      }
export function useOrgMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgMembersQuery, OrgMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgMembersQuery, OrgMembersQueryVariables>(OrgMembersDocument, options);
        }
export function useOrgMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgMembersQuery, OrgMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgMembersQuery, OrgMembersQueryVariables>(OrgMembersDocument, options);
        }
export type OrgMembersQueryHookResult = ReturnType<typeof useOrgMembersQuery>;
export type OrgMembersLazyQueryHookResult = ReturnType<typeof useOrgMembersLazyQuery>;
export type OrgMembersSuspenseQueryHookResult = ReturnType<typeof useOrgMembersSuspenseQuery>;
export type OrgMembersQueryResult = Apollo.QueryResult<OrgMembersQuery, OrgMembersQueryVariables>;
export const CreateOrgMemberDocument = gql`
    mutation CreateOrgMember($orgName: String!, $role: OrgRole!, $newMember: String, $emailAddress: String) {
  createOrgMember(
    orgName: $orgName
    newMember: $newMember
    emailAddress: $emailAddress
    role: $role
  ) {
    ...OrgMember
  }
}
    ${OrgMemberFragmentDoc}`;
export type CreateOrgMemberMutationFn = Apollo.MutationFunction<CreateOrgMemberMutation, CreateOrgMemberMutationVariables>;

/**
 * __useCreateOrgMemberMutation__
 *
 * To run a mutation, you first call `useCreateOrgMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMemberMutation, { data, loading, error }] = useCreateOrgMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      role: // value for 'role'
 *      newMember: // value for 'newMember'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCreateOrgMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrgMemberMutation, CreateOrgMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrgMemberMutation, CreateOrgMemberMutationVariables>(CreateOrgMemberDocument, options);
      }
export type CreateOrgMemberMutationHookResult = ReturnType<typeof useCreateOrgMemberMutation>;
export type CreateOrgMemberMutationResult = Apollo.MutationResult<CreateOrgMemberMutation>;
export type CreateOrgMemberMutationOptions = Apollo.BaseMutationOptions<CreateOrgMemberMutation, CreateOrgMemberMutationVariables>;
export const DeleteOrgMemberDocument = gql`
    mutation DeleteOrgMember($orgName: String!, $username: String, $emailAddress: String) {
  deleteOrgMember(
    orgName: $orgName
    username: $username
    emailAddress: $emailAddress
  )
}
    `;
export type DeleteOrgMemberMutationFn = Apollo.MutationFunction<DeleteOrgMemberMutation, DeleteOrgMemberMutationVariables>;

/**
 * __useDeleteOrgMemberMutation__
 *
 * To run a mutation, you first call `useDeleteOrgMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgMemberMutation, { data, loading, error }] = useDeleteOrgMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useDeleteOrgMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgMemberMutation, DeleteOrgMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgMemberMutation, DeleteOrgMemberMutationVariables>(DeleteOrgMemberDocument, options);
      }
export type DeleteOrgMemberMutationHookResult = ReturnType<typeof useDeleteOrgMemberMutation>;
export type DeleteOrgMemberMutationResult = Apollo.MutationResult<DeleteOrgMemberMutation>;
export type DeleteOrgMemberMutationOptions = Apollo.BaseMutationOptions<DeleteOrgMemberMutation, DeleteOrgMemberMutationVariables>;
export const UpdateOrgMemberDocument = gql`
    mutation UpdateOrgMember($orgName: String!, $username: String!, $role: OrgRole!) {
  updateOrgMember(orgName: $orgName, username: $username, role: $role) {
    ...OrgMember
  }
}
    ${OrgMemberFragmentDoc}`;
export type UpdateOrgMemberMutationFn = Apollo.MutationFunction<UpdateOrgMemberMutation, UpdateOrgMemberMutationVariables>;

/**
 * __useUpdateOrgMemberMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMemberMutation, { data, loading, error }] = useUpdateOrgMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      username: // value for 'username'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateOrgMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgMemberMutation, UpdateOrgMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgMemberMutation, UpdateOrgMemberMutationVariables>(UpdateOrgMemberDocument, options);
      }
export type UpdateOrgMemberMutationHookResult = ReturnType<typeof useUpdateOrgMemberMutation>;
export type UpdateOrgMemberMutationResult = Apollo.MutationResult<UpdateOrgMemberMutation>;
export type UpdateOrgMemberMutationOptions = Apollo.BaseMutationOptions<UpdateOrgMemberMutation, UpdateOrgMemberMutationVariables>;
export const CreateOrgIdentityProviderDocument = gql`
    mutation CreateOrgIdentityProvider($orgName: String!, $metadataDescriptor: String!) {
  createOrgIdentityProvider(
    orgName: $orgName
    metadataDescriptor: $metadataDescriptor
  ) {
    ...OrgIdentityProviderFragment
  }
}
    ${OrgIdentityProviderFragmentDoc}`;
export type CreateOrgIdentityProviderMutationFn = Apollo.MutationFunction<CreateOrgIdentityProviderMutation, CreateOrgIdentityProviderMutationVariables>;

/**
 * __useCreateOrgIdentityProviderMutation__
 *
 * To run a mutation, you first call `useCreateOrgIdentityProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgIdentityProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgIdentityProviderMutation, { data, loading, error }] = useCreateOrgIdentityProviderMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      metadataDescriptor: // value for 'metadataDescriptor'
 *   },
 * });
 */
export function useCreateOrgIdentityProviderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrgIdentityProviderMutation, CreateOrgIdentityProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrgIdentityProviderMutation, CreateOrgIdentityProviderMutationVariables>(CreateOrgIdentityProviderDocument, options);
      }
export type CreateOrgIdentityProviderMutationHookResult = ReturnType<typeof useCreateOrgIdentityProviderMutation>;
export type CreateOrgIdentityProviderMutationResult = Apollo.MutationResult<CreateOrgIdentityProviderMutation>;
export type CreateOrgIdentityProviderMutationOptions = Apollo.BaseMutationOptions<CreateOrgIdentityProviderMutation, CreateOrgIdentityProviderMutationVariables>;
export const DeleteOrgIdentityProviderDocument = gql`
    mutation DeleteOrgIdentityProvider($orgName: String!) {
  deleteOrgIdentityProvider(orgName: $orgName)
}
    `;
export type DeleteOrgIdentityProviderMutationFn = Apollo.MutationFunction<DeleteOrgIdentityProviderMutation, DeleteOrgIdentityProviderMutationVariables>;

/**
 * __useDeleteOrgIdentityProviderMutation__
 *
 * To run a mutation, you first call `useDeleteOrgIdentityProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgIdentityProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgIdentityProviderMutation, { data, loading, error }] = useDeleteOrgIdentityProviderMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useDeleteOrgIdentityProviderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgIdentityProviderMutation, DeleteOrgIdentityProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgIdentityProviderMutation, DeleteOrgIdentityProviderMutationVariables>(DeleteOrgIdentityProviderDocument, options);
      }
export type DeleteOrgIdentityProviderMutationHookResult = ReturnType<typeof useDeleteOrgIdentityProviderMutation>;
export type DeleteOrgIdentityProviderMutationResult = Apollo.MutationResult<DeleteOrgIdentityProviderMutation>;
export type DeleteOrgIdentityProviderMutationOptions = Apollo.BaseMutationOptions<DeleteOrgIdentityProviderMutation, DeleteOrgIdentityProviderMutationVariables>;
export const UpdateOrgIdentityProviderDocument = gql`
    mutation UpdateOrgIdentityProvider($orgName: String!, $metadataDescriptor: String!) {
  updateOrgIdentityProvider(
    orgName: $orgName
    metadataDescriptor: $metadataDescriptor
  ) {
    ...OrgIdentityProviderFragment
  }
}
    ${OrgIdentityProviderFragmentDoc}`;
export type UpdateOrgIdentityProviderMutationFn = Apollo.MutationFunction<UpdateOrgIdentityProviderMutation, UpdateOrgIdentityProviderMutationVariables>;

/**
 * __useUpdateOrgIdentityProviderMutation__
 *
 * To run a mutation, you first call `useUpdateOrgIdentityProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgIdentityProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgIdentityProviderMutation, { data, loading, error }] = useUpdateOrgIdentityProviderMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      metadataDescriptor: // value for 'metadataDescriptor'
 *   },
 * });
 */
export function useUpdateOrgIdentityProviderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgIdentityProviderMutation, UpdateOrgIdentityProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgIdentityProviderMutation, UpdateOrgIdentityProviderMutationVariables>(UpdateOrgIdentityProviderDocument, options);
      }
export type UpdateOrgIdentityProviderMutationHookResult = ReturnType<typeof useUpdateOrgIdentityProviderMutation>;
export type UpdateOrgIdentityProviderMutationResult = Apollo.MutationResult<UpdateOrgIdentityProviderMutation>;
export type UpdateOrgIdentityProviderMutationOptions = Apollo.BaseMutationOptions<UpdateOrgIdentityProviderMutation, UpdateOrgIdentityProviderMutationVariables>;
export const OrgIdentityProviderDocument = gql`
    query OrgIdentityProvider($orgName: String!, $includeDescriptor: Boolean) {
  orgIdentityProvider(orgName: $orgName, includeDescriptor: $includeDescriptor) {
    ...GetOrgIdentityProviderRes
  }
}
    ${GetOrgIdentityProviderResFragmentDoc}`;

/**
 * __useOrgIdentityProviderQuery__
 *
 * To run a query within a React component, call `useOrgIdentityProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgIdentityProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgIdentityProviderQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      includeDescriptor: // value for 'includeDescriptor'
 *   },
 * });
 */
export function useOrgIdentityProviderQuery(baseOptions: Apollo.QueryHookOptions<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables> & ({ variables: OrgIdentityProviderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>(OrgIdentityProviderDocument, options);
      }
export function useOrgIdentityProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>(OrgIdentityProviderDocument, options);
        }
export function useOrgIdentityProviderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>(OrgIdentityProviderDocument, options);
        }
export type OrgIdentityProviderQueryHookResult = ReturnType<typeof useOrgIdentityProviderQuery>;
export type OrgIdentityProviderLazyQueryHookResult = ReturnType<typeof useOrgIdentityProviderLazyQuery>;
export type OrgIdentityProviderSuspenseQueryHookResult = ReturnType<typeof useOrgIdentityProviderSuspenseQuery>;
export type OrgIdentityProviderQueryResult = Apollo.QueryResult<OrgIdentityProviderQuery, OrgIdentityProviderQueryVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($orgName: String!, $teamName: String!) {
  deleteTeam(orgName: $orgName, teamName: $teamName)
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($orgName: String!, $teamName: String!, $description: String!, $callerRole: TeamRole!) {
  updateTeam(
    orgName: $orgName
    teamName: $teamName
    description: $description
    callerRole: $callerRole
  ) {
    ...TeamForOrg
  }
}
    ${TeamForOrgFragmentDoc}`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *      description: // value for 'description'
 *      callerRole: // value for 'callerRole'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const CreateTeamMemberDocument = gql`
    mutation CreateTeamMember($orgName: String!, $teamName: String!, $memberName: String!, $role: TeamRole!) {
  createTeamMember(
    orgName: $orgName
    teamName: $teamName
    memberName: $memberName
    role: $role
  ) {
    ...TeamMemberForOrg
  }
}
    ${TeamMemberForOrgFragmentDoc}`;
export type CreateTeamMemberMutationFn = Apollo.MutationFunction<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;

/**
 * __useCreateTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMemberMutation, { data, loading, error }] = useCreateTeamMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *      memberName: // value for 'memberName'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>(CreateTeamMemberDocument, options);
      }
export type CreateTeamMemberMutationHookResult = ReturnType<typeof useCreateTeamMemberMutation>;
export type CreateTeamMemberMutationResult = Apollo.MutationResult<CreateTeamMemberMutation>;
export type CreateTeamMemberMutationOptions = Apollo.BaseMutationOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($orgName: String!, $teamName: String!, $memberName: String!) {
  deleteTeamMember(
    orgName: $orgName
    teamName: $teamName
    memberName: $memberName
  )
}
    `;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *      memberName: // value for 'memberName'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, options);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($orgName: String!, $teamName: String!, $memberName: String!, $role: TeamRole!) {
  updateTeamMember(
    orgName: $orgName
    teamName: $teamName
    memberName: $memberName
    role: $role
  ) {
    ...TeamMemberForOrg
  }
}
    ${TeamMemberForOrgFragmentDoc}`;
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *      memberName: // value for 'memberName'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, options);
      }
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;
export const TeamForOrgDocument = gql`
    query TeamForOrg($orgName: String!, $teamName: String!) {
  team(orgName: $orgName, teamName: $teamName) {
    ...TeamForProfile
  }
}
    ${TeamForProfileFragmentDoc}`;

/**
 * __useTeamForOrgQuery__
 *
 * To run a query within a React component, call `useTeamForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamForOrgQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useTeamForOrgQuery(baseOptions: Apollo.QueryHookOptions<TeamForOrgQuery, TeamForOrgQueryVariables> & ({ variables: TeamForOrgQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamForOrgQuery, TeamForOrgQueryVariables>(TeamForOrgDocument, options);
      }
export function useTeamForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamForOrgQuery, TeamForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamForOrgQuery, TeamForOrgQueryVariables>(TeamForOrgDocument, options);
        }
export function useTeamForOrgSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamForOrgQuery, TeamForOrgQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamForOrgQuery, TeamForOrgQueryVariables>(TeamForOrgDocument, options);
        }
export type TeamForOrgQueryHookResult = ReturnType<typeof useTeamForOrgQuery>;
export type TeamForOrgLazyQueryHookResult = ReturnType<typeof useTeamForOrgLazyQuery>;
export type TeamForOrgSuspenseQueryHookResult = ReturnType<typeof useTeamForOrgSuspenseQuery>;
export type TeamForOrgQueryResult = Apollo.QueryResult<TeamForOrgQuery, TeamForOrgQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($orgName: String!, $teamName: String!, $description: String!, $callerRole: TeamRole!) {
  createTeam(
    orgName: $orgName
    teamName: $teamName
    description: $description
    callerRole: $callerRole
  ) {
    ...TeamForOrg
  }
}
    ${TeamForOrgFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      teamName: // value for 'teamName'
 *      description: // value for 'description'
 *      callerRole: // value for 'callerRole'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const TeamListForOrgDocument = gql`
    query TeamListForOrg($orgName: String!) {
  teams(orgName: $orgName) {
    ...TeamListForOrg
  }
}
    ${TeamListForOrgFragmentDoc}`;

/**
 * __useTeamListForOrgQuery__
 *
 * To run a query within a React component, call `useTeamListForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamListForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamListForOrgQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useTeamListForOrgQuery(baseOptions: Apollo.QueryHookOptions<TeamListForOrgQuery, TeamListForOrgQueryVariables> & ({ variables: TeamListForOrgQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamListForOrgQuery, TeamListForOrgQueryVariables>(TeamListForOrgDocument, options);
      }
export function useTeamListForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamListForOrgQuery, TeamListForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamListForOrgQuery, TeamListForOrgQueryVariables>(TeamListForOrgDocument, options);
        }
export function useTeamListForOrgSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamListForOrgQuery, TeamListForOrgQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamListForOrgQuery, TeamListForOrgQueryVariables>(TeamListForOrgDocument, options);
        }
export type TeamListForOrgQueryHookResult = ReturnType<typeof useTeamListForOrgQuery>;
export type TeamListForOrgLazyQueryHookResult = ReturnType<typeof useTeamListForOrgLazyQuery>;
export type TeamListForOrgSuspenseQueryHookResult = ReturnType<typeof useTeamListForOrgSuspenseQuery>;
export type TeamListForOrgQueryResult = Apollo.QueryResult<TeamListForOrgQuery, TeamListForOrgQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($orgName: String!) {
  org(orgName: $orgName) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export function useGetOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetSamlAuthnRequestDocument = gql`
    query GetSAMLAuthnRequest($orgName: String!) {
  samlAuthnRequest(orgName: $orgName) {
    authnRequest
    httpPostUrl
  }
}
    `;

/**
 * __useGetSamlAuthnRequestQuery__
 *
 * To run a query within a React component, call `useGetSamlAuthnRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamlAuthnRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamlAuthnRequestQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useGetSamlAuthnRequestQuery(baseOptions: Apollo.QueryHookOptions<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables> & ({ variables: GetSamlAuthnRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>(GetSamlAuthnRequestDocument, options);
      }
export function useGetSamlAuthnRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>(GetSamlAuthnRequestDocument, options);
        }
export function useGetSamlAuthnRequestSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>(GetSamlAuthnRequestDocument, options);
        }
export type GetSamlAuthnRequestQueryHookResult = ReturnType<typeof useGetSamlAuthnRequestQuery>;
export type GetSamlAuthnRequestLazyQueryHookResult = ReturnType<typeof useGetSamlAuthnRequestLazyQuery>;
export type GetSamlAuthnRequestSuspenseQueryHookResult = ReturnType<typeof useGetSamlAuthnRequestSuspenseQuery>;
export type GetSamlAuthnRequestQueryResult = Apollo.QueryResult<GetSamlAuthnRequestQuery, GetSamlAuthnRequestQueryVariables>;
export const UpdatePasswordFromTokenDocument = gql`
    mutation UpdatePasswordFromToken($token: String!, $newPassword: String!) {
  updatePasswordFromToken(token: $token, newPassword: $newPassword)
}
    `;
export type UpdatePasswordFromTokenMutationFn = Apollo.MutationFunction<UpdatePasswordFromTokenMutation, UpdatePasswordFromTokenMutationVariables>;

/**
 * __useUpdatePasswordFromTokenMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordFromTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordFromTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordFromTokenMutation, { data, loading, error }] = useUpdatePasswordFromTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordFromTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordFromTokenMutation, UpdatePasswordFromTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordFromTokenMutation, UpdatePasswordFromTokenMutationVariables>(UpdatePasswordFromTokenDocument, options);
      }
export type UpdatePasswordFromTokenMutationHookResult = ReturnType<typeof useUpdatePasswordFromTokenMutation>;
export type UpdatePasswordFromTokenMutationResult = Apollo.MutationResult<UpdatePasswordFromTokenMutation>;
export type UpdatePasswordFromTokenMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordFromTokenMutation, UpdatePasswordFromTokenMutationVariables>;
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($username: String!, $displayName: String!, $company: String!) {
  updateCurrentUser(
    username: $username
    displayName: $displayName
    company: $company
  ) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      displayName: // value for 'displayName'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument, options);
      }
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationResult = Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const CreateEmailDocument = gql`
    mutation CreateEmail($username: String!, $emailAddress: String!) {
  createEmail(username: $username, emailAddress: $emailAddress) {
    ...EmailAddress
  }
}
    ${EmailAddressFragmentDoc}`;
export type CreateEmailMutationFn = Apollo.MutationFunction<CreateEmailMutation, CreateEmailMutationVariables>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCreateEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailMutation, CreateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(CreateEmailDocument, options);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<CreateEmailMutation, CreateEmailMutationVariables>;
export const MakeEmailPrimaryDocument = gql`
    mutation MakeEmailPrimary($username: String!, $emailAddress: String!) {
  makeEmailPrimary(username: $username, emailAddress: $emailAddress) {
    ...EmailAddress
  }
}
    ${EmailAddressFragmentDoc}`;
export type MakeEmailPrimaryMutationFn = Apollo.MutationFunction<MakeEmailPrimaryMutation, MakeEmailPrimaryMutationVariables>;

/**
 * __useMakeEmailPrimaryMutation__
 *
 * To run a mutation, you first call `useMakeEmailPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeEmailPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeEmailPrimaryMutation, { data, loading, error }] = useMakeEmailPrimaryMutation({
 *   variables: {
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useMakeEmailPrimaryMutation(baseOptions?: Apollo.MutationHookOptions<MakeEmailPrimaryMutation, MakeEmailPrimaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeEmailPrimaryMutation, MakeEmailPrimaryMutationVariables>(MakeEmailPrimaryDocument, options);
      }
export type MakeEmailPrimaryMutationHookResult = ReturnType<typeof useMakeEmailPrimaryMutation>;
export type MakeEmailPrimaryMutationResult = Apollo.MutationResult<MakeEmailPrimaryMutation>;
export type MakeEmailPrimaryMutationOptions = Apollo.BaseMutationOptions<MakeEmailPrimaryMutation, MakeEmailPrimaryMutationVariables>;
export const DeleteEmailDocument = gql`
    mutation DeleteEmail($username: String!, $emailAddress: String!) {
  deleteEmail(username: $username, emailAddress: $emailAddress)
}
    `;
export type DeleteEmailMutationFn = Apollo.MutationFunction<DeleteEmailMutation, DeleteEmailMutationVariables>;

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useDeleteEmailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailMutation, DeleteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailMutation, DeleteEmailMutationVariables>(DeleteEmailDocument, options);
      }
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>;
export type DeleteEmailMutationResult = Apollo.MutationResult<DeleteEmailMutation>;
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<DeleteEmailMutation, DeleteEmailMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($username: String!, $oldPassword: String!, $newPassword: String!) {
  updatePassword(
    username: $username
    oldPassword: $oldPassword
    newPassword: $newPassword
  )
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const SendPasswordRecoveryEmailDocument = gql`
    mutation SendPasswordRecoveryEmail($email: String!) {
  sendPasswordRecoveryEmail(email: $email)
}
    `;
export type SendPasswordRecoveryEmailMutationFn = Apollo.MutationFunction<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>;

/**
 * __useSendPasswordRecoveryEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordRecoveryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordRecoveryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordRecoveryEmailMutation, { data, loading, error }] = useSendPasswordRecoveryEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordRecoveryEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>(SendPasswordRecoveryEmailDocument, options);
      }
export type SendPasswordRecoveryEmailMutationHookResult = ReturnType<typeof useSendPasswordRecoveryEmailMutation>;
export type SendPasswordRecoveryEmailMutationResult = Apollo.MutationResult<SendPasswordRecoveryEmailMutation>;
export type SendPasswordRecoveryEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($token: String!, $username: String!, $provider: IdentityProvider!, $email: String, $samlOrgName: String) {
  createUser(
    token: $token
    provider: $provider
    username: $username
    email: $email
    samlOrgName: $samlOrgName
  ) {
    ...AuthUser
  }
}
    ${AuthUserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      username: // value for 'username'
 *      provider: // value for 'provider'
 *      email: // value for 'email'
 *      samlOrgName: // value for 'samlOrgName'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const SigninDocument = gql`
    mutation Signin($token: String!, $provider: IdentityProvider!, $username: String, $samlOrgName: String) {
  signin(
    token: $token
    provider: $provider
    username: $username
    samlOrgName: $samlOrgName
  ) {
    ...AuthUser
  }
}
    ${AuthUserFragmentDoc}`;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      token: // value for 'token'
 *      provider: // value for 'provider'
 *      username: // value for 'username'
 *      samlOrgName: // value for 'samlOrgName'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const GetIncidentDocument = gql`
    query GetIncident($incidentName: String!) {
  incident(incidentName: $incidentName) {
    ...Incident
  }
}
    ${IncidentFragmentDoc}`;

/**
 * __useGetIncidentQuery__
 *
 * To run a query within a React component, call `useGetIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentQuery({
 *   variables: {
 *      incidentName: // value for 'incidentName'
 *   },
 * });
 */
export function useGetIncidentQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentQuery, GetIncidentQueryVariables> & ({ variables: GetIncidentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentQuery, GetIncidentQueryVariables>(GetIncidentDocument, options);
      }
export function useGetIncidentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentQuery, GetIncidentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentQuery, GetIncidentQueryVariables>(GetIncidentDocument, options);
        }
export function useGetIncidentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetIncidentQuery, GetIncidentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIncidentQuery, GetIncidentQueryVariables>(GetIncidentDocument, options);
        }
export type GetIncidentQueryHookResult = ReturnType<typeof useGetIncidentQuery>;
export type GetIncidentLazyQueryHookResult = ReturnType<typeof useGetIncidentLazyQuery>;
export type GetIncidentSuspenseQueryHookResult = ReturnType<typeof useGetIncidentSuspenseQuery>;
export type GetIncidentQueryResult = Apollo.QueryResult<GetIncidentQuery, GetIncidentQueryVariables>;
export const AuthedPageQueryDocument = gql`
    query AuthedPageQuery {
  currentUser {
    _id
    username
  }
}
    `;

/**
 * __useAuthedPageQuery__
 *
 * To run a query within a React component, call `useAuthedPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthedPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthedPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthedPageQuery(baseOptions?: Apollo.QueryHookOptions<AuthedPageQuery, AuthedPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthedPageQuery, AuthedPageQueryVariables>(AuthedPageQueryDocument, options);
      }
export function useAuthedPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthedPageQuery, AuthedPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthedPageQuery, AuthedPageQueryVariables>(AuthedPageQueryDocument, options);
        }
export function useAuthedPageQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuthedPageQuery, AuthedPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthedPageQuery, AuthedPageQueryVariables>(AuthedPageQueryDocument, options);
        }
export type AuthedPageQueryHookResult = ReturnType<typeof useAuthedPageQuery>;
export type AuthedPageQueryLazyQueryHookResult = ReturnType<typeof useAuthedPageQueryLazyQuery>;
export type AuthedPageQuerySuspenseQueryHookResult = ReturnType<typeof useAuthedPageQuerySuspenseQuery>;
export type AuthedPageQueryQueryResult = Apollo.QueryResult<AuthedPageQuery, AuthedPageQueryVariables>;
export const DataTableQueryDocument = gql`
    query DataTableQuery($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $tableName: String!) {
  table(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    tableName: $tableName
  ) {
    columns {
      ...ColumnForDataTable
    }
    foreignKeys {
      ...ForeignKeysForDataTable
    }
  }
}
    ${ColumnForDataTableFragmentDoc}
${ForeignKeysForDataTableFragmentDoc}`;

/**
 * __useDataTableQuery__
 *
 * To run a query within a React component, call `useDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTableQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      tableName: // value for 'tableName'
 *   },
 * });
 */
export function useDataTableQuery(baseOptions: Apollo.QueryHookOptions<DataTableQuery, DataTableQueryVariables> & ({ variables: DataTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataTableQuery, DataTableQueryVariables>(DataTableQueryDocument, options);
      }
export function useDataTableQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataTableQuery, DataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataTableQuery, DataTableQueryVariables>(DataTableQueryDocument, options);
        }
export function useDataTableQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataTableQuery, DataTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DataTableQuery, DataTableQueryVariables>(DataTableQueryDocument, options);
        }
export type DataTableQueryHookResult = ReturnType<typeof useDataTableQuery>;
export type DataTableQueryLazyQueryHookResult = ReturnType<typeof useDataTableQueryLazyQuery>;
export type DataTableQuerySuspenseQueryHookResult = ReturnType<typeof useDataTableQuerySuspenseQuery>;
export type DataTableQueryQueryResult = Apollo.QueryResult<DataTableQuery, DataTableQueryVariables>;
export const RowsForDataTableQueryDocument = gql`
    query RowsForDataTableQuery($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $revisionType: RevisionType!, $tableName: String!, $offset: Int) {
  rows(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    revisionName: $refName
    tableName: $tableName
    revisionType: $revisionType
    offset: $offset
  ) {
    ...RowListRows
  }
}
    ${RowListRowsFragmentDoc}`;

/**
 * __useRowsForDataTableQuery__
 *
 * To run a query within a React component, call `useRowsForDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRowsForDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRowsForDataTableQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      revisionType: // value for 'revisionType'
 *      tableName: // value for 'tableName'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRowsForDataTableQuery(baseOptions: Apollo.QueryHookOptions<RowsForDataTableQuery, RowsForDataTableQueryVariables> & ({ variables: RowsForDataTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RowsForDataTableQuery, RowsForDataTableQueryVariables>(RowsForDataTableQueryDocument, options);
      }
export function useRowsForDataTableQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RowsForDataTableQuery, RowsForDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RowsForDataTableQuery, RowsForDataTableQueryVariables>(RowsForDataTableQueryDocument, options);
        }
export function useRowsForDataTableQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RowsForDataTableQuery, RowsForDataTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RowsForDataTableQuery, RowsForDataTableQueryVariables>(RowsForDataTableQueryDocument, options);
        }
export type RowsForDataTableQueryHookResult = ReturnType<typeof useRowsForDataTableQuery>;
export type RowsForDataTableQueryLazyQueryHookResult = ReturnType<typeof useRowsForDataTableQueryLazyQuery>;
export type RowsForDataTableQuerySuspenseQueryHookResult = ReturnType<typeof useRowsForDataTableQuerySuspenseQuery>;
export type RowsForDataTableQueryQueryResult = Apollo.QueryResult<RowsForDataTableQuery, RowsForDataTableQueryVariables>;
export const DiffSummariesDocument = gql`
    query DiffSummaries($deploymentName: String!, $ownerName: String!, $databaseName: String!, $fromCommitId: String!, $toCommitId: String!, $refName: String) {
  diffSummaries(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    fromRefName: $fromCommitId
    toRefName: $toCommitId
    refName: $refName
  ) {
    ...DiffSummary
  }
}
    ${DiffSummaryFragmentDoc}`;

/**
 * __useDiffSummariesQuery__
 *
 * To run a query within a React component, call `useDiffSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiffSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiffSummariesQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *      databaseName: // value for 'databaseName'
 *      fromCommitId: // value for 'fromCommitId'
 *      toCommitId: // value for 'toCommitId'
 *      refName: // value for 'refName'
 *   },
 * });
 */
export function useDiffSummariesQuery(baseOptions: Apollo.QueryHookOptions<DiffSummariesQuery, DiffSummariesQueryVariables> & ({ variables: DiffSummariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiffSummariesQuery, DiffSummariesQueryVariables>(DiffSummariesDocument, options);
      }
export function useDiffSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiffSummariesQuery, DiffSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiffSummariesQuery, DiffSummariesQueryVariables>(DiffSummariesDocument, options);
        }
export function useDiffSummariesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiffSummariesQuery, DiffSummariesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiffSummariesQuery, DiffSummariesQueryVariables>(DiffSummariesDocument, options);
        }
export type DiffSummariesQueryHookResult = ReturnType<typeof useDiffSummariesQuery>;
export type DiffSummariesLazyQueryHookResult = ReturnType<typeof useDiffSummariesLazyQuery>;
export type DiffSummariesSuspenseQueryHookResult = ReturnType<typeof useDiffSummariesSuspenseQuery>;
export type DiffSummariesQueryResult = Apollo.QueryResult<DiffSummariesQuery, DiffSummariesQueryVariables>;
export const BranchListForCommitGraphDocument = gql`
    query BranchListForCommitGraph($ownerName: String!, $deploymentName: String!, $databaseName: String!, $offset: Int) {
  branches(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    offset: $offset
  ) {
    list {
      ...BranchForCommitGraph
    }
    nextOffset
  }
}
    ${BranchForCommitGraphFragmentDoc}`;

/**
 * __useBranchListForCommitGraphQuery__
 *
 * To run a query within a React component, call `useBranchListForCommitGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchListForCommitGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchListForCommitGraphQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBranchListForCommitGraphQuery(baseOptions: Apollo.QueryHookOptions<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables> & ({ variables: BranchListForCommitGraphQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>(BranchListForCommitGraphDocument, options);
      }
export function useBranchListForCommitGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>(BranchListForCommitGraphDocument, options);
        }
export function useBranchListForCommitGraphSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>(BranchListForCommitGraphDocument, options);
        }
export type BranchListForCommitGraphQueryHookResult = ReturnType<typeof useBranchListForCommitGraphQuery>;
export type BranchListForCommitGraphLazyQueryHookResult = ReturnType<typeof useBranchListForCommitGraphLazyQuery>;
export type BranchListForCommitGraphSuspenseQueryHookResult = ReturnType<typeof useBranchListForCommitGraphSuspenseQuery>;
export type BranchListForCommitGraphQueryResult = Apollo.QueryResult<BranchListForCommitGraphQuery, BranchListForCommitGraphQueryVariables>;
export const UseCurrentUserQueryDocument = gql`
    query UseCurrentUserQuery {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useUseCurrentUserQuery__
 *
 * To run a query within a React component, call `useUseCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<UseCurrentUserQuery, UseCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCurrentUserQuery, UseCurrentUserQueryVariables>(UseCurrentUserQueryDocument, options);
      }
export function useUseCurrentUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCurrentUserQuery, UseCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCurrentUserQuery, UseCurrentUserQueryVariables>(UseCurrentUserQueryDocument, options);
        }
export function useUseCurrentUserQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UseCurrentUserQuery, UseCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseCurrentUserQuery, UseCurrentUserQueryVariables>(UseCurrentUserQueryDocument, options);
        }
export type UseCurrentUserQueryHookResult = ReturnType<typeof useUseCurrentUserQuery>;
export type UseCurrentUserQueryLazyQueryHookResult = ReturnType<typeof useUseCurrentUserQueryLazyQuery>;
export type UseCurrentUserQuerySuspenseQueryHookResult = ReturnType<typeof useUseCurrentUserQuerySuspenseQuery>;
export type UseCurrentUserQueryQueryResult = Apollo.QueryResult<UseCurrentUserQuery, UseCurrentUserQueryVariables>;
export const CurrentUserSubscriptionsQueryDocument = gql`
    query CurrentUserSubscriptionsQuery {
  currentUserSubscriptions {
    ...SubscriptionForBanner
  }
}
    ${SubscriptionForBannerFragmentDoc}`;

/**
 * __useCurrentUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useCurrentUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>(CurrentUserSubscriptionsQueryDocument, options);
      }
export function useCurrentUserSubscriptionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>(CurrentUserSubscriptionsQueryDocument, options);
        }
export function useCurrentUserSubscriptionsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>(CurrentUserSubscriptionsQueryDocument, options);
        }
export type CurrentUserSubscriptionsQueryHookResult = ReturnType<typeof useCurrentUserSubscriptionsQuery>;
export type CurrentUserSubscriptionsQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserSubscriptionsQueryLazyQuery>;
export type CurrentUserSubscriptionsQuerySuspenseQueryHookResult = ReturnType<typeof useCurrentUserSubscriptionsQuerySuspenseQuery>;
export type CurrentUserSubscriptionsQueryQueryResult = Apollo.QueryResult<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables>;
export const DepForDepRoleDocument = gql`
    query DepForDepRole($deploymentName: String!, $ownerName: String!) {
  deployment(deploymentName: $deploymentName, ownerName: $ownerName) {
    ...DepForDepRole
  }
}
    ${DepForDepRoleFragmentDoc}`;

/**
 * __useDepForDepRoleQuery__
 *
 * To run a query within a React component, call `useDepForDepRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepForDepRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepForDepRoleQuery({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      ownerName: // value for 'ownerName'
 *   },
 * });
 */
export function useDepForDepRoleQuery(baseOptions: Apollo.QueryHookOptions<DepForDepRoleQuery, DepForDepRoleQueryVariables> & ({ variables: DepForDepRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepForDepRoleQuery, DepForDepRoleQueryVariables>(DepForDepRoleDocument, options);
      }
export function useDepForDepRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepForDepRoleQuery, DepForDepRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepForDepRoleQuery, DepForDepRoleQueryVariables>(DepForDepRoleDocument, options);
        }
export function useDepForDepRoleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DepForDepRoleQuery, DepForDepRoleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepForDepRoleQuery, DepForDepRoleQueryVariables>(DepForDepRoleDocument, options);
        }
export type DepForDepRoleQueryHookResult = ReturnType<typeof useDepForDepRoleQuery>;
export type DepForDepRoleLazyQueryHookResult = ReturnType<typeof useDepForDepRoleLazyQuery>;
export type DepForDepRoleSuspenseQueryHookResult = ReturnType<typeof useDepForDepRoleSuspenseQuery>;
export type DepForDepRoleQueryResult = Apollo.QueryResult<DepForDepRoleQuery, DepForDepRoleQueryVariables>;
export const DeploymentOwnerDocument = gql`
    query DeploymentOwner($ownerName: String!) {
  deploymentOwner(ownerName: $ownerName) {
    username
    orgName
    displayName
  }
}
    `;

/**
 * __useDeploymentOwnerQuery__
 *
 * To run a query within a React component, call `useDeploymentOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentOwnerQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *   },
 * });
 */
export function useDeploymentOwnerQuery(baseOptions: Apollo.QueryHookOptions<DeploymentOwnerQuery, DeploymentOwnerQueryVariables> & ({ variables: DeploymentOwnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>(DeploymentOwnerDocument, options);
      }
export function useDeploymentOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>(DeploymentOwnerDocument, options);
        }
export function useDeploymentOwnerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>(DeploymentOwnerDocument, options);
        }
export type DeploymentOwnerQueryHookResult = ReturnType<typeof useDeploymentOwnerQuery>;
export type DeploymentOwnerLazyQueryHookResult = ReturnType<typeof useDeploymentOwnerLazyQuery>;
export type DeploymentOwnerSuspenseQueryHookResult = ReturnType<typeof useDeploymentOwnerSuspenseQuery>;
export type DeploymentOwnerQueryResult = Apollo.QueryResult<DeploymentOwnerQuery, DeploymentOwnerQueryVariables>;
export const ResendVerifyEmailDocument = gql`
    mutation ResendVerifyEmail($username: String!, $emailAddress: String!) {
  resendVerifyEmail(username: $username, emailAddress: $emailAddress)
}
    `;
export type ResendVerifyEmailMutationFn = Apollo.MutationFunction<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;

/**
 * __useResendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailMutation, { data, loading, error }] = useResendVerifyEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useResendVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>(ResendVerifyEmailDocument, options);
      }
export type ResendVerifyEmailMutationHookResult = ReturnType<typeof useResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationResult = Apollo.MutationResult<ResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;
export const TableNamesDocument = gql`
    query TableNames($ownerName: String!, $deploymentName: String!, $databaseName: String!, $refName: String!, $filterSystemTables: Boolean) {
  tableNames(
    ownerName: $ownerName
    deploymentName: $deploymentName
    databaseName: $databaseName
    refName: $refName
    filterSystemTables: $filterSystemTables
  ) {
    list
  }
}
    `;

/**
 * __useTableNamesQuery__
 *
 * To run a query within a React component, call `useTableNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableNamesQuery({
 *   variables: {
 *      ownerName: // value for 'ownerName'
 *      deploymentName: // value for 'deploymentName'
 *      databaseName: // value for 'databaseName'
 *      refName: // value for 'refName'
 *      filterSystemTables: // value for 'filterSystemTables'
 *   },
 * });
 */
export function useTableNamesQuery(baseOptions: Apollo.QueryHookOptions<TableNamesQuery, TableNamesQueryVariables> & ({ variables: TableNamesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableNamesQuery, TableNamesQueryVariables>(TableNamesDocument, options);
      }
export function useTableNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableNamesQuery, TableNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableNamesQuery, TableNamesQueryVariables>(TableNamesDocument, options);
        }
export function useTableNamesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableNamesQuery, TableNamesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableNamesQuery, TableNamesQueryVariables>(TableNamesDocument, options);
        }
export type TableNamesQueryHookResult = ReturnType<typeof useTableNamesQuery>;
export type TableNamesLazyQueryHookResult = ReturnType<typeof useTableNamesLazyQuery>;
export type TableNamesSuspenseQueryHookResult = ReturnType<typeof useTableNamesSuspenseQuery>;
export type TableNamesQueryResult = Apollo.QueryResult<TableNamesQuery, TableNamesQueryVariables>;